import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { Formik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CustomCheckboxGoals, MyTextField, MinHeightTextarea, NumberInput } from '../FormComponents/FormComponents'
import FormLabel from '@material-ui/core/FormLabel';
import { CustomCheckbox, CustomRowCheckbox, Myradio } from '../FormComponents/FormComponents'



//import { Link } from 'react-router-dom';
//redux
import { connect } from 'react-redux'
import { updateEvaluation } from '../../../redux/actions/dataActions'



const useStyles = makeStyles(theme => ({
    ...theme.SpreadThis,
    root: {
        flexgrow: 1,
        width: '75%',
        margin: '0 auto',
        //marginTop: theme.spacing(11)
    },
    paper: {
        padding: theme.spacing(6),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        alignItem: "center"
    },
    labelstyle: {
        padding: theme.spacing(1.5),
    },
    textboxfullwidth: {
        width: '100%',
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(3)
    },
    formlabel: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },


}))
const longTermGoals = [
    {
        title: `Intact functional vision with improved tracking, saccades, convergence, ROM, eye muscle 
                strength, ability to read, write and able to do the ADL and IADL independently. ` },
    { title: `Patient will continue his home exercises regularly to maintain and improve his functional vision.` },
]


const treatmentModalities = [
    { title: "ADL Retraining" },
    { title: "Developmental Play" },
    { title: "Functional Coordination Exercises" },
    { title: "General Postural Exercises" },
    { title: "Gross Coordination Exercises" },
    { title: "Home Exercises Program" },
    { title: "IADL Retraining" },
    { title: "Nueromuscular Reeducation" },
]
const treatmentModalitiesSecond = [
    { title: "Optolite Exercises" },
    { title: "Patient Education and Home" },
    { title: "Reading Skills Exercises" },
    { title: "Reflex Inhibition Exercises" },
    { title: "Simple Warm Up Eye Exercises" },
    { title: "Therapeutic Activities" },
    { title: "Writing Skills Exercises" },
]
const data = [{ title: "Rx. Plan and Goals were Discussed with Patient/Spouse and they agreed and Consented to Treatment." }]

const LongTermGoals = ({ ...props }) => {
    const classes = useStyles();
    let evalDetails = {
        key: "longTermGoals",

        ...props.patient.evaluationDetails
    }
    return (
        <Formik initialValues={props.longTermGoals && Object.keys(props.longTermGoals).length > 0 ? props.longTermGoals : {
            longTermGoals: [],
            additionalGoals: '',
            recomendedTreatment: '',
            treatmentModalities: '',
            rehabPotential: '',
            otherModalities1: '',
            otherModalities2: '',
            otherModalities3: '',
            agrement: '',
            frequencyTreatmentWK: '',
            frequencyTreatmentWKS: '',
            frequencyTreatmenTotalVisit: '',


        }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const evaluationData = { longTermGoals: values }
                setTimeout(() => {

                    props.updateEvaluation(evalDetails, evaluationData, (isSuccess) => { props.handleChange(6, isSuccess); setSubmitting(false) })

                    //resetForm()
                }, 500)
            }}>
            {({ values, isSubmitting, submitForm }) => (
                <div className={classes.root}>

                    <Paper className={classes.paper}>
                        <Grid container justify="flex-start" spacing={2}>
                            <Grid item xs>
                                <p style={{ color: "red", textAlign: 'left' }}>
                                    {props.patient ? props.patient.evaluationDetails ? props.patient.evaluationDetails.evaluationType ? props.patient.evaluationDetails.evaluationType.toUpperCase() === "MAINEVALUATION" ? (<p>MAIN EVAUATION</p>) : props.patient.evaluationDetails.evaluationType.toUpperCase() : "" : "" : ""}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomCheckboxGoals
                                option={longTermGoals}
                                label="Long Term Goals"
                                name="longTermGoals"
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <FormLabel focused className={classes.formlabel}>Additional Goals</FormLabel>
                            <MinHeightTextarea
                                name="additionalGoals"
                                placeholder="Special goals and suggestions for the patient😊"
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <FormLabel focused className={classes.formlabel}>Recomended Specific Treatment</FormLabel>
                            <MinHeightTextarea
                                name="recomendedTreatment"
                                placeholder="Recomended Specific Treatment for the eye movement Defect or Diseases"
                            />
                        </Grid>
                        <Grid item xs={12} container direction="column" >
                            <FormLabel focused className={classes.formlabel}>Prognosis/Rehab Potential</FormLabel>
                            <Grid container direction="row">
                                <Myradio type="radio" name="rehabPotential" label='Poor' value='Poor' />
                                <Myradio type="radio" name="rehabPotential" label='Fair' value='Fair' />
                                <Myradio type="radio" name="rehabPotential" label='Fair +' value='Fair +' />
                                <Myradio type="radio" name="rehabPotential" label='Good' value='Good' />
                                <Myradio type="radio" name="rehabPotential" label='Excellent' value='Excellent' />
                                <Myradio type="radio" name="rehabPotential" label='N/A' value='N/A' />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formlabel} direction="row" xs={8}>
                            <Grid item xs={6}>
                                <CustomCheckbox option={treatmentModalities} label="Treatment Modalities" name="treatmentModalities" value={values.treatmentModalities} />
                            </Grid>
                            <Grid item xs={6}>
                                <br />
                                <CustomRowCheckbox option={treatmentModalitiesSecond} label="Treatment Modalities" name="treatmentModalities" value={values.treatmentModalities} />
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <FormLabel focused className={classes.formlabel}>Other Treatment Modalities</FormLabel>

                            <Grid item xs={6}>
                                <MyTextField
                                    name="otherModalities1"
                                    placeholder="Other-1"
                                />
                                <MyTextField
                                    name="otherModalities2"
                                    placeholder="Other-2"
                                />
                                <MyTextField
                                    name="otherModalities3"
                                    placeholder="Other-3"
                                />
                            </Grid>
                        </Grid>

                        <br />
                        <Grid item xs={12} >
                            <FormLabel focused className={classes.formlabel}>Frequency of Treatment</FormLabel>
                            <Grid container directon="row" alignItems="stretch">
                                <Grid item xs={1}>
                                    <NumberInput type="number" name="frequencyTreatmentWK" />
                                </Grid>
                                <Grid item xs={1}>
                                    <br />
                                    <label for="week">x  WK</label>
                                </Grid>
                                <Grid item xs={1}>
                                    <NumberInput type="number" name="frequencyTreatmentWKS" />
                                </Grid>
                                <Grid item xs={1}>
                                    <br />
                                    <label for="week">x WKS = </label>
                                </Grid>
                                <Grid item xs={1}>
                                    <NumberInput type="number" name="frequencyTreatmenTotalVisit" />
                                </Grid>
                                <Grid item xs={1}>
                                    <br />
                                    <label for="week"> Visits </label>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs container direction="column" >
                            <CustomCheckbox option={data} name="agrement" />
                        </Grid>

                        <Grid container justify="center" >
                            <Button
                                className={classes.button}
                                disabled={isSubmitting}
                                color="primary"
                                variant="contained"
                                onClick={submitForm}
                            >
                                save & continue
                            </Button>
                        </Grid>
                    </Paper>
                </div>
            )}
        </Formik>
    )
}

const mapStateToProps = (state) => ({
    patient: state.data.patient,
    longTermGoals: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation.longTermGoals ? state.data.patient.evaluation.longTermGoals : {} : {} : {},
    UI: state.UI
})

export default connect(mapStateToProps, { updateEvaluation })(LongTermGoals)
