import React from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import { Formik } from 'formik'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CustomCheckboxGoals } from '../FormComponents/FormComponents'
import { Button } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import { MinHeightTextarea } from '../FormComponents/FormComponents'

//import { Link } from 'react-router-dom';
//redux
import { updateEvaluation } from '../../../redux/actions/dataActions';
import { connect } from 'react-redux';


const useStyles = makeStyles(theme => ({
    ...theme.SpreadThis,
    root: {
        flexGrow: 1,
        width: '75%',
        margin: '0 auto'
    },
    paper: {
        padding: theme.spacing(5),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        alignItem: "flex-start"
    },
    button: {
        textAlign: "center"
    }
}))

const shortTermGoals = [
    { title: 'Improve the functional vision problem through interaction of the brain, Eyes, and visual pathways. ' },
    { title: 'Improve Visual skills such as control of eye movements, depth perception, and orientation.' },
    { title: 'Improve Hand writing skills, ability to copy notes from the board. ' },
    { title: 'Improve the ability in participating in sports through developing Depth perception and ability to accurately judge the speed and trajectory of an incoming ball. ' },
    { title: 'Improve the sitting tolerance and able to keep their attention on the test in front of them.' },
    { title: 'Improve and retraining of the Hand eye coordination related ADL and IADL in acquired and or traumatic brain injury. ' },
    { title: 'Improve the confidence related postural normality from poor eye contact ,head down, and turned secondary to poor confidence. ' },
    { title: 'Improve the strength of the eye muscles and improve vision.' },
    { title: 'Vision therapy ' },
    { title: 'Dylexia treatment. ' },
    {
        title: `Improve occulomotor skills, to easily control movements of their eyes 
              so that patient does not loose line & place of reading, by skipping
              small words, slow reading speed, and poor reading comprehension and
              difficulty in copying from the board. ` },
    {
        title: `Improve accommodation(focusing)since patient is unable to maintain a 
                clear image or focus on an object , so that patient will not rub the 
                eyes ,blink excessively ,squinting, having frontal headaches, hurting 
                eyes, itching or stinging when reading or difficulty from copying from 
                the board. ` },
    {
        title: `Improve convergence/divergence (eye teaming) since patient has difficulty 
                using their eyes together as a team, negatively impacting, their eyes during depth perception, 
                causing blurry and /or double vision and fatigue with near point tasks, so that it reduces the fatigue when 
                reading, avoids reading frequently ,looking away from reading material, poor reading comprehension words split 
                apart and /or move on the page. ` },
    {
        title: `Maintain and improve the vestibulo Ocular reflex (VOR) so that inner ear and eye movements stabilize the images on 
                the retinas during head movements in the direction opposite to head movement.  `},
    { title: `Improve eye movement to improve behavior and academic performance. ` },
    {
        title: ` Reduce the convergence problem to reduce the symptoms of ADHD.such as convergence insufficiency in where 
                the ADHD children has poor near vision.`},
]


const ShortTermGoals = ({ ...props }) => {

    const classes = useStyles()
    let evalDetails = {
        key: "shortTermGoals",

        ...props.patient.evaluationDetails
    }
    return (
        <Formik
            initialValues={props.shortTermGoals && Object.keys(props.shortTermGoals).length > 0 ? props.shortTermGoals : {
                shortTermGoals: '',
                additionalGoals: '',
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const evaluationData = { shortTermGoals: values }
                setTimeout(() => {
                    props.updateEvaluation(evalDetails, evaluationData, (isSuccess) => { props.handleChange(5, isSuccess); setSubmitting(false) })

                    // resetForm()
                }, 500)
            }}
        >
            {({ values, isSubmitting, submitForm }) => (
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Paper className={classes.paper}>
                            <Grid container justify="flex-start" spacing={2}>
                                <Grid item xs>
                                    <p style={{ color: "red", textAlign: 'left' }}>
                                        {props.patient ? props.patient.evaluationDetails ? props.patient.evaluationDetails.evaluationType ? props.patient.evaluationDetails.evaluationType.toUpperCase() === "MAINEVALUATION" ? (<p>MAIN EVAUATION</p>) : props.patient.evaluationDetails.evaluationType.toUpperCase() : "" : "" : ""}
                                    </p>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomCheckboxGoals option={shortTermGoals} label="Short Term Goals" name="shortTermGoals" />
                            </Grid>
                            <Grid item xs={12} >
                                <FormLabel focused className={classes.formlabel}>Additional Goals</FormLabel>
                                <MinHeightTextarea
                                    name="additionalGoals"
                                    placeholder="Special goals and suggestions for the patient😊"
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.button}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    save & continue
                                    </Button>
                            </Grid>
                        </Paper>
                    </Grid>
                </div>
            )}

        </Formik >

    )
}

const mapStateToProps = (state) => ({
    patient: state.data.patient,
    shortTermGoals: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation.shortTermGoals ? state.data.patient.evaluation.shortTermGoals : {} : {} : {},
    UI: state.UI
})
export default connect(mapStateToProps, { updateEvaluation })(ShortTermGoals)