import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { purple } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
// import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Dialog from '@material-ui/core/Dialog';
// import MyButton from '../../utils/MyButton';
// import CloseIcon from '@material-ui/icons/Close';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import dayjs from 'dayjs'
import { deepOrange } from '@material-ui/core/colors';

//redux stuff
import { connect } from 'react-redux';
import { setCurrentEvaluation, soapNoteReport, setEvaluationType } from '../../redux/actions/dataActions'
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },

}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const style = (theme) => ({
    table: {
        minWidth: 500,
    },
    tablemain: {
        padding: theme.spacing(3),
        overflow: "visible",
        boxSizing: "border-box",
        marginTop: theme.spacing(6)
    },
    paperHead: {
        position: "relative",
        paddingTop: theme.spacing(.5),
        textAlign: 'center',
        margin: 'auto'
    },
    paper: {
        position: "absolute",
        top: '-70px',
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        textAlign: 'center',
        margin: 'auto',
        color: "white",
        backgroundColor: deepOrange[500],
        '&:hover': {
            backgroundColor: deepOrange[700],
        },
    },
    purpleButton: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        }
    },
})
class ProgressNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 3,
            page: 0
        }
        this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage.bind(this)
    }
    // componentDidMount() {
    //     this.props.getAllPatients();
    // }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 })
    };
    listOutDiagnosis = (diagnosis) => {
        if (diagnosis && diagnosis.title !== "others") {
            return <p>{diagnosis.title}</p>
        }
    }

    render() {

        const { page, rowsPerPage } = this.state;
        const { classes, patient, UI: { loading } } = this.props;
        let evalDetails = {
            ...patient.evaluationDetails,
            evaluationType: "Progress Note"
        }

        // const emptyRows = rowsPerPage - Math.min(rowsPerPage, patients.length - page * rowsPerPage);
        let length = this.props.progressNotes ? this.props.progressNotes ? this.props.progressNotes.length : 0 : 0
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, length - page * rowsPerPage);

        return (
            <TableContainer className={classes.tablemain} component={Paper}>
                <Grid item xs={12} sm={11} className={classes.paperHead}>
                    <Paper className={classes.paper}>
                        <h3>Progrss Notes</h3>
                    </Paper>
                </Grid>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            {/* <TableCell align="right"></TableCell> */}
                            <TableCell align="right" onClick={() => { this.props.setEvaluationType(evalDetails); this.props.history.push('/soapnotes') }}>
                                <Button variant="contained" color="primary">
                                    Add
                                            {loading && <CircularProgress size={30} className={classes.progress} />}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date of Visit</TableCell>
                            <TableCell align="right">Primary Diagnosis</TableCell>
                            <TableCell align="right">Service</TableCell>
                            <TableCell align="right">Insurance</TableCell>
                            <TableCell align="right">Edit</TableCell>
                            <TableCell align="right">Report</TableCell>
                            {/* <TableCell align="right">Details</TableCell> */}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(rowsPerPage > 0
                            ? this.props.progressNotes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : this.props.progressNotes
                        ).map((prog_data, i) => (
                            <Fragment  >
                                <TableRow key={i}>
                                    <TableCell component="th" scope="row" onClick={() => { this.handleClickOpen(prog_data) }}>
                                        {/* <Link component="button" variant="body2"> {dayjs(prog_data.appointment ? prog_data.appointment.appointmentDate : "").format("dddd, MMMM D YYYY")}</Link> */}
                                        {dayjs(prog_data.appointment ? prog_data.appointment.appointmentDate : "").format("dddd, MMMM D YYYY")}
                                    </TableCell>
                                    {/* <TableCell align="right">{evl_data.appointment.primaryDiagnosis ? evl_data.appointment.primaryDiagnosis.forEach(diagnosis => { console.log(diagnosis.title) }) : ''}</TableCell> */}
                                    <TableCell align="right">{prog_data.appointment.primaryDiagnosis ? prog_data.appointment.primaryDiagnosis.map(diagnosis => this.listOutDiagnosis(diagnosis)) : ''}{prog_data.appointment.otherPrimaryDiagnosis ? prog_data.appointment.otherPrimaryDiagnosis : ""}</TableCell>
                                    <TableCell align="right">{prog_data.appointment.service ? prog_data.appointment.service.toString() : ''}</TableCell>
                                    <TableCell align="right">{prog_data.appointment.insurance ? prog_data.appointment.insurance.title !== "others" ? prog_data.appointment.insurance.title : prog_data.appointment.otherInsurance : ''}</TableCell>
                                    <TableCell align="right" onClick={() => { this.props.setCurrentEvaluation("Progress Note", prog_data); this.props.history.push('/soapnotes') }}> <EditIcon color="primary" /></TableCell>
                                    <TableCell align="right" onClick={() => { this.props.setCurrentEvaluation("Progress Note", prog_data); this.props.soapNoteReport(prog_data, this.props.patient, this.props.userData) }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                        >
                                            Report
                                            {loading && <CircularProgress size={30} className={classes.progress} />}
                                        </Button>
                                    </TableCell>
                                    {/* <TableCell align="right" onClick={() => { this.props.setCurrentEvaluation(prog_data); this.props.history.push('/evaluationMain') }}> <EditIcon color="primary" /></TableCell> */}

                                </TableRow>
                                {/* <Dialog

                                    open={this.state.open}
                                    onClose={this.handleClose}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth="md"
                                >
                                    <MyButton
                                        onClick={this.handleClose}
                                        tip="close window"
                                        tipClassName={classes.closeButton}
                                    >
                                        <CloseIcon />
                                    </MyButton>
                                    <DialogTitle className={classes.styledHeader} >
                                        Patient Registration
                                     </DialogTitle>
                                    <DialogContent className={classes.evaDialog}>
                                        <Grid container justify="center">
                                            <Grid item xs={12}>
                                                <FormControl className={classes.formControl} variant="outlined">
                                                    <InputLabel htmlFor="outlined-age-native-simple">Evaluation</InputLabel>
                                                    <Select
                                                        //labelId="demo-controlled-open-select-label"
                                                        //id="outlined-age-native-simple"
                                                        inputProps={{
                                                            name: 'age',
                                                            id: 'outlined-age-native-simple',
                                                        }}
                                                        open={this.state.openMenu}
                                                        onClose={this.handleMenuClose}
                                                        onOpen={this.handleMenuClickOpen}
                                                        value={this.state.evalValue}
                                                        onChange={this.handleChange}
                                                    >
                                                        <MenuItem value={'appointment'}>Appointment</MenuItem>
                                                        <MenuItem value={'evaluationOne'}>Evaluation One</MenuItem>
                                                        <MenuItem value={'evaluationSecond'}>Evaluation Two</MenuItem>
                                                        <MenuItem value={'objectiveObservation'}>Objective Observation</MenuItem>
                                                        <MenuItem value={'shortTermGoals'}>Short Term Goals</MenuItem>
                                                        <MenuItem value={'longTermGoals'}>Long Term Goals</MenuItem>
                                                        <MenuItem value={'dischargePlan'}>Discharge</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                          
                                        </Grid>


                                    </DialogContent>

                                </Dialog> */}
                            </Fragment>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[3, 5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={6}
                                count={this.props.progressNotes.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }
}



const mapStateToProps = (state) => ({
    UI: state.UI,
    userData: state.user.credentials,
    patient: state.data.patient,
    current_progNote: state.data.patient ? state.data.patient.progressNotes ? state.data.patient.progressNotes : {} : {},
    progressNotes: state.data.progressNotes ? state.data.progressNotes.progressNotes ? state.data.progressNotes.progressNotes : [] : []
})
const mapActionToProps = {
    setCurrentEvaluation,
    soapNoteReport,
    setEvaluationType

}
export default connect(mapStateToProps, mapActionToProps)(withStyles(style)(ProgressNote))
