import React from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import './App.css';
// pages
import NavbarDrawer from './components/Navbar/NavbarDrawer';

// import Home from './pages/home';
// import Login from './pages/login';
// import Signup from './pages/signup';
// import EditProfile from './components/UserProfile/EditProfile'
// import Plans from './components/Subscription/Plans'
// import SearchPatient from './components/Search/SearchPatient'
// import EvaluationTab from './components/Evaluation/EvaluationTab/EvaluationTab'
// import TrainerApp from './components/TrainerApp/TrainerApp';
// import EvaluationOne from './components/Evaluation/EvaluationFirst/EvaluationOne'
// //import PatientRegistrationForm from './components/Evaluation/PatientRegistration/PatientRegistrationForm'
// import LongTermGoals from './components/Evaluation/Goals/LongTermGoals';
// import ShortTermGoals from './components/Evaluation/Goals/ShortTermGoals'
// import ObjectiveObservation from './components/Evaluation/ObjectiveObservation/ObjectiveObservation'
// import DischargeForm from './components/Evaluation/DischargeForm/DischargeForm'
// import EvaluationSecond from './components/Evaluation/EvaluationSecond/EvaluationSecond'
// import UserDashboard from './components/Dashboard/UserDashboard';
// import ProffesionalDashboard from './components/Dashboard/ProffesionalDashboard';
// import PatientInfo from './components/Search/PatientInfo'
// import Appointment from './components/Evaluation/Appointment/Appointment'
import AppRoute from './AppRoute';
//redux
import { Provider } from 'react-redux';
import store from './redux/store'
import { logoutUser, getUserData } from './redux/actions/userActions'
import { SET_AUTHENTICATED } from './redux/types'
//theme
import themeFile from './utils/theme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import jwtDecode from 'jwt-decode';
//import AuthRoute from './utils/AuthRoute';
import axios from 'axios';


const theme = createMuiTheme(themeFile);

let authenticated;
const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    authenticated = "false";
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
    authenticated = "true";


  }
} else {
  store.dispatch(logoutUser());

}



class App extends React.Component {


  render() {

    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <NavbarDrawer />
            <main className="content">
              <AppRoute />
              <Redirect to={authenticated ? "/my-account" : "/login"} />
            </main>
          </Router>

        </Provider>
      </MuiThemeProvider>
    );
  }
}


export default App;

