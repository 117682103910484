import React, { Component } from 'react'
import { Formik, Field } from 'formik'
import Typography from '@material-ui/core/Typography';
import { MyTextField, ComboBox, Myradio, DatePickerField, NumberInput, } from '../FormComponents/FormComponents'
import Paper from '@material-ui/core/Paper';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { CheckboxWithLabel } from 'formik-material-ui';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';

const jwt = require('jsonwebtoken');
const TOKEN_SECRET = "gdfgdt6456gdfg645gdfgd"


const useStyles = (theme) => ({
    ...theme.SpreadThis,
    root: {
        width: '75%',
        margin: '0 auto',
        flexGrow: 1,
        //marginTop: theme.spacing(5)
    },
    paper: {
        padding: theme.spacing(5),
        textAlign: 'center',
    },
    textField: {
        padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(3)
    },
    evaluationtxt: {
        textAlign: "left"
    }


})

const insuranceList = [
    { title: 'Self Pay' },
    { title: 'Blue Cross / Blue Shield' },
    { title: 'Children Medical Service' },
    { title: 'CIGNA' },
    { title: 'Freedom 1st' },
    { title: "MedicAid - only up to 20 years old" },
    { title: 'MedicAid Waiver' },
    { title: 'Optimum' },
    { title: 'PediCare' },
    { title: 'StayWell' },
    { title: 'WellCare' },
    { title: "'Worker's Compensation'" },
    { title: 'Sunshine' },
];


export class PatientRegistrationForm extends Component {

    state = {
        inputData: ""
    }
    componentDidMount() {
        let id = ""
        const token = document.cookie.split(";")[0].replace("auth_token=", "");
        if (token) {
            const decodedToken = jwt.verify(token, TOKEN_SECRET);
            id = decodedToken.user_id;
        }
        this.setState({ id });
        if (id) {
            axios.get("/api/user/get-form-data?id=" + id)
                .then(res => {
                    let output = JSON.parse(res.data.user_form_data);
                    this.setState({ inputData: output ? output.patientReg : "" });

                });
        }
    }
    render() {
        let inputData = this.state.inputData;
        let id = this.state.id;
        const { classes } = this.props;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Formik
                    enableReinitialize
                    initialValues={inputData ? inputData : {
                        lastName: '',
                        firstName: '',
                        address: '',
                        patientId: '',
                        insurance: [],
                        service: [],
                        onSetDate: new Date(),
                        socDate: new Date(),
                        initialEvaluation: false,
                        rx: false,
                        primaryDiagnosis: '',
                        physiciansName: '',
                        duration: '',
                    }}
                    validationSchema={Yup.object({
                        lastName: Yup.string()
                            .max(15, 'Must be 15 characters or less')
                            .required('Required'),
                        firstName: Yup.string()
                            .max(15, 'Must be 15 characters or less')
                            .required('Required'),
                        address: Yup.string()
                            .max(30, 'Must be 30 characters or less')
                            .required('Required'),
                        patientId: Yup.string()
                            .required('Required'),
                        initialEvaluation: Yup.boolean()
                            .required('Required'),
                        rx: Yup.boolean()
                            .required('Required'),

                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        axios.post('/api/user/form-data?id=' + id, { patientReg: values })
                            .then(res => {
                                alert("isjdfij");

                            });
                        setTimeout(() => {
                            alert(JSON.stringify(values, null, 2));
                            setSubmitting(false);
                        }, 500)
                    }}
                >
                    {({ values, isSubmitting, handleSubmit }) => (
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <div className={classes.root}>
                                <Grid container spacing={3}>
                                    <Paper className={classes.paper}>
                                        <Typography color="primary" className={classes.textField} variant="h4" >Patient Registration</Typography>
                                        {/* <Divider /> */}
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-around"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item xs={6}>
                                                <MyTextField
                                                    name="lastName"
                                                    value={values.lastName}
                                                    placeholder="Last Name"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MyTextField
                                                    name="firstName"
                                                    value={values.firstName}
                                                    placeholder="First Name"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MyTextField
                                                    name="address"
                                                    value={values.address}
                                                    placeholder="Address"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MyTextField
                                                    name="patientId"
                                                    value={values.patientId}
                                                    placeholder="Patient ID"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ComboBox
                                                    label="Insurance"
                                                    OptionList={insuranceList}
                                                    name="insurance"
                                                    placeholder="Insurance"
                                                    as="select"
                                                    value={values.insurance}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    // className={classes.checkboxtext}
                                                    variant="subtitle1"
                                                >
                                                    Service
                                                    </Typography>
                                                <Field
                                                    component={CheckboxWithLabel}
                                                    name="service"
                                                    type="checkbox"
                                                    value="PT"
                                                    Label={{ label: 'PT' }}
                                                />
                                                <Field
                                                    component={CheckboxWithLabel}
                                                    Label={{ label: 'OT' }}
                                                    type="checkbox"
                                                    name="service"
                                                    value="OT"
                                                />
                                                <Field
                                                    component={CheckboxWithLabel}
                                                    Label={{ label: 'ST' }}
                                                    type="checkbox"
                                                    name="service"
                                                    value="ST"
                                                />
                                            </Grid>
                                            <Grid item xs={6}  >
                                                <DatePickerField
                                                    name="onSetDate"
                                                    placeholder="On Set Date"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DatePickerField
                                                    name="socDate"
                                                    placeholder="SOC Date"
                                                />
                                            </Grid>
                                            <Grid item xs={6} className={classes.evaluationtxt}>
                                                <Typography variant="subtitle1" >Intial Evaluation</Typography>
                                                <Myradio type="radio" value="yes" label="Yes" name="initialEvaluation" />
                                                <Myradio type="radio" value="No" label="No" name="initialEvaluation" />
                                            </Grid>
                                            <Grid item xs={6} className={classes.evaluationtxt}>
                                                <Typography variant="subtitle1" >rx</Typography>
                                                <Myradio type="radio" value="Yes" label="Yes" name="rx" />
                                                <Myradio type="radio" value="No" label="No" name="rx" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MyTextField
                                                    name="primaryDiagnosis"
                                                    placeholder="Primary Diagnosis"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MyTextField
                                                    name="treatmentDiagnosis"
                                                    placeholder="Treatment Diagnosis"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MyTextField
                                                    name="physiciansName"
                                                    placeholder="Physician's Name"
                                                />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <NumberInput name="duration" type="number" placeholder="Duration" />
                                            </Grid>
                                            <Grid xs={12} container justify="center" >
                                                {/* <Button
                                                className={classes.button}
                                                color="primary"
                                                variant="contained"
                                                onClick ={()=>submitData()}
                                            >
                                                save & continue
                                                </Button> */}
                                                <button type="submit"> save & continue</button>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                </Grid>
                                <pre>
                                    {JSON.stringify(values, null, 2)}
                                </pre>
                            </div>
                        </form >
                    )}

                </Formik>
            </MuiPickersUtilsProvider>
        )
    }
}

export default withStyles(useStyles)(PatientRegistrationForm)
