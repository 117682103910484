import {
    SET_USER,
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI,
    SET_UNAUTHENTICATED,
    LOADING_USER,
    SET_SELECTED_PLAN,
    UPDATE_USER
    //SHOW_RESPONSE

} from '../types';
import axios from 'axios';


//login action creater
export const loginUser = (userData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('api/login', (userData))
        .then(res => {
            setAuthorization(res.data.token);
            dispatch(getUserData())
            dispatch({
                type: CLEAR_ERRORS
            })
            history.push('/my-account')

        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })

        })
}

//logout action creater
export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('FBIdToken');
    delete axios.defaults.headers.common['Authorization'];
    dispatch({ type: SET_UNAUTHENTICATED })
}


//sign up user action creater
export const signupUser = (newUserData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('/api/signup', (newUserData))
        .then(res => {
            setAuthorization(res.data.token);
            dispatch(getUserData());
            dispatch({ type: CLEAR_ERRORS });
            history.push('/my-account');
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

export const resetPassword = (email, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post('/api/resetpassword', (email))
        .then(res => {
            dispatch({ type: CLEAR_ERRORS })
            history.push('/login');
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

//getting user info
export const getUserData = () => (dispatch) => {
    dispatch({ type: LOADING_USER });
    axios.get('/api/user')
        .then(res => {
            dispatch({
                type: SET_USER,
                payload: res.data
            })
            return res.data.userType

        })
        .catch(err => {
            console.log(err);
        })
}

//upload image
export const uploadImage = (formData) => (dispatch) => {
    dispatch({ type: LOADING_USER })
    axios
        .post('/api/user/image', (formData))
        .then(() => {
            dispatch(getUserData());
        })
        .catch((err) => console.log(err))
}

export const selectedPlan = (plan, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    dispatch({
        type: SET_SELECTED_PLAN,
        payload: plan
    })
    history.push('/payment');
}

export const startFreeTrial = (plan, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })

    axios.post("/api/paypal/checkout", { selectedPlan: plan.type, userType: plan.plan, nonce: "" })
        .then(k => {
            alert(k.data.message)
            //props.history.push('/my-account')
            window.location.href = '/my-account';
        })
        .catch(k => {
            alert(k.data.message)
        })

}
//update profile
export const updateProfile = (profileData) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('/api/user', (profileData))
        .then(res => {
            dispatch({
                type: UPDATE_USER,
                payload: profileData
            })
            dispatch({
                type: CLEAR_ERRORS
            })
        })
        .catch(err => {
            console.log(err)
        })
}
const setAuthorization = (token) => {
    const FBIdToken = `Bearer ${token}`;
    localStorage.setItem('FBIdToken', FBIdToken);
    axios.defaults.headers.common['Authorization'] = FBIdToken;
}

