import React, { Component } from 'react';
import Iframe from 'react-iframe';
import withStyles from '@material-ui/core/styles/withStyles';


const styles = (theme) => ({
    ...theme.spreadThis,
    root: {
        height: '100%',
    },
    paper: {
        width: '33%',
        minWidth: '280px',
        margin: 'auto',
        padding: '20px 20px 40px 20px',
        textAlign: 'center',
        color: theme.palette.primary,
        //background: theme.palette.secondary.light
    }
})
class TrainerApp extends Component {


    render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            <Iframe

                url="https://trainer.visionrehabilitations.com/"
                width="100%"
                height="100%"
                id="myId"
                className="myClassname"
                display="initial"
                allow="camera;microphone"
                position="relative" /></div>
    }

}

export default withStyles(styles)(TrainerApp)
