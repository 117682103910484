import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PatientHistoryTable from './PatientHistoryTable';
import { connect } from 'react-redux';

const styles = (theme) => ({
    ...theme.SpreadThis,
    root: {
        width: '75%',
        margin: '0 auto'
    },
    textfield: {
        padding: theme.spacing(2),
        textAlign: "center",
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(5)
    }

})
class PatientInfo extends Component {

    render() {
        const { classes, currentPatientData: { lastName, firstName, patientId, address, phone } } = this.props;
        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography color="primary" className={classes.textfield} variant="h4" >Patient Info</Typography>
                    <hr style={{ marginBottom: '20px' }} />
                    <Grid container spacing={1} style={{ paddingLeft: '30px' }}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Name :{lastName + ' ' + firstName}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Patient Id:{patientId}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Address:{address}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Phone :{phone}
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr style={{ marginBottom: '60px', border: 'none' }} />
                    <PatientHistoryTable history={this.props.history} />
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentPatientData: state.data.patient
})

export default connect(mapStateToProps, {})(withStyles(styles)(PatientInfo))
