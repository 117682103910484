//user reduser types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const SHOW_RESPONSE = 'SHOW_RESPONSE';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
//UI REDUSER TYPES
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_PATIENT = 'CURRENT_PATIENT'
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

//DATA REDUSER TYPES
export const SET_ALL_PATIENTS = 'SET_ALL_PATIENTS';
export const LOADING_DATA = 'LOADING_DATA';
export const REGISTER_PATIENT = 'REGISTER_PATIENT';
export const SET_EVALUATION_DETAILS = 'SET_EVALUATION_DETAILS';
export const SET_EVALUATIONS = 'SET_EVALUATIONS';
export const SET_CURRENT_EVALUATION = 'SET_CURRENT_EVALUATION';
export const UPDATE_CURRENT_EVALUATION = 'UPDATE_CURRENT_EVALUATION';
export const CLEAR_CURRENT_PATIENT = 'CLEAR_CURRENT_PATIENT';
export const CLEAR_CURRENT_EVALUATIONS = 'CLEAR_CURRENT_EVALUATIONS';
export const CLEAR_SELECTED_EVALUATION = 'CLEAR_SELECTED_EVALUATION';
export const CLEAR_SELECTED_PROGRESSNOTE = 'CLEAR_SELECTED_PROGRESSNOTE';
export const CLEAR_SELECTED_UPDATENOTE = 'CLEAR_SELECTED_UPDATENOTE';
export const CLEAR_SELECTED_DISCHARGENOTE = 'CLEAR_SELECTED_DISCHARGENOTE';

//PROGRESS NOTE TYPES
export const SET_PROGRESSNOTES = 'SET_PROGRESSNOTES';
export const SET_CURRENT_PROGRESSNOTE = 'SET_CURRENT_PROGRESSNOTE';
export const UPDATE_CURRENT_PROGRESSNOTE = 'UPDATE_CURRENT_PROGRESSNOTE'
export const SET_PROGNOTE_ID = "SET_PROGNOTE_ID";

//UPDATE NOTE TYPES
export const SET_UPDATENOTES = "SET_UPDATENOTES";
export const SET_CURRETNT_UPDATENOTE = "SET_CURRETNT_UPDATENOTE";
export const UPDATE_CURRENT_UPDATENOTE = "UPDATE_CURRENT_UPDATENOTE";
//
export const SET_CURRETNT_DISCHARGENOTE = "SET_CURRETNT_DISCHARGENOTE";
export const UPDATE_CURRENT_DISCHARGENOTE = "UPDATE_CURRENT_DISCHARGENOTE";
export const SET_DISCHARGENOTE = "SET_DISCHARGENOTE";