import {
    SET_ALL_PATIENTS,
    LOADING_DATA,
    SET_CURRENT_PATIENT,
    SET_EVALUATION_DETAILS,
    SET_EVALUATIONS,
    SET_CURRENT_EVALUATION,
    UPDATE_CURRENT_EVALUATION,
    CLEAR_CURRENT_PATIENT,
    SET_PROGRESSNOTES,
    SET_PROGNOTE_ID,
    SET_CURRENT_PROGRESSNOTE,
    UPDATE_CURRENT_PROGRESSNOTE,
    SET_CURRETNT_UPDATENOTE,
    UPDATE_CURRENT_UPDATENOTE,
    SET_UPDATENOTES,
    SET_DISCHARGENOTE,
    SET_CURRETNT_DISCHARGENOTE,
    UPDATE_CURRENT_DISCHARGENOTE,
    CLEAR_CURRENT_EVALUATIONS,
    CLEAR_SELECTED_EVALUATION,
    CLEAR_SELECTED_PROGRESSNOTE,
    CLEAR_SELECTED_UPDATENOTE,
    CLEAR_SELECTED_DISCHARGENOTE
} from '../types';

const initialState = {
    patients: [],
    patient: {},
    evaluations: {},
    progressNotes: {},
    updateNotes: {},
    dischargeNotes: {},
    loading: false
};

export default function (state = initialState, action) {
    let patient = { ...state.patient };

    switch (action.type) {
        case LOADING_DATA:
            return {
                ...state,
                loading: true
            };
        case SET_ALL_PATIENTS:
            return {
                ...state,
                patients: action.payload,
                loading: false
            }
        case SET_CURRENT_PATIENT:
            return {
                ...state,
                patient: action.payload,
                // loading: false
            }
        case SET_EVALUATION_DETAILS:
            let data = {}
            data = { ...state.patient }
            data.evaluationDetails = action.payload
            return {
                ...state,
                patient: data,
                //loading: false
            }
        case SET_EVALUATIONS:
            return {
                ...state,
                evaluations: action.payload,
                loading: false
            }
        case SET_CURRENT_EVALUATION:
            {
                let evaluation = {};
                evaluation = { ...state.patient }
                evaluation.evaluation = action.payload
                return {
                    ...state,
                    patient: evaluation,
                    // loading: false
                }
            }
        case UPDATE_CURRENT_EVALUATION:
            //eval_update = { ...state.patient.evaluation }
            let { key, eval_update } = action.payload
            // let patient = { ...state.patient };
            let evaluation = { ...patient.evaluation };
            evaluation[key] = eval_update[key];
            patient.evaluation = evaluation;
            return {
                ...state,
                patient,
            }
        case SET_PROGRESSNOTES:
            return {
                ...state,
                progressNotes: action.payload,
                loading: false
            }
        case SET_CURRENT_PROGRESSNOTE:
            {
                let progNote = {};
                progNote = { ...state.patient }
                progNote.progressNote = action.payload
                return {
                    ...state,
                    patient: progNote,
                    loading: false
                }
            }
        case UPDATE_CURRENT_PROGRESSNOTE:
            //eval_update = { ...state.patient.evaluation }
            let { progkey, progUpdate } = action.payload
            //  let updatepatient = { ...state.patient };
            let progDataUpdate = { ...patient.progressNote };
            progDataUpdate[progkey] = progUpdate[progkey];
            patient.progressNote = progDataUpdate;

            return {
                ...state,
                patient,
            }
        case SET_PROGNOTE_ID:
            let progData = {}
            progData = { ...state.patient }
            progData.progressNoteId = action.payload
            return {
                ...state,
                patient: progData
            }
        // updatenote section***********************
        case SET_UPDATENOTES:
            return {
                ...state,
                updateNotes: action.payload,
                loading: false
            }
        case SET_CURRETNT_UPDATENOTE:
            {
                let upNote = {};
                upNote = { ...state.patient }
                upNote.updateNotes = action.payload
                return {
                    ...state,
                    patient: upNote,
                    loading: false
                }
            }
        case UPDATE_CURRENT_UPDATENOTE:
            //eval_update = { ...state.patient.evaluation }
            let { upkey, updateNoteUpdate } = action.payload
            //  let updatepatient = { ...state.patient };
            let upNoteUpdate = { ...patient.updateNotes };
            upNoteUpdate[upkey] = updateNoteUpdate[upkey];
            patient.updateNotes = upNoteUpdate;

            return {
                ...state,
                patient,
            }

        //dischargeNote section***********************
        case SET_DISCHARGENOTE:
            return {
                ...state,
                dischargeNotes: action.payload,
                loading: false
            }

        case SET_CURRETNT_DISCHARGENOTE:
            {
                let disNote = {};
                disNote = { ...state.patient }
                disNote.dischargeNotes = action.payload
                return {
                    ...state,
                    patient: disNote,
                    loading: false
                }
            }
        case UPDATE_CURRENT_DISCHARGENOTE:
            //eval_update = { ...state.patient.evaluation }
            let { diskey, dischargeNoteUpdate } = action.payload
            //  let updatepatient = { ...state.patient };
            let disUpdate = { ...patient.dischargeNotes };
            disUpdate[diskey] = dischargeNoteUpdate[diskey];
            patient.dischargeNotes = disUpdate;

            return {
                ...state,
                patient,

            }

        case CLEAR_CURRENT_PATIENT:
            return {
                ...state,
                patient: {}
            }
        case CLEAR_CURRENT_EVALUATIONS:

            patient.evaluation = {}
            patient.upNoteUpdates = {};
            patient.dischargeNotes = {};
            patient.progressNote = {};
            return {
                ...state,
                patient,
                progressNotes: {},
                updateNotes: {},
                dischargeNotes: {},
            }
        case CLEAR_SELECTED_EVALUATION:
            patient.evaluationDetails.evaluationId = ""
            patient.evaluation = {};
            return {
                ...state,
                patient
            }
        // CLEAR_SELECTED_PROGRESSNOTE,
        // CLEAR_SELECTED_UPDATENOTE,
        // CLEAR_SELECTED_DISCHARGENOTE
        case CLEAR_SELECTED_PROGRESSNOTE:
            patient.evaluationDetails.progressNoteId = ""
            patient.progressNote = {};
            return {
                ...state,
                patient
            }
        case CLEAR_SELECTED_UPDATENOTE:
            patient.evaluationDetails.updateNoteId = ""
            patient.updateNotes = {};
            return {
                ...state,
                patient
            }
        case CLEAR_SELECTED_DISCHARGENOTE:
            patient.evaluationDetails.dischargeNoteId = ""
            patient.dischargeNotes = {};
            return {
                ...state,
                patient
            }
        default:
            return state;
    }
}



