import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
//import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import { deepOrange, green, cyan, orange } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import Profile from '../UserProfile/Profile';
import { Link } from 'react-router-dom'

// import HUE from '@material-ui/core/colors/HUE';

const useStyles = makeStyles(theme => ({
    ...theme.spreadThis,
    root: {
        flexGrow: 1,
        width: '75%',
        margin: 'auto'
    },
    cardroot: {
        marginTop: theme.spacing(7),
        position: 'relative',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    square: {
        position: "absolute",
        top: '-10px',
        width: '80px',
        height: '80px',
        color: theme.palette.getContrastText(deepOrange[500]),
    },

    cardhead: {
        position: "relative"
    },
    icon: {
        fontSize: '36px',
        lineHeight: '56px',
        width: '56px',
        height: '56px',
        textAlign: 'center'
    },
    button: {
        marginTop: theme.spacing(3)
    },
    game: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2),
        textAlign: 'center',
        boxSizing: 'border-box'
    },
    gamebutton: {
        width: 'calc(100% - 125px)',
        fontSize: '24px'
    }

}));

const UserDashboard = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container direction="row"
                justify="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Profile />
                </Grid>
                <Grid container item xs={12} sm={6}>

                    <Grid item xs={12} spacing={3}>
                        <Paper className={classes.game}>
                            <ButtonGroup style={{ width: '100%' }}>
                                <Button variant="contained" component={Link} to='/trainer-app' endIcon={<PlayCircleFilledWhiteIcon style={{ width: '40px', height: '40px' }} />} className={classes.gamebutton} color="primary" disableElevation>
                                    Enter Exercise
                                </Button>
                                <Button variant="outlined" color="primary" component={Link} to='/video-tutorial' style={{ width: '125px', fontSize: '18px' }}>
                                    Tutorial
                                </Button>
                            </ButtonGroup>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row"
                            justify="center" spacing={3}>
                            <Grid item xs className={classes.cardroot} >
                                <Card >
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                variant="rounded"
                                                className={classes.square}
                                                style={{ background: orange[500] }}
                                            >
                                                <AccountBalanceIcon className={classes.icon} />
                                            </Avatar>
                                        }
                                    />
                                    <CardContent>
                                        <p>My Plan</p>
                                        <Divider />
                                        <h3>499 monthly</h3>
                                    </CardContent>
                                </Card>

                            </Grid>
                            <Grid item xs className={classes.cardroot}>
                                <Card >
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                variant="rounded"
                                                className={classes.square}
                                                style={{ backgroundColor: green[500], }}
                                            >
                                                <AssignmentIcon className={classes.icon} />
                                            </Avatar>
                                        }
                                    />
                                    <CardContent>
                                        <p>Suggest A Friend</p>
                                        <Divider />
                                        <Button variant="outlined" color="primary" className={classes.button}>
                                            Suggest Now
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row"
                        justify="center" spacing={3}>

                        <Grid item xs className={classes.cardroot}>
                            <Card >
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            variant="rounded"
                                            className={classes.square}
                                            style={{ backgroundColor: cyan[500] }}
                                        >
                                            <AddBoxIcon className={classes.icon} />
                                        </Avatar>
                                    }
                                />
                                <CardContent>
                                    <p>Upgrade Plan</p>
                                    <Divider />
                                    <Button variant="outlined" color="primary" className={classes.button}>
                                        Suggest Now
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs className={classes.cardroot}>
                            <Card >
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            variant="rounded"
                                            className={classes.square}
                                            style={{ backgroundColor: deepOrange[500], }}>
                                            <ErrorOutlineIcon className={classes.icon} />
                                        </Avatar>
                                    }
                                />
                                <CardContent>
                                    <p>Total Rounds Played</p>
                                    <Divider />
                                    <h3>33</h3>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

        </div >
    )
}

export default UserDashboard
