import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';

import ProgressNote from '../../ProgressNote/ProgressNote';
import EvaluationType from './EvaluationType'

import { connect } from 'react-redux'
import UpdateNote from '../../UpdateNote/UpdateNote';
import DischargeNotes from '../../DischargeNote/DischargeNotes'
import CurrentEvaluation from './CurrentEvaluation'


const styles = (theme) => ({
    ...theme.SpreadThis,
    root: {
        flexGrow: 1,
        width: '80%',
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    tableBody: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    patient: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    }
})
class EvaluationMain extends Component {
    state = {
        eval_type: this.props.data.patient ? this.props.data.patient.evaluationDetails ? this.props.data.patient.evaluationDetails.evaluationType ? this.props.data.patient.evaluationDetails.evaluationType : "" : "" : "",

    }
    selectedEvalType = (type) => {
        this.setState({ eval_type: type })
        console.log(type)
    }
    findOutEvalType = (type) => {
        let selectedEval = null;
        switch (type) {
            case "mainEvaluation":
                selectedEval = <CurrentEvaluation history={this.props.history} />
                break;
            case "Progress Note":
                selectedEval = <ProgressNote history={this.props.history} />
                break;
            case "Update Note":
                selectedEval = <UpdateNote history={this.props.history} />;
                break;
            case "Discharge Note":
                selectedEval = <DischargeNotes history={this.props.history} />;
                break;
            default:
                selectedEval = <CurrentEvaluation history={this.props.history} />
                break;
        }
        return selectedEval
    }

    render() {
        const { classes, UI: { loading }, data: { patient: { firstName, lastName, age, phone, emPhone, emPerson, patientId, address, city, state, country, zip, } } } = this.props;
        // let evalType = this.props.data.patient ? this.props.data.patient.evaluationDetails ? this.props.data.patient.evaluationDetails.evaluationType ? this.props.data.patient.evaluationDetails.evaluationType : "" : "" : "";

        //console.log(evaluationType)
        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={8} md={8} sm={8}>
                        <Paper className={classes.paper}>

                            <Typography style={{ padding: "16px" }} variant="h6" gutterBottom>
                                Patient Information
                            </Typography>
                            <Grid container
                                justify="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item container justify="flex-start" xs={12} sm={6}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <b>Name :</b>   {lastName}{' '}{firstName}
                                    </Typography>
                                </Grid>
                                <Grid container justify="flex-start" item xs={12} sm={6}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <b>Patient Id :</b>  {patientId}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container
                                justify="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item container justify="flex-start" xs={12} sm={6}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <b>Address :</b>{address},{city}
                                    </Typography>

                                </Grid>
                                <Grid container justify="flex-start" item xs={12} sm={6}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <b>Phone : </b> {phone}
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Grid container
                                justify="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item container justify="flex-start" xs={12} sm={6}>
                                    <Typography variant="subtitle1" gutterBottom>  {state}, {country ? country.label ? country.label : '' : ''}-{zip}
                                    </Typography>
                                </Grid>
                                <Grid container justify="flex-start" item xs={12} sm={6}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <b>Em.Phone :</b>{emPhone}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} container justify="flex-start" sm={6}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <b>Date of Birth: </b><Moment format="MM/DD/YYYY">{age}</Moment>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container justify="flex-start" sm={6}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <b>Em.Contact Person :</b> {emPerson}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>
                    <Grid item xs md={4} sm={4}>
                        <Paper className={classes.paper}>
                            <EvaluationType selectedEvalType={this.selectedEvalType} history={this.props.history} />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12} md={6} sm={6}>
                        {/* <Paper className={classes.tableBody}> */}
                        {!loading ? this.findOutEvalType(this.state.eval_type) : this.findOutEvalType(this.state.eval_type)}
                        {/* {!loading ? this.state.eval_type === "progressNote" ? <ProgressNote /> : "" : ""}
                            {!loading ? this.state.eval_type === "updateNote" ? <UpdateNote /> : "" : ""}
                            {!loading ? this.state.eval_type === "dischargeNots" ? <DischargeNotes /> : "" : ""}
                            {!loading ? this.state.eval_type === "currentEvaluation" ? <CurrentEvaluation /> : "" : ""} */}

                        {/* </Paper> */}
                    </Grid>
                </Grid>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    UI: state.UI,
    data: state.data,
})
const mapActionToProps = {

}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(EvaluationMain))
