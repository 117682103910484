import React from 'react'
import { Formik, useField, Field } from 'formik'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormLabel from '@material-ui/core/FormLabel';
import { Input } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { CheckboxWithLabel } from 'formik-material-ui';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Myradio, CustomCheckbox } from '../FormComponents/FormComponents'
import { EyeMuscleStrengthLeft, EyeMuscleStrengthRight } from '../EvaluationSecond/PosturalVisualSymptoms'


//redux
import { connect } from 'react-redux'
import { updateEvaluation } from '../../../redux/actions/dataActions'

const useStyles = makeStyles(theme => ({
    ...theme.SpreadThis,
    root: {
        flexgrow: 1,
        width: '75%',
        margin: '0 auto',
    },
    tRoot: {
        flexgrow: 1,
        width: '95%',
        margin: '0 auto',
    },
    textfield: {
        padding: theme.spacing(4),
        textAlign: "center"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
})
)

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary,
        color: theme.palette.common.black,
        fontSize: 16,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const visualAcuity = [
    { title: 'Color Sensitivity' },
    { title: 'Depth Perception' },
    { title: 'Light Sensitivity' }
]

export const SelectOption = ({ ...props }) => {
    const [field] = useField(props)
    const classes = useStyles()
    return (
        <FormControl size="small" variant="outlined" className={classes.formControl}>
            <Select
                value={field.value}
                {...field}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value="1/10 VAS">1/10 VAS</MenuItem>
                <MenuItem value="2/10 VAS">2/10 VAS</MenuItem>
                <MenuItem value="3/10 VAS">3/10 VAS</MenuItem>
                <MenuItem value="4/10 VAS">4/10 VAS</MenuItem>
                <MenuItem value="5/10 VAS">5/10 VAS</MenuItem>
                <MenuItem value="6/10 VAS">6/10 VAS</MenuItem>
                <MenuItem value="7/10 VAS">7/10 VAS</MenuItem>
                <MenuItem value="8/10 VAS">8/10 VAS</MenuItem>
                <MenuItem value="9/10 VAS">9/10 VAS</MenuItem>
                <MenuItem value="10/10 VAS">10/10 VAS</MenuItem>
            </Select>
        </FormControl>
    )

}

const NumberInput = ({ placeholder, ...props }) => {
    const [field] = useField(props);
    const classes = useStyles();
    return (
        <Input

            className={classes.formControl}
            variant="outlined"
            {...field}
            type="number"
            placeholder={placeholder}
        />
    )
}

const initialObservationState = {
    eyeDominance: '',
    visualAcuityDevelopment: '',
    eyeOpening: '',
    orientation: '',
    pupillaryDistance: '',
    mood: '',
    leftObservation: {
        directLightResponse: '',
        consenualResponse: '',
        pupilSizeandShape: '',
        romScale: '',
        romHorizontalRight: '',
        romHorizontalLeft: '',
        romVerticalUp: '',
        romVerticalDown: '',
        romofEyeMusclesRange: '',
        eyeMuscleStrength: {
            LR: '',
            MR: '',
            SR: '',
            IR: '',
            SO: '',
            IO: ''
        },
        pathologicalReflexes: '',
        pupillaryDarkReflexes: '',
        otherPupilReflexes: '',
        ciliospinalReflexs: '',
        nearAccommodativeTriad: '',
        cornealReflex: '',
        vestibuloOcularReflex: '',
        palpebralOculogyricReflex: '',
        lacrimatoryReflex: '',
        optokineticReflex: '',
        oculocardiacReflex: '',
        oculoRespiratoryReflex: '',
        oculoErneticReflex: '',
        distanceofTwoEyes: '',
        lawsofEyeMovements: '',
        visualAcuity: '',
        edema: '',
    },

    rightObservation: {
        directLightResponse: '',
        consenualResponse: '',
        pupilSizeandShape: '',
        romScale: '',
        romHorizontalRight: '',
        romHorizontalLeft: '',
        romVerticalUp: '',
        romVerticalDown: '',
        romofEyeMusclesRange: '',
        eyeMuscleStrength: {
            LR: '',
            MR: '',
            SR: '',
            IR: '',
            SO: '',
            IO: ''
        },
        pathologicalReflexes: '',
        pupillaryDarkReflexes: '',
        otherPupilReflexes: '',
        ciliospinalReflexs: '',
        nearAccommodativeTriad: '',
        cornealReflex: '',
        vestibuloOcularReflex: '',
        palpebralOculogyricReflex: '',
        lacrimatoryReflex: '',
        optokineticReflex: '',
        oculocardiacReflex: '',
        oculoRespiratoryReflex: '',
        oculoErneticReflex: '',
        distanceofTwoEyes: '',
        lawsofEyeMovements: '',
        visualAcuity: '',
        edema: '',
    }
}

const Observation = ({ ...props }) => {
    const classes = useStyles();

    const findObservationType = () => {
        let observationData = {};
        let evalType = props.patient ? props.patient.evaluationDetails ? props.patient.evaluationDetails.evaluationType ? props.patient.evaluationDetails.evaluationType : "" : "" : ""
        if (evalType === "mainEvaluation") {
            observationData = { ...props.mainObservation }

            return observationData && Object.keys(observationData).length > 0 ? observationData : initialObservationState
            // if (observationData && Object.keys(observationData).length > 0) {
            //     observationOut = observationData
            // }
            // observationOut = initialObservationState

        }
        else if (evalType === "Progress Note") {
            observationData = props.progressObservation
            return observationData && Object.keys(observationData).length > 0 ? observationData : initialObservationState
        }
        else if (evalType === "Update Note") {
            observationData = props.updateObservation
            return observationData && Object.keys(observationData).length > 0 ? observationData : initialObservationState
        }
        else if (evalType === "Discharge Note") {
            observationData = props.dischargeObservation
            return observationData && Object.keys(observationData).length > 0 ? observationData : initialObservationState

        }
        else {
            return initialObservationState

        }
    }

    let evalDetails = {
        ...props.patient.evaluationDetails,
        key: "objectiveObservation",
    }



    return (
        <Formik initialValues={findObservationType()}
            onSubmit={(values, { setSubmitting, resetForm }) => {

                const observationData = { objectiveObservation: values }
                setTimeout(() => {

                    props.updateEvaluation(evalDetails, observationData, (isSuccess) => { props.handleChange(4, isSuccess); setSubmitting(false) })

                    // resetForm()
                }, 500)
            }}
        >
            {({ values, isSubmitting, submitForm }) => (
                <div className={classes.root}>
                    <Grid container spacing={3} direction="column">
                        <Paper className={classes.paper}>
                            <Grid container justify="flex-start" spacing={2}>
                                <Grid item xs>
                                    <p style={{ color: "red", textAlign: 'left', marginLeft: '24px' }}>
                                        {props.patient ? props.patient.evaluationDetails ? props.patient.evaluationDetails.evaluationType ? props.patient.evaluationDetails.evaluationType.toUpperCase() === "MAINEVALUATION" ? (<p>MAIN EVAUATION</p>) : props.patient.evaluationDetails.evaluationType.toUpperCase() : "" : "" : ""}
                                    </p>
                                </Grid>
                            </Grid>
                            <Typography color="primary" className={classes.textfield} variant="h4" >Objectives of Observation</Typography>
                            <Paper className={classes.tRoot}>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell align="left">Left Eye</StyledTableCell>
                                                <StyledTableCell align="left">Right Eye</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow hover role="checkbox" tabIndex={-1}>

                                                <TableCell align="left">
                                                    Direct Light Response
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.directLightResponse" label="Brisk " value="Brisk" />
                                                    <Myradio type="radio" name="leftObservation.directLightResponse" label="Sluggish" value="Sluggish" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.directLightResponse" label="Brisk " value="Brisk" />
                                                    <Myradio type="radio" name="rightObservation.directLightResponse" label="Sluggish" value="Sluggish" />
                                                </TableCell>

                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Consenual Response
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.consenualResponse" label="Present " value="Present" />
                                                    <Myradio type="radio" name="leftObservation.consenualResponse" label="Absent" value="Absent" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.consenualResponse" label="Present " value="Present" />
                                                    <Myradio type="radio" name="rightObservation.consenualResponse" label="Absent" value="Absent" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Pupil Size and Shape
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.pupilSizeandShape" label="Accommodation " value="Accommodation" />
                                                    <Myradio type="radio" name="leftObservation.pupilSizeandShape" label="Non Reactive" value="Non Reactive" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.pupilSizeandShape" label="Accommodation " value="Accommodation" />
                                                    <Myradio type="radio" name="rightObservation.pupilSizeandShape" label="Non Reactive" value="Non Reactive" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Eye Opening
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.eyeOpening" label="Symmetry" value="Symmetry" />
                                                    <Myradio type="radio" name="leftObservation.eyeOpening" label="Asymmetry" value="Asymmetry" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.eyeOpening" label="Symmetry" value="Symmetry" />
                                                    <Myradio type="radio" name="rightObservation.eyeOpening" label="Asymmetry" value="Asymmetry" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Laws of Eye Movements
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.lawsofEyeMovements" label="Doner's Law" value="Doner's Law" />
                                                    <Myradio type="radio" name="leftObservation.lawsofEyeMovements" label="Hering's" value="Hering's" />
                                                    <Myradio type="radio" name="leftObservation.lawsofEyeMovements" label="Sherrington" value="Sherrington" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.lawsofEyeMovements" label="Doner's Law" value="Doner's Law" />
                                                    <Myradio type="radio" name="rightObservation.lawsofEyeMovements" label="Hering's" value="Hering's" />
                                                    <Myradio type="radio" name="rightObservation.lawsofEyeMovements" label="Sherrington" value="Sherrington" />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Edema
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.edema" label="WNL" value="WNL" />
                                                    <Myradio type="radio" name="leftObservation.edema" label="WFL" value="WFL" />
                                                    <Myradio type="radio" name="leftObservation.edema" label="Minimal" value="Minimal" />
                                                    <Myradio type="radio" name="leftObservation.edema" label="Moderate" value="Moderate" />
                                                    <Myradio type="radio" name="lefttObservation.edema" label="Severe" value="Severe" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.edema" label="WNL" value="WNL" />
                                                    <Myradio type="radio" name="rightObservation.edema" label="WFL" value="WFL" />
                                                    <Myradio type="radio" name="rightObservation.edema" label="Minimal" value="Minimal" />
                                                    <Myradio type="radio" name="rightObservation.edema" label="Moderate" value="Moderate" />
                                                    <Myradio type="radio" name="rightObservation.edema" label="Severe" value="Severe" />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Pupillary Reflexes
                                                            </TableCell>
                                                <TableCell align="left">
                                                </TableCell>
                                                <TableCell align="left">
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Ciliospinal reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.ciliospinalReflexs" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.ciliospinalReflexs" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.ciliospinalReflexs" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.ciliospinalReflexs" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Corneal reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.cornealReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.cornealReflex" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.cornealReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.cornealReflex" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Lacrimatory reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.lacrimatoryReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.lacrimatoryReflex" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.lacrimatoryReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.lacrimatoryReflex" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Near accommodative triad
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.nearAccommodativeTriad" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.nearAccommodativeTriad" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.nearAccommodativeTriad" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.nearAccommodativeTriad" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Oculo-ernetic reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.oculoErneticReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.oculoErneticReflex" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.oculoErneticReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.oculoErneticReflex" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Oculo-respiratory reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.oculoRespiratoryReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.oculoRespiratoryReflex" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.oculoRespiratoryReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.oculoRespiratoryReflex" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Oculocardiac reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.oculocardiacReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.oculocardiacReflex" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.oculocardiacReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.oculocardiacReflex" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Optokinetic reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.optokineticReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.optokineticReflex" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.optokineticReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.optokineticReflex" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Other pupil reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.otherPupilReflexes" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.otherPupilReflexes" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.otherPupilReflexes" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.otherPupilReflexes" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Palpebral oculogyric reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.palpebralOculogyricReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.palpebralOculogyricReflex" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.palpebralOculogyricReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.palpebralOculogyricReflex" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Pupillary dark reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.pupillaryDarkReflexes" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.pupillaryDarkReflexes" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.pupillaryDarkReflexes" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.pupillaryDarkReflexes" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Pupillary light reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.pupillaryLightReflexes" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.pupillaryLightReflexes" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.pupillaryLightReflexes" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.pupillaryLightReflexes" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Vestibulo-ocular reflex
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.vestibuloOcularReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="leftObservation.vestibuloOcularReflex" label="-" value="negative" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.vestibuloOcularReflex" label="+" value="positive" />
                                                    <Myradio type="radio" name="rightObservation.vestibuloOcularReflex" label="-" value="negative" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    ROM of Eye Muscles in
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="leftObservation.romScale" label="Degree" value="degree" />
                                                    <Myradio type="radio" name="leftObservation.romScale" label="m.m" value="m.m" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="rightObservation.romScale" label="Degree" value="degree" />
                                                    <Myradio type="radio" name="rightObservation.romScale" label="m.m" value="m.m" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell align="left">
                                                    Horizontal
                                                            </StyledTableCell>
                                                <StyledTableCell align="left">

                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Right
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="leftObservation.romHorizontalRight" type="number" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="rightObservation.romHorizontalRight" type="number" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Left
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="leftObservation.romHorizontalLeft" type="number" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="rightObservation.romHorizontalLeft" type="number" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell align="left">
                                                    Vertical
                                                            </StyledTableCell>
                                                <StyledTableCell align="left">

                                                </StyledTableCell>
                                                <StyledTableCell align="left">

                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Up
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="leftObservation.romVerticalUp" type="number" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="rightObservation.romVerticalUp" type="number" />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Down
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="leftObservation.romVerticalDown" type="number" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="rightObservation.romVerticalDown" type="number" />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Pain
                                                            </TableCell>
                                                <TableCell align="left">

                                                    <SelectOption
                                                        type="select"
                                                        name="leftObservation.pain"
                                                        value={values.leftObservation.pain}
                                                        label="pain"
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <SelectOption
                                                        type="select"
                                                        name="rightObservation.pain"
                                                        value={values.rightObservation.pain}
                                                        label="pain"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Visual Acuity
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <CustomCheckbox option={visualAcuity} name="leftObservation.visualAcuity" label=" " />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <CustomCheckbox option={visualAcuity} name="rightObservation.visualAcuity" label=" " />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Eye Muscle Strength (Oxford scale) *if it is applicable
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <EyeMuscleStrengthLeft value={values.leftObservation.eyeMuscleStrength} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <EyeMuscleStrengthRight value={values.rightObservation.eyeMuscleStrength} />
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Paper>
                            <Paper className={classes.tRoot}>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>
                                                    {/* Other Observations */}
                                                    <FormLabel focused className={classes.formlabel}>Other Observations </FormLabel>

                                                </StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Eye Dominance
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="eyeDominance" label="Right" value="Right" />
                                                    <Myradio type="radio" name="eyeDominance" label="Left" value="Left" />
                                                </TableCell>

                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Visual Acuity Development
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Myradio type="radio" name="visualAcuityDevelopment" label="Under One Month 20/800 - 20/200" value="Under One Month 20/800 - 20/200" />
                                                    <Myradio type="radio" name="visualAcuityDevelopment" label="Two Month 20/150" value="Two Month 20/150" />
                                                    <Myradio type="radio" name="visualAcuityDevelopment" label="Four Month 20/60" value="Four Month 20/60" />
                                                    <Myradio type="radio" name="visualAcuityDevelopment" label="Six Month 20/20" value="Six Month 20/20" />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Mood
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        name="mood"
                                                        type="checkbox"
                                                        value="Alert"
                                                        Label={{ label: 'Alert' }}
                                                    />
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        Label={{ label: 'Cooperation' }}
                                                        type="checkbox"
                                                        name="mood"
                                                        value="Cooperation"
                                                    />
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        Label={{ label: 'Motivation' }}
                                                        type="checkbox"
                                                        name="mood"
                                                        value="Motivation"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Orientation
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        name="orientation"
                                                        type="checkbox"
                                                        value="Person"
                                                        Label={{ label: 'Person' }}
                                                    />
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        Label={{ label: 'Place' }}
                                                        type="checkbox"
                                                        name="orientation"
                                                        value="Place"
                                                    />
                                                    <Field
                                                        component={CheckboxWithLabel}
                                                        Label={{ label: 'Time' }}
                                                        type="checkbox"
                                                        name="orientation"
                                                        value="Time"
                                                    />

                                                    {/* <CustomCheckboxHorizontal option={orientation} name="orientation" label="" /> */}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    Pupillary Distance
                                                            </TableCell>
                                                <TableCell align="left">
                                                    <NumberInput name="pupillaryDistance" className={classes.formControl} type="number" placeholder="c.m" />
                                                </TableCell>

                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell align="left">
                                                    <Grid container justify="center" >
                                                        <Button
                                                            className={classes.button}
                                                            disabled={isSubmitting}
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={submitForm}
                                                        >
                                                            save & continue
                                        </Button>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align="left">

                                                </TableCell>

                                            </TableRow>



                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>

                        </Paper>
                    </Grid>

                </div >
            )
            }


        </Formik>
    )
}


const mapStateToProps = (state) => ({
    patient: state.data.patient ? state.data.patient : {},
    mainObservation: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation.objectiveObservation ? state.data.patient.evaluation.objectiveObservation : {} : {} : {},
    progressObservation: state.data.patient ? state.data.patient.progressNote ? state.data.patient.progressNote.objectiveObservation ? state.data.patient.progressNote.objectiveObservation : {} : {} : {},
    updateObservation: state.data.patient ? state.data.patient.updateNotes ? state.data.patient.updateNotes.objectiveObservation ? state.data.patient.updateNotes.objectiveObservation : {} : {} : {},
    dischargeObservation: state.data.patient ? state.data.patient.dischargeNotes ? state.data.patient.dischargeNotes.objectiveObservation ? state.data.patient.dischargeNotes.objectiveObservation : {} : {} : {},
    UI: state.UI
})

export default connect(mapStateToProps, { updateEvaluation })(Observation)
