import React from 'react'
import { Grid, Paper, } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'


const styles = (theme) => ({
    ...theme.SpreadThis,
    root: {
        width: '75%',
        textAlign: 'center',
        margin: 'auto'

    },
    main: {
        display: 'flex',
        justify: 'space-around',
        wrap: 'flex-wrap',

    },
    avatar: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
})

class Contact extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>


                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Paper elevation={3} >
                            <h1 style={{ padding: '16px' }}>We're Here to Help You</h1>
                            <Grid container direction="row" justify="center" alignItems="center" className={classes.avatar}>

                            </Grid>
                            <h3>If you need any help, Please mail us on -<a href="visionrehabilitations@gmail.com">visionrehabilitations@gmail.com</a> </h3>
                            <img src="contact2.png" alt="contact" style={{ height: "400px", maxWidth: "100%", margin: 'auto' }} />


                        </Paper>


                    </Grid>
                    <Paper>
                    </Paper>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Contact)