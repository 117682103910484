import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Button from '@material-ui/core/Button'

//component
import AppoinmentForm from './AppoinmentForm';
import EditPatientInfo from './EditPatientInfo';
//redux
import { connect } from 'react-redux'
import { addAppointment, setCurrentPatient } from '../../../redux/actions/dataActions'

const styles = (theme) => ({
    ...theme.SpreadThis,
    root: {
        width: '75%',
        margin: '0 auto'
    },
    textfield: {
        padding: theme.spacing(2),
        textAlign: "center",
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(5)
    },
    topHead: {
        textAlign: "right"
    }

})
export class Appointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: ''
        }
        this.cancel = "";
    }
    handleChange = (event) => {
        const query = event.target.value
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.props.setCurrentPatient(query);

        });
    };



    render() {
        const { classes, patient: { firstName, lastName, age, phone, emPhone, emPerson, patientId, address, city, state, country, zip, } } = this.props

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container justify="space-around" spacing={2}>
                        <Grid item xs>
                            <p style={{ color: "red" }}>
                                {this.props.patient ? this.props.patient.evaluationDetails ? this.props.patient.evaluationDetails.evaluationType ? this.props.patient.evaluationDetails.evaluationType.toUpperCase() === "MAINEVALUATION" ? (<p>MAIN EVAUATION</p>) : this.props.patient.evaluationDetails.evaluationType.toUpperCase() : "" : "" : ""}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                    >
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Typography
                                color="primary"
                                className={classes.textfield}
                                variant="h4"
                            >
                                Appointment
                                </Typography>

                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth className={classes.margin} variant="outlined">
                                <OutlinedInput
                                    className={classes.search}
                                    name="query"
                                    id="outlined-adornment-amount"
                                    value={this.state.query}
                                    onChange={this.handleChange}
                                    placeholder="Eg: Patient Id..."
                                    type="search"
                                    startAdornment={<SearchIcon position="start" />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <hr style={{ margin: '20px 0 20px 0', }} />
                    <Grid container spacing={1} style={{ paddingLeft: '30px' }}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <b>Name :</b>  {lastName}{' '}{firstName}
                            </Typography>
                        </Grid>
                        <Grid container item xs={6}>
                            <Grid item xs>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Patient Id :</b>  {patientId}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Button
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"

                                    component={EditPatientInfo}
                                //to="/evafirst"
                                >
                                    Edit
                                        </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <b>Address :</b> {address},{city}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {state}, {country ? country.label ? country.label : '' : ''}-{zip}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <b>Phone : </b> {phone}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <b>Em.Contact Person :</b> {emPerson}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <b>Date of Birth: </b><Moment format="MM/DD/YYYY">{age}</Moment>
                            </Typography> </Grid>
                        {/* <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <b>Em.Contact Person :</b> {emPerson}
                            </Typography>
                        </Grid> */}

                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <b>Em.Phone :</b>{emPhone}
                            </Typography> </Grid>

                    </Grid>

                    {/* <hr style={{ marginBottom: '60px', border: 'none' }} /> */}
                    <AppoinmentForm handleChange={this.props.handleChange} />
                </Paper >
            </div >
        )
    }
}
Appointment.propTypes = {
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    // patient: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
    user: state.user,
    patient: state.data.patient,
    UI: state.UI

})
const mapActionsToProps = {
    setCurrentPatient,
    addAppointment
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Appointment))
