export default {
    palette: {
        primary: {
            light: '#33ab9f',
            main: '#009688',
            dark: '#00695f',
            contrastText: '#fff'
        },
        secondary: {
            light: '#f5dea3',
            main: '#42e6a4',
            dark: '#02a8a8',
            contrastText: '#fff'
        }
    },
    spreadThis: {
        spacing: factor => `${0.25 * factor}rem`,
        typography: {
            useNextVarients: true
        },
        form: {
            textAlign: 'center',
            padding: '30px 30px 0 30px'
        },
        pageTitle: {
            margin: '0 auto 10px auto'
        },
        textField: {
            margin: '5px auto 5px auto'
        },
        button: {
            marginTop: 20,
            position: 'relative'
        },
        image: {
            margin: '10px auto 10px auto',
            maxWidth: '80px',
        },
        img: {
            maxWidth: '100%',
            height: '500px',
            // opacity: '.8',
            // filter: 'alpha(opacity=50)'
        },
        customError: {
            color: 'red',
            fontSize: '0.8rem',
            marginTop: 10
        },
        progress: {
            position: 'absolute'
        },

    }
}