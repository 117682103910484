import React from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Formik, } from 'formik';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
//import { Link } from 'react-router-dom'
//Form compoent importing
import {
    LowerMotorNeuronEyeDefectsatPheripheralLevelNerve,
    CvaHeadInjury,
    ExternalOpthalmoplegia,
    SubarachnoidSpace,
    EyeMuscleGeneralMultiDefects,
    PosturalVisualSymptoms,
    MidbrainLevelPregaglionicArea
}
    from './PosturalVisualSymptoms';
//redux
import { updateEvaluation } from '../../../redux/actions/dataActions'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
    ...theme.SpreadThis,
    root: {
        flexgrow: 1,
        width: '75%',
        margin: '0 auto'
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'left',
        alignItem: "center"
    },
    textfield: {
        padding: theme.spacing(4),
        textAlign: "center"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    itemspaceRight: {
        marginRight: theme.spacing(2)
    },
    itemspaceLeft: {
        marginRight: theme.spacing(3)
    },
    formlabel: {
        paddingBottom: theme.spacing(3),

    },
    button: {
        margin: theme.spacing(3)
    },
    main: {
        padding: theme.spacing(4)
    }
}))

const EvaluationSecond = ({ ...props }) => {
    const classes = useStyles()
    const { patient } = props
    let evalDetails = {
        key: "evaluationSecond",

        ...patient.evaluationDetails
    }

    return (
        <Formik initialValues={props.evaluationSecond && Object.keys(props.evaluationSecond).length > 0 ? props.evaluationSecond : {
            cvaHeadInjury: {
                abscess: "",
                aneurysm: '',
                bloodVesselBlockage: '',
                brainCancer: '',
                hemorrhage: '',
                infarction: '',
                necrosis: '',
                sclerosis: '',
                tumor: ''
            },
            externalOpthalmoplegia: {
                ciliaryMuscleNumbness: '',
                ciliaryMuscleParalysis: '',
                dilatedFixedPupil: '',
                irritabilityBlurryVision: '',
                suspenseLigamentAlteration: '',
                uncomfortableFeeling: ''
            },
            midbrainLevelPregaglionicArea: {
                ipsilateralHemiplegia: '',
                contralateralHemiplegia: ''

            },
            subarachnoidSpace: {
                contralateralHomohemianopia: '',
                ipsilateralHemiplegia: '',
                ivCranialNerveMeningitis: '',
                microvascularDiseaseDuetoHighBloodPressureorDiabetes: '',
                posteriorCerebralArteryIschemia: '',
                posteriorCommunicationArteryAneurysm: '',
                tbPyogenicorFungal: ''
            },
            posturalVisualSymptoms: {
                abnormalGait: '',
                hallucinations: '',
                abnormalPosturalTone: '',
                migraineHeadache: '',
                scinteillatingScotoma: '',
                visualIllusions: ''
            },
            lowerMotorNeuronEyeDefectsatPheripheralLevelNerve: {
                accidentalInjury: '',
                fractureofOrbit: '',
                injurytoIIICranialNerveEnding: '',
                postGanglionicAreaPressure: '',
                tearofMusclesAroundEyes: ''
            },
            eyeMuscleGeneralMultiDefects: {
                anosometropia: '',
                antisacccades: '',
                convergenceInsufficiency: '',
                defectiveMotorExecution: '​',
                defectiveMotorPlanning: '',
                disabledKinestheticFeedback: '',
                disorganizedOrthographicCoding: '',
                doubleVision: '',
                dyslexia: '',
                fineMotorDevelopmentalDelay: '',
                impairedVisualMotorCoordination: '',
                impairedVisualMotorSkills: '',
                letterRecognitionDifficulty: '',
                nyctalopia: '',
                nystagmus: '',
                readingSkillImpairment: '',
                strabismus: ''
            }
        }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const evaluationData = { evaluationSecond: values }
                setTimeout(() => {
                    props.updateEvaluation(evalDetails, evaluationData, (isSuccess) => { props.handleChange(3, isSuccess); setSubmitting(false) })

                    // resetForm()
                }, 500)
            }}>
            {({ values, isSubmitting, submitForm }) => (
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Grid container justify="flex-start" spacing={2}>
                            <Grid item xs>
                                <p style={{ color: "red", textAlign: 'left' }}>
                                    {props.patient ? props.patient.evaluationDetails ? props.patient.evaluationDetails.evaluationType ? props.patient.evaluationDetails.evaluationType.toUpperCase() === "MAINEVALUATION" ? (<p>MAIN EVAUATION</p>) : props.patient.evaluationDetails.evaluationType.toUpperCase() : "" : "" : ""}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Typography color="primary" className={classes.textfield} variant="h4" >Evaluation Part Two</Typography>
                            <Divider />
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={3}
                            className={classes.main}
                        >

                            <Grid item xs={6}>
                                <LowerMotorNeuronEyeDefectsatPheripheralLevelNerve
                                    value={values.lowerMotorNeuronEyeDefectsatPheripheralLevelNerve}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <PosturalVisualSymptoms
                                    value={values.posturalVisualSymptoms}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CvaHeadInjury value={values.cvaHeadInjury} />
                            </Grid>
                            <Grid item xs={6}>
                                <SubarachnoidSpace value={values.subarachnoidSpace} />
                            </Grid>

                            <Grid item xs={12}>
                                <EyeMuscleGeneralMultiDefects value={values.eyeMuscleGeneralMultiDefects} />
                            </Grid>
                            <Grid item xs={6}>
                                <ExternalOpthalmoplegia value={values.externalOpthalmoplegia} />
                            </Grid>
                            <Grid item xs={6}>
                                <MidbrainLevelPregaglionicArea value={values.midbrainLevelPregaglionicArea} />
                            </Grid>

                            <Grid container justify="center" >
                                <Button
                                    className={classes.button}

                                    disabled={isSubmitting}
                                    color="primary"
                                    variant="contained"
                                    onClick={submitForm}
                                >
                                    save & continue
                                     </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )
            }
        </Formik >
    )
}

const mapActionToProps = {
    updateEvaluation
}
const mapStateToProps = (state) => ({
    patient: state.data.patient,
    evaluationSecond: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation.evaluationSecond ? state.data.patient.evaluation.evaluationSecond : {} : {} : {},
    UI: state.UI
})

export default connect(mapStateToProps, mapActionToProps)(EvaluationSecond)
