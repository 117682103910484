import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Alert from '@material-ui/lab/Alert';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Appointment from '../Evaluation/Appointment/Appointment';
import Observation from '../Evaluation/ObjectiveObservation/Obeservation';
//import DischargeForm from '../Evaluation/DischargeForm/DischargeForm';
import Snackbar from '@material-ui/core/Snackbar';
// import DischargePlan from '../Evaluation/DischargeForm/DischargePlan';
import DischargeForm from '../Evaluation/DischargeForm/DischargeForm';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        margin: '0 auto',
    },

    tab: {
        display: "flex",
        justifyContent: "center"
    }

}));

export default function NotesTab() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [toastValue, setToastValue] = React.useState('');

    const handleChange = (newValue, toastValue) => {
        setToastValue(toastValue ? "success" : "error");
        if (toastValue && newValue === 4) {
            setValue(2)
        }
        else {
            setValue(newValue);
        }
    };

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color="default"  >
                <Snackbar open={toastValue ? true : false} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={3000} onClose={() => setToastValue("")}>
                    <Alert onClose={() => setToastValue("")} severity={toastValue}>
                        {toastValue === "success" ? "Saved Successfully" : "Error Occured while saving"}
                    </Alert>
                </Snackbar>     <Tabs
                    value={value}
                    onChange={(m, n) => setValue(n)}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable auto tabs example"
                    centered
                >

                    <Tab label="Appointment" {...a11yProps(0)} />

                    <Tab label="Observations" {...a11yProps(1)} />

                    <Tab label="Discharge Form" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Appointment handleChange={handleChange} />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Observation handleChange={handleChange} />
            </TabPanel>

            <TabPanel value={value} index={2}>
                <DischargeForm />
            </TabPanel>
        </div>
    );
}
