import React from 'react'
import { useField, useFormikContext, Field } from 'formik'
import { Radio } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Input } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import { FormGroup } from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import Divider from '@material-ui/core/Divider';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Grid from '@material-ui/core/Grid'


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = makeStyles(theme => ({
    ...theme.SpreadThis,
    checkboxtag: {
        padding: theme.spacing(1.5)
    },
    datestyle: {
        width: '99%',
    },
    durationstyle: {
        paddingTop: theme.spacing(2)
    },
    divider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(.5)
    },
    labelstyle: {
        padding: theme.spacing(3),
        textAlign: 'center',
        fontSize: '30px'
    },
    textboxfullwidth: {
        width: '100%',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    textField: {
        margin: '5px auto 5px auto'
    },
    treatStyle: {
        display: 'flex',
        flexDirection: 'row'
    }, option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
})
)
// mui Radio button component
export const Myradio = ({ label, ...props }) => {
    const [field] = useField(props)
    return (
        <FormControlLabel {...field} control={<Radio />} label={label} />
    )
}

//mui textField component
export const MyTextField = ({ placeholder, ...props }) => {
    const [field, meta] = useField(props)
    const errorText = meta.error && meta.touched ? meta.error : ''
    return (
        <TextField label={placeholder} fullWidth {...field} placeholder={placeholder} helperText={errorText} error={!!errorText} />
    )
}
//mui textField component outlined 
export const CustomTextField = ({ placeholder, ...props }) => {
    //const classes = useStyles();
    const [field, meta] = useField(props)
    const errorText = meta.error && meta.touched ? meta.error : ''
    return (
        <TextField label={placeholder} size="small" variant="outlined" {...field} placeholder={placeholder} helperText={errorText} error={!!errorText} />
    )
}

// mui date picker component
export const DatePickerField = ({ ...props }) => {
    const classes = useStyles();
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        // <DatePicker
        //     className={classes.datestyle}
        //     {...field}
        //     {...props}
        //     label={props.placeholder}
        //     selected={(field.value && new Date(field.value)) || null}
        //     onChange={val => {
        //         setFieldValue(field.name, val);
        //     }}
        // />
        <KeyboardDatePicker
            {...field}
            {...props}
            className={classes.datestyle}
            autoOk
            variant="inline"
            // inputVariant="outlined"
            label={props.placeholder}
            size="small"
            format="MM/dd/yyyy"
            InputAdornmentProps={{ position: "start" }}
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val);
            }}
        />
    );
};
//date picker without style
export const CustomDatePicker = ({ label, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        <KeyboardDatePicker
            {...field}
            {...props}
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={label}
            size="small"
            format="MM/dd/yyyy"
            InputAdornmentProps={{ position: "start" }}
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val);
            }}
        />
    );
};

// mui combobox component
export const ComboBox = ({ OptionList, label, placeholder, ...props }) => {
    const [field] = useField(props)
    const { setFieldValue } = useFormikContext();
    return (
        <Autocomplete
            {...field}
            options={OptionList}
            getOptionLabel={(option) => option.title}
            onChange={(val, data) => {
                setFieldValue(field.name, data);
            }}

            renderInput={(params) =>
                <TextField
                    {...params}
                    label={placeholder}
                    fullWidth

                />
            }
        />
    );
}

//mui number picker field
export const NumberInput = ({ placeholder, ...props }) => {
    const [field] = useField(props);
    const classes = useStyles();
    return (
        <Input
            fullWidth
            className={classes.durationstyle}
            {...field}
            type="number"
            placeholder={placeholder}
        />
    )
}


export const CheckboxesTags = ({ label, options, ...props }) => {
    const [field] = useField(props)
    const { setFieldValue } = useFormikContext()
    const classes = useStyles();
    return (
        <Autocomplete
            className={classes.checkboxtag}
            {...field}
            multiple
            id="checkboxes-tags-demo"
            onChange={(val, data) => {
                setFieldValue(field.name, data);
            }}
            options={options}
            disableCloseOnSelect
            getOptionLabel={option => option.title}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.title}
                </React.Fragment>
            )}
            style={{ width: 500 }}
            renderInput={params => (
                <TextField {...params} fullWidth variant="outlined" label={label} placeholder="Favorites" />
            )}
        />
    );
}

//custom checkbox withoue divider
export const CustomCheckbox = ({ option, label, ...props }) => {
    const [field] = useField(props)
    return (
        <div>
            <FormLabel style={{ textAlign: "left", paddingBottom: '8px' }} component="legend" color="primary" focused>{label}</FormLabel>
            {option.map((item, i) => (
                <FormGroup key={i}>
                    <Field
                        component={CheckboxWithLabel}
                        key={i}
                        {...field}
                        type="checkbox"
                        value={item.title}
                        Label={{ label: item.title }}
                    />
                </FormGroup>
            ))}
        </div>
    )
}

//custom checkbox withoue divider
export const CustomCheckboxHorizontal = ({ option, label, ...props }) => {
    const [field] = useField(props)
    return (
        <div>
            <FormLabel style={{ textAlign: "left", paddingBottom: '8px' }} component="legend" color="primary" focused>{label}</FormLabel>
            {option.map((item, i) => (
                <FormGroup key={i}>
                    <Grid container direction="row">
                        <Field
                            component={CheckboxWithLabel}
                            key={i}
                            {...field}
                            type="checkbox"
                            value={item.title}
                            Label={{ label: item.title }}
                        />
                    </Grid>
                    <Field
                        component={CheckboxWithLabel}
                        key={i}
                        {...field}
                        type="checkbox"
                        value={item.title}
                        Label={{ label: item.title }}
                    />
                </FormGroup>
            ))}
        </div>
    )
}

export const MultiSelectDropebox = ({ OptionList, label, placeholder, ...props }) => {
    const [field] = useField(props)
    // const classes = useStyles()
    const { setFieldValue } = useFormikContext();
    return (
        <Autocomplete
            {...field}
            multiple
            id="tags-standard"
            options={OptionList}
            getOptionLabel={(option) => option.title}
            // defaultValue={[OptionList[13]]}
            onChange={(val, data) => {
                // if (Array.isArray(data) && data.length !== 0) {
                //     setFieldValue(field.name, data[0].title);
                // }
                // else {
                //     setFieldValue(field.name, data);
                // }
                setFieldValue(field.name, data);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    placeholder={placeholder}
                />
            )} />
    )
}

//custom checkbox with divider
export const CustomCheckboxGoals = ({ option, label, ...props }) => {
    const [field] = useField(props)
    const classes = useStyles()
    return (
        <div>
            <FormLabel className={classes.labelstyle} component="legend" color="primary" focused>{label}</FormLabel>
            {option.map((item, i) => (
                <FormGroup key={i}>
                    <Field
                        component={CheckboxWithLabel}
                        {...field}
                        key={i}
                        type="checkbox"
                        value={item.title}
                        Label={{ label: item.title }}
                    />
                    <Divider className={classes.divider} varient="middle" />
                </FormGroup>
            ))}
        </div>
    )
}


//min height text area 
export const MinHeightTextarea = ({ placeholder, ...props }) => {
    const classes = useStyles()
    const [field] = useField(props)
    return <TextareaAutosize
        {...field}
        rowsMin={6}
        className={classes.textboxfullwidth}
        aria-label="maximum height"
        rowsMax={6}
        placeholder={placeholder}
    />;
}

//mui textField component
export const MyDialogTextField = ({ label, placeholder, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props)
    const errorText = meta.error && meta.touched ? meta.error : ''
    return (
        <TextField
            label={label}
            fullWidth
            {...field}
            placeholder={placeholder}
            helperText={errorText}
            error={!!errorText}
            className={classes.textField}
        />
    )
}
export const MyDialogTextHalf = ({ label, placeholder, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props)
    const errorText = meta.error && meta.touched ? meta.error : ''
    return (
        <TextField
            label={label}
            fullWidth
            {...field}
            placeholder={placeholder}
            helperText={errorText}
            error={!!errorText}
            className={classes.textField}
        />
    )
}
//mui textField component
export const OutlinedTextField = ({ label, placeholder, ...props }) => {
    const [field, meta] = useField(props)
    const errorText = meta.error && meta.touched ? meta.error : ''
    return (
        <TextField
            label={label}
            fullWidth
            {...field}
            placeholder={placeholder}
            helperText={errorText}
            error={!!errorText}
            // className={classes.textField}
            variant="outlined"
        // size="small"
        />
    )
}


export const CustomRowCheckbox = ({ option, label, ...props }) => {
    const [field] = useField(props)
    return (
        <div>
            {/* <FormLabel style={{ textAlign: "left", paddingBottom: '8px' }} component="legend" color="primary" focused>{label}</FormLabel> */}
            {option.map((item, i) => (
                <FormGroup key={i}>
                    <Field
                        component={CheckboxWithLabel}
                        key={i}
                        {...field}
                        type="checkbox"
                        value={item.title}
                        Label={{ label: item.title }}
                    />
                </FormGroup>
            ))}
        </div>
    )
}

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}


export const CountryList = ({ OptionList, ...props }) => {
    const [field] = useField(props)
    const { setFieldValue } = useFormikContext();
    const classes = useStyles();
    return (
        <Autocomplete
            id="country-select-demo"
            style={{ margin: '5px auto 10px auto' }}
            options={OptionList}
            {...field}
            classes={{
                option: classes.option,
            }}
            onChange={(val, data) => {
                setFieldValue(field.name, data);
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
                <React.Fragment>
                    <span>{countryToFlag(option.code)}</span>
                    {option.label} ({option.code}) +{option.phone}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField

                    {...params}
                    label="Choose a country"
                    variant="outlined"

                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    )
}