import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Formik, } from 'formik'
import Typography from '@material-ui/core/Typography';
import { CustomCheckbox } from '../FormComponents/FormComponents'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { MinHeightTextarea } from '../FormComponents/FormComponents'
import FormLabel from '@material-ui/core/FormLabel';

//redux stuff
import { connect } from 'react-redux';
import { updateEvaluation } from '../../../redux/actions/dataActions';



const styles = (theme) => ({

    ...theme.SpreadThis,
    root: {

        flexGrow: 1,
        width: '75%',
        margin: '0 auto'
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        alignItem: "center",
        //paddingLeft: theme.spacing(15)
    },
    evalContent: {
        marginLeft: theme.spacing(8),
        padding: theme.spacing(4)
    },
    labelstyle: {
        padding: theme.spacing(1.5),
    },
    textfield: {
        padding: theme.spacing(4)
    },
    textContainer: {
        textAlign: 'left',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
})

const locationofInjury = [
    { title: "Cerbral Cortex" },
    { title: "Frontal Lobe" },
    { title: "III and IV Cranial Nerves" },
    { title: "Occipital Lobe" },
    { title: "Parietal Lobe Midbrain" },
    { title: "Premotor Nuclei in the Reticular Formation" },
    { title: "Pretectal Area" },
    { title: "Superior Colliculus" },
    { title: "Visual Cortex" },
]

const pons = [
    { title: "Longitudinal Fasiculus" },
    { title: "Nucleus Preposition Hypogloss" },
    { title: "Paramedian Pantine Reticular Formation (PMRF)" },
    { title: "VI Carnial Nerve Nuclei" },
]

const autonomicNervousSystem = [
    { title: "CN III and Sympathetic Palpibrae" },
    { title: "Eye Muscles" },
    { title: "Focusing the Lens" },
    { title: "Levator Superior" },
    { title: "Long Ciliary Muscles" },
    { title: "Short Ciliary Muscles" },
]


class EvaluationOne extends Component {




    // componentDidUpdate(prevProps) {
    //     if (prevProps.patient !== this.props.patient) {
    //         prevProps.patient = this.props.patient
    //     }
    // }
    render() {
        const { classes, patient, evaluationOneData } = this.props;
        let evalDetails = {
            key: "evaluationOne",

            ...patient.evaluationDetails
        }

        return (
            <Formik
                // initialValues={patient ? patient.evaluation ? patient.evaluation.evaluationOne ? patient.evaluation.evaluationOne : {} : {} : {
                initialValues={evaluationOneData && Object.keys(evaluationOneData).length > 0 ? evaluationOneData : {

                    // treatmentModalities: '',
                    locationofInjury: '',
                    pons: '',
                    autonomicNervousSystem: '',
                    presentMedicalHistory: "",
                    pastMedicalHistory: "",
                    familyPsychosocial: "",
                    workEducational: "",
                    previousStatus: ""

                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    const evaluationOneData = { evaluationOne: values }
                    setTimeout(() => {
                        this.props.updateEvaluation(evalDetails, evaluationOneData, (isSuccess) => { this.props.handleChange(2, isSuccess); setSubmitting(false) })

                        // resetForm()
                    }, 500)
                }}
            >
                {({ values, isSubmitting, submitForm }) => (
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container justify="flex-start" spacing={2}>
                                        <Grid item xs>
                                            <p style={{ color: "red", textAlign: 'left' }}>
                                                {this.props.patient ? this.props.patient.evaluationDetails ? this.props.patient.evaluationDetails.evaluationType ? this.props.patient.evaluationDetails.evaluationType.toUpperCase() === "MAINEVALUATION" ? (<p>MAIN EVAUATION</p>) : this.props.patient.evaluationDetails.evaluationType.toUpperCase() : "" : "" : ""}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <Typography color="primary" className={classes.textfield} variant="h4" >Evaluation Part One</Typography>
                                    <Divider />
                                    <Grid container className={classes.textContainer} spacing={2}>

                                        <Grid item xs={12}>
                                            <FormLabel focused>Present Medical History</FormLabel>
                                            <MinHeightTextarea
                                                name="presentMedicalHistory"
                                                placeholder="Past Medical History"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormLabel focused>Past Medical History</FormLabel>
                                            <MinHeightTextarea
                                                name="pastMedicalHistory"
                                                placeholder="Past Medical History"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormLabel focused>Family & Psychosocial History</FormLabel>
                                            <MinHeightTextarea
                                                name="familyPsychosocial"
                                                placeholder="Family & Psychosocial"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormLabel focused>Work & Educational History</FormLabel>
                                            <MinHeightTextarea
                                                name="workEducational"
                                                placeholder="Work and Educational background"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormLabel focused>Previous Functional Status and Abilities</FormLabel>
                                            <MinHeightTextarea
                                                name="previousStatus"
                                                placeholder="Previous Functional Status and Abilities"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-around"
                                        alignItems="stretch"
                                        spacing={3}
                                        className={classes.evalContent}
                                    >

                                        <Grid item xs={6}  >
                                            <CustomCheckbox option={autonomicNervousSystem} label="Autonomic Nervous System" name="autonomicNervousSystem" value={values.autonomicNervousSystem} />
                                            <br />
                                            <CustomCheckbox option={pons} label="Pons" name="pons" />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <CustomCheckbox option={locationofInjury} label="Location of Injury" name="locationofInjury" value={values.locationofInjury} />
                                        </Grid>

                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                variant="contained"
                                                onClick={submitForm}
                                            >
                                                save & continue
                                                    </Button>
                                        </Grid>
                                    </Grid>

                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                )}

            </Formik>
        )
    }
}

const mapStateToProps = (state) => ({
    patient: state.data.patient,
    evaluationOneData: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation.evaluationOne ? state.data.patient.evaluation.evaluationOne : {} : {} : {},
    UI: state.UI
})

const mapActionToProps = {
    updateEvaluation
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(EvaluationOne))























// const useStyles = makeStyles(theme => ({
//     ...theme.SpreadThis,
//     root: {

//         //marginTop: theme.spacing(11),
//         flexGrow: 1,
//         width: '75%',
//         margin: '0 auto'
//     },
//     paper: {
//         padding: theme.spacing(3),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         alignItem: "center"
//     },
//     labelstyle: {
//         padding: theme.spacing(1.5),
//     },
//     textfield: {
//         padding: theme.spacing(4)
//     },


// }))


// const EvaluationOne = (props) => {
//     const classes = useStyles()
//     return (
//         <Formik initialValues={{

//             treatmentModalities: [],
//             nueromuscularReeducation: [],
//             locationofInjury: [],
//             pons: [],
//             autonomicNervousSystem: []

//         }}
//             onSubmit={(values, { setSubmitting }) => {
//                 let evluationId = props.data.patient.appointmentId
//                 console.log(evluationId)
//                 setTimeout(() => {
//                     alert(JSON.stringify(values, null, 2))
//                     setSubmitting(false)
//                 }, 500)
//             }}
//         >
//             {({ values, isSubmitting, submitForm }) => (
//                 <div className={classes.root}>
//                     <Grid container spacing={3}>
//                         <Grid item xs={12}>
//                             <Paper className={classes.paper}>
//                                 <Typography color="primary" className={classes.textfield} variant="h4" >Evaluation Part One</Typography>
//                                 <Divider />
//                                 <Grid
//                                     container
//                                     direction="row"
//                                     justify="space-around"
//                                     alignItems="stretch"
//                                     spacing={3}
//                                     className={classes.textfield}
//                                 >
//                                     <Grid item xs={6}  >
//                                         <CustomCheckbox option={locationofInjury} label="Location of Injury" name="locationofInjury" />
//                                     </Grid>
//                                     <Grid item xs={6} >
//                                         <CustomCheckbox option={treatmentModalities} label="Treatment Modalities" name="treatmentModalities" />
//                                     </Grid>
//                                     <Grid item xs={6} >
//                                         <CustomCheckbox option={nueromuscularReeducation} label="Nueromuscular Reeducation" name="nueromuscularReeducation" />
//                                     </Grid>
//                                     <Grid item xs={6} >
//                                         <CustomCheckbox option={autonomicNervousSystem} label="Autonomic Nervous System" name="autonomicNervousSystem" />
//                                     </Grid>
//                                     <Grid item xs={6} >
//                                         <CustomCheckbox option={pons} label="Pons" name="pons" />
//                                     </Grid>
//                                     <Grid item xs={6}></Grid>
//                                     <Grid item xs={12}>
//                                         <Button color="primary" variant="contained" onClick={submitForm}>submit</Button>
//                                     </Grid>
//                                 </Grid>
//                                 <pre>
//                                     {JSON.stringify(values, null, 2)}
//                                 </pre>
//                             </Paper>
//                         </Grid>
//                     </Grid>
//                 </div>
//             )}

//         </Formik>
//     )
// }
