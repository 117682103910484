import React, { Component, Fragment } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import dayjs from 'dayjs';
//custom
import MyButton from '../../utils/MyButton'
// import EditProfile from '../UserProfile/EditProfile'
//icons
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

//Redux
import { connect } from 'react-redux';
import { uploadImage } from '../../redux/actions/userActions'

const styles = (theme) => ({
    ...theme.spreadThis,
    profile: {

        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    profdata: {
        paddingTop: theme.spacing(1)
    },
    large: {
        width: theme.spacing(25),
        height: theme.spacing(25),
    },
    '& span,svg': {
        verticalAlign: "middle"
    },
    hr: {
        border: 'none',
        margin: '0 0 10px 0'
    },
    imageWrapper: {
        textAlign: 'center',
        position: 'relative',
    },
    profileImage: {
        width: 200,
        height: 200,
        objectFit: 'cover',
        maxWidth: '100%',
        borderRadius: '50%'
    }


});
class Profile extends Component {
    handleImageChange = (event) => {
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);

        this.props.uploadImage(formData);
    };
    handleEditPicture = () => {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    };
    render() {
        const {
            classes,
            user: {
                credentials: { userHandle, email, imageUrl, state, createdAt, businessName, city, zipCode, phone, fax, address },
                loading,
                authenticated
            } } = this.props;
        let profileMarkUp = !loading ? (authenticated && (
            <Fragment>
                <Paper className={classes.profile} >

                    <Grid item xs={12} sm={12}>
                        <h1>{businessName}</h1>
                        <p>{address ? address : ""} {city ? city : ""}</p>
                        <p> {state ? (state) : ""} {zipCode ? zipCode : ""}</p>
                        {phone ? (<p>Phone: {phone}</p>) : ""}
                        {fax ? (<p> Fax: {fax}</p>) : ""}
                    </Grid>
                    <Grid container direction="column">

                        <Grid item xs={12} sm={12} className={classes.imageWrapper}>
                            <img src={imageUrl} alt="profile" className={classes.profileImage} />
                            <input
                                type="file"
                                id="imageInput"
                                hidden="hidden"
                                onChange={this.handleImageChange}
                            />
                            <MyButton
                                tip="Edit profile picture"
                                onClick={this.handleEditPicture}
                                btnClassName="button"
                            >
                                <EditIcon color="primary" />
                            </MyButton>

                        </Grid>
                        <Grid className={classes.profdata} item xs={12} sm={12} >

                            <Typography className={classes.profdata} gutterBottom variant="h5" component="h2">
                                {userHandle}
                            </Typography>
                            <hr className={classes.hr} />
                            <EmailIcon color="primary" />{' '}<span>{email}</span>
                            <hr className={classes.hr} />

                            {state &&
                                <Fragment>
                                    <LocationOnIcon color="primary" />{' '}<span>{state}</span>
                                    <hr className={classes.hr} />
                                </Fragment>
                            }

                            <CalendarTodayIcon color="primary" />{' '}
                            <span>Joined {dayjs(createdAt).format('MMM YYYY')}</span>
                            {/* <EditProfile /> */}
                        </Grid>
                    </Grid>
                </Paper>
            </Fragment>
        )
        ) : (<p>Loading</p>)
        return profileMarkUp
    }

}

const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(mapStateToProps, { uploadImage })(withStyles(styles)(Profile))
