import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Alert from '@material-ui/lab/Alert';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Appointment from '../Appointment/Appointment';
import LongTermGoals from '../Goals/LongTermGoals';
import ShortTermGoals from '../Goals/ShortTermGoals';
//import ObjectiveObservation from '../ObjectiveObservation/ObjectiveObservation';
import Observation from '../ObjectiveObservation/Obeservation';
import DischargeForm from '../DischargeForm/DischargeForm';
import EvaluationSecond from '../EvaluationSecond/EvaluationSecond';
import EvaluationOne from '../EvaluationFirst/EvaluationOne';
import Snackbar from '@material-ui/core/Snackbar';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        margin: '0 auto',
    },

    tab: {
        display: "flex",
        justifyContent: "center"
    }

}));

export default function EvaluationTab() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [toastValue, setToastValue] = React.useState('');

    const handleChange = (newValue, toastValue) => {
        setToastValue(toastValue ? "success" : "error");
        if (toastValue) setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color="default"  >
                <Snackbar open={toastValue ? true : false} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={3000} onClose={() => setToastValue("")}>
                    <Alert onClose={() => setToastValue("")} severity={toastValue}>
                        {toastValue === "success" ? "Saved Successfully" : "Error Occured while saving"}
                    </Alert>
                </Snackbar>     <Tabs
                    value={value}
                    onChange={(m, n) => setValue(n)}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable auto tabs example"
                    centered
                >

                    <Tab label="Appointment" {...a11yProps(0)} />
                    <Tab label="Evaluation One" {...a11yProps(1)} />
                    <Tab label="Evaluation Two" {...a11yProps(2)} />
                    {/* <Tab label="Objective Observations" {...a11yProps(3)} /> */}
                    <Tab label="Observations" {...a11yProps(3)} />
                    <Tab label="Short Term Goals" {...a11yProps(4)} />
                    <Tab label="Long Term Goals" {...a11yProps(5)} />
                    <Tab label="Discharge Form" {...a11yProps(6)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Appointment handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <EvaluationOne handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <EvaluationSecond handleChange={handleChange} />
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
                <ObjectiveObservation handleChange={handleChange} />
            </TabPanel> */}
            <TabPanel value={value} index={3}>
                <Observation handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ShortTermGoals handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <LongTermGoals handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <DischargeForm />
            </TabPanel>
        </div>
    );
}
