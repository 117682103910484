import { saveAs } from 'file-saver';
import {
    SET_ALL_PATIENTS,
    SET_CURRENT_PATIENT,
    LOADING_DATA,
    LOADING_UI,
    CLEAR_ERRORS,
    SET_ERRORS,
    SET_EVALUATION_DETAILS,
    SET_MESSAGE,
    SET_EVALUATIONS,
    SET_CURRENT_EVALUATION,
    UPDATE_CURRENT_EVALUATION,
    UPDATE_CURRENT_PROGRESSNOTE,
    CLEAR_CURRENT_PATIENT,
    // SET_PROGNOTE_ID,
    SET_CURRENT_PROGRESSNOTE,
    SET_CURRETNT_UPDATENOTE,
    UPDATE_CURRENT_UPDATENOTE,
    SET_CURRETNT_DISCHARGENOTE,
    UPDATE_CURRENT_DISCHARGENOTE,
    CLEAR_CURRENT_EVALUATIONS,
    CLEAR_SELECTED_EVALUATION,
    CLEAR_SELECTED_PROGRESSNOTE,
    CLEAR_SELECTED_UPDATENOTE,
    CLEAR_SELECTED_DISCHARGENOTE
} from '../types';
import axios from 'axios'

export const getAllPatients = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios.get('/api/patients')
        .then(res => {
            dispatch({
                type: SET_ALL_PATIENTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_ALL_PATIENTS,
                payload: []
            });
        })
}

//Register patient
export const registerPatient = (newPatientData, isSuccess) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('/api/patient', (newPatientData))
        .then(res => {
            dispatch({
                type: SET_CURRENT_PATIENT,
                payload: res.data
            })

            dispatch({ type: CLEAR_ERRORS })
            isSuccess(true)
            // history.push('/evaluation')
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
            isSuccess(false)
        })
}

//update patient info
export const updatePatientInfo = (patientData, patientAutoGenId, callbackNext) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post(`api/patientinfo/${patientAutoGenId}`, (patientData))
        .then(res => {
            dispatch({
                type: SET_CURRENT_PATIENT,
                payload: patientData
            })

            dispatch({ type: CLEAR_ERRORS })
            callbackNext(true);
        })
        .catch(err => {
            console.log(err)
            callbackNext(false);
        })
}

//add appointment
export const addAppointment = (evalDetails, appoinmentData, callbackNext) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    let evalType = evalDetails.evaluationType
    let patientAutoGenId = evalDetails.patientAutoGenId
    let appointment = { ...appoinmentData }
    if (evalType === "mainEvaluation") {
        appointment.appointment.currentEvaluationId = evalDetails ? evalDetails.evaluationId ? evalDetails.evaluationId : "" : ""
        axios.post(`/api/patient/${patientAutoGenId}`, (appointment))
            .then(res => {
                let evaluationID = res.data ? res.data.appointmentId ? res.data.appointmentId : '' : '';
                let evaluationDetils = {
                    evaluationId: evaluationID,
                    evaluationType: "mainEvaluation"
                }
                if (evaluationID !== "") {
                    dispatch({
                        type: SET_EVALUATION_DETAILS,
                        payload: evaluationDetils
                    })
                }
                let eval_update = { ...appoinmentData }
                let key = evalDetails.key
                dispatch({
                    type: UPDATE_CURRENT_EVALUATION,
                    payload: { key, eval_update }
                })
                dispatch({ type: CLEAR_ERRORS })
                callbackNext(true);
            })
            .catch(err => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
                callbackNext(false);
            })
    }
    else if (evalType === "Progress Note") {
        appointment.appointment.currentProgressNoteId = evalDetails ? evalDetails.progressNoteId ? evalDetails.progressNoteId : "" : ""
        axios.post(`/api/addprognote/${evalDetails.evaluationId}`, (appoinmentData))
            .then(res => {
                let progressNoteId = res.data ? res.data.progAppointmentId ? res.data.progAppointmentId : evalDetails.progressNoteId : "";
                let evaluationDetils = {

                    ...evalDetails,
                    progressNoteId: progressNoteId
                }
                if (progressNoteId !== "") {
                    dispatch({
                        type: SET_EVALUATION_DETAILS,
                        payload: evaluationDetils
                    })
                }
                let progUpdate = { ...appoinmentData }
                let progkey = evalDetails.key
                dispatch({
                    type: UPDATE_CURRENT_PROGRESSNOTE,
                    payload: { progkey, progUpdate }
                })
                dispatch({ type: CLEAR_ERRORS })
                callbackNext(true);
            })
            .catch(err => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
                callbackNext(false);
            })

    }
    else if (evalType === "Update Note") {
        appointment.appointment.currentUpdateNoteId = evalDetails ? evalDetails.updateNoteId ? evalDetails.updateNoteId : '' : "";
        axios.post(`/api/addUpdateNote/${evalDetails.evaluationId}`, (appoinmentData))
            .then(res => {
                let updateNoteId = res.data ? res.data.updateNoteAppointId ? res.data.updateNoteAppointId : evalDetails.updateNoteId : "";
                let evaluationDetils = {

                    ...evalDetails,
                    updateNoteId: updateNoteId
                }
                if (updateNoteId !== "") {
                    dispatch({
                        type: SET_EVALUATION_DETAILS,
                        payload: evaluationDetils
                    })
                }
                let updateNoteUpdate = { ...appoinmentData }
                let upkey = evalDetails.key
                dispatch({
                    type: UPDATE_CURRENT_UPDATENOTE,
                    payload: { upkey, updateNoteUpdate }
                })
                dispatch({ type: CLEAR_ERRORS })
                callbackNext(true);
            })
            .catch(err => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
                callbackNext(false);
            })
    }
    else if (evalType === "Discharge Note") {
        appointment.appointment.currentDischargeNoteId = evalDetails ? evalDetails.dischargeNoteId ? evalDetails.dischargeNoteId : '' : "";
        axios.post(`/api/addDischargeNote/${evalDetails.evaluationId}`, (appoinmentData))
            .then(res => {
                let dischargeNoteId = res.data ? res.data.dischargeNoteId ? res.data.dischargeNoteId : evalDetails.dischargeNoteId : "";
                let evaluationDetils = {

                    ...evalDetails,
                    dischargeNoteId: dischargeNoteId
                }
                if (dischargeNoteId !== "") {
                    dispatch({
                        type: SET_EVALUATION_DETAILS,
                        payload: evaluationDetils
                    })
                }
                let dischargeNoteUpdate = { ...appoinmentData }
                let diskey = evalDetails.key
                dispatch({
                    type: UPDATE_CURRENT_DISCHARGENOTE,
                    payload: { diskey, dischargeNoteUpdate }
                })
                dispatch({ type: CLEAR_ERRORS })
                callbackNext(true);
            })
            .catch(err => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
                callbackNext(false);
            })
    }

}

export const dischargePlanUpdate = (key, formData, dischargeData, evaluationId, callbackNext) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    console.log(formData.get("image"))
    let dischargePlan = { ...dischargeData }

    axios.post('/api/signature', (formData))
        .then((res) => {
            console.log(res.data)
            dischargePlan.therapistSign = res.data.imageUrl
            const eval_data = { dischargePlan }
            dispatch(updateEvaluation(key, eval_data, evaluationId, callbackNext))
        })
        .catch((err) => {
            console.log(err)
            callbackNext(false)
        })
}

//update evaluation
export const updateEvaluation = (evalDetails, eval_update, callbackNext) => (dispatch) => {

    let updateData = {};
    updateData = { ...eval_update }
    dispatch({ type: LOADING_UI })

    if (evalDetails.evaluationType === "mainEvaluation") {
        let key = evalDetails.key
        //updateData.appointment.currentEvaluationId = evalDetails.evaluationId
        axios.post(`/api/evaluation/${evalDetails.evaluationId}`, (updateData))
            .then(res => {
                dispatch({
                    type: UPDATE_CURRENT_EVALUATION,
                    payload: { key, eval_update }
                })
                dispatch({
                    type: SET_MESSAGE,
                    payload: res.data
                })
                dispatch({
                    type: CLEAR_ERRORS
                })
                callbackNext(true)
            })
            .catch(err => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
                callbackNext(false)
            })


    }
    else if (evalDetails.evaluationType === "Progress Note") {
        updateData.currentProgressNoteId = evalDetails.progressNoteId
        let progkey = evalDetails.key
        let progUpdate = updateData
        axios.post(`/api/updateprognote/${evalDetails.progressNoteId}`, (updateData))
            .then(res => {
                dispatch({
                    type: UPDATE_CURRENT_PROGRESSNOTE,
                    payload: { progkey, progUpdate }
                })
                dispatch({
                    type: SET_MESSAGE,
                    payload: res.data
                })
                dispatch({
                    type: CLEAR_ERRORS
                })
                callbackNext(true)
            })
            .catch(err => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
                callbackNext(false)
            })
    }
    else if (evalDetails.evaluationType === "Update Note") {
        updateData.currentUpdateNoteId = evalDetails.updateNoteId
        let upkey = evalDetails.key
        let updateNoteUpdate = updateData
        axios.post(`/api/updateUpdateNote/${evalDetails.updateNoteId}`, (updateData))
            .then(res => {
                dispatch({
                    type: UPDATE_CURRENT_UPDATENOTE,
                    payload: { upkey, updateNoteUpdate }
                })
                dispatch({
                    type: SET_MESSAGE,
                    payload: res.data
                })
                dispatch({
                    type: CLEAR_ERRORS
                })
                callbackNext(true)
            })
            .catch(err => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
                callbackNext(false)
            })
    }
    else if (evalDetails.evaluationType === "Discharge Note") {
        updateData.currentDischargeNoteId = evalDetails.dischargeNoteId
        let diskey = evalDetails.key;
        let dischargeNoteUpdate = updateData;
        axios.post(`/api/updateDischargeNote/${evalDetails.dischargeNoteId}`, (updateData))
            .then(res => {

                dispatch({
                    type: UPDATE_CURRENT_DISCHARGENOTE,
                    payload: { diskey, dischargeNoteUpdate }
                })
                dispatch({
                    type: SET_MESSAGE,
                    payload: res.data
                })
                dispatch({
                    type: CLEAR_ERRORS
                })
                callbackNext(true)
            })
            .catch(err => {
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
                callbackNext(false)
            })
    }


}

// set the current patient
export const setCurrentPatient = (patientId) => (dispatch) => {
    let cancel = ''
    const CancelToken = axios.CancelToken;
    dispatch({ type: LOADING_UI })
    dispatch({ type: CLEAR_CURRENT_PATIENT })
    if (cancel) {
        cancel.cancel()
    }
    cancel = CancelToken.source();
    axios.post('/api/patientinfo', { patientId }, {
        cancelToken: cancel.token
    })
        .then(res => {
            dispatch({
                type: SET_CURRENT_PATIENT,
                payload: res.data
            })

        })
        .catch(err => {
            console.log(err)
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

//Get all evaluations of a patient
export const getEvaluations = (patientAutoGenId, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    dispatch({
        type: CLEAR_CURRENT_EVALUATIONS
    })
    axios.get(`/api/evaluations/${patientAutoGenId}`)
        .then(res => {
            dispatch({
                type: SET_EVALUATIONS,
                payload: res.data
            })
            dispatch({ type: CLEAR_ERRORS })
            // history.push('/patient')
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

//set current evaluation * editing history
export const setCurrentEvaluation = (evalType, selectedEvaluation) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    let evaluationDetails = {
        evaluationId: selectedEvaluation.evaluationId,
        evaluationType: evalType
    }
    if (evalType === "mainEvaluation") {
        dispatch({
            type: CLEAR_CURRENT_EVALUATIONS
        })

        dispatch({
            type: SET_EVALUATION_DETAILS,
            payload: evaluationDetails
        })
        dispatch({
            type: SET_CURRENT_EVALUATION,
            payload: selectedEvaluation
        })
        dispatch({ type: CLEAR_ERRORS })
        // console.log(selectedEvaluation)

    }
    else if (evalType === "Progress Note") {
        evaluationDetails.progressNoteId = selectedEvaluation.progressNoteId
        dispatch({
            type: SET_EVALUATION_DETAILS,
            payload: evaluationDetails
        })

        dispatch({
            type: SET_CURRENT_PROGRESSNOTE,
            payload: selectedEvaluation
        })
        dispatch({ type: CLEAR_ERRORS })
        console.log(selectedEvaluation);
    }
    else if (evalType === "Update Note") {
        evaluationDetails.updateNoteId = selectedEvaluation.updateNoteId
        dispatch({
            type: SET_EVALUATION_DETAILS,
            payload: evaluationDetails
        })

        dispatch({
            type: SET_CURRETNT_UPDATENOTE,
            payload: selectedEvaluation
        })
        dispatch({ type: CLEAR_ERRORS })
        console.log(selectedEvaluation);
    }
    else if (evalType === "Discharge Note") {
        evaluationDetails.dischargeNoteId = selectedEvaluation.dischargeNoteId
        dispatch({
            type: SET_EVALUATION_DETAILS,
            payload: evaluationDetails
        })

        dispatch({
            type: SET_CURRETNT_DISCHARGENOTE,
            payload: selectedEvaluation
        })
        dispatch({ type: CLEAR_ERRORS })
        console.log(selectedEvaluation);
    }

}

export const viewReport = (selectedEvaluation, patientData, userData) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    let reportData = {}
    reportData.selectedEvaluation = { ...selectedEvaluation }
    reportData.patientData = { ...patientData }
    reportData.userData = { ...userData }
    axios.post('/api/report', (reportData), { responseType: 'blob' })
        //.then(() => axios.get('/api/fetch-report', { responseType: 'blob' }))
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

            saveAs(pdfBlob, 'newPdf.pdf');
            dispatch({ type: CLEAR_ERRORS })
        })
        .catch(err => {
            console.log(err)
        })
}

export const setEvaluationType = (selectedEval) => (dispatch) => {

    dispatch({ type: LOADING_UI })
    dispatch({
        type: SET_EVALUATION_DETAILS,
        payload: selectedEval
    })
    if (selectedEval.evaluationType === "mainEvaluation") {
        dispatch({ type: CLEAR_SELECTED_EVALUATION })
    }
    else if (selectedEval.evaluationType === "Progress Note") {
        dispatch({ type: CLEAR_SELECTED_PROGRESSNOTE })
    }
    else if (selectedEval.evaluationType === "Update Note") {
        dispatch({ type: CLEAR_SELECTED_UPDATENOTE })
    }
    else if (selectedEval.evaluationType === "Discharge Note") {
        dispatch({ type: CLEAR_SELECTED_DISCHARGENOTE })
    }

}



export const soapNoteReport = (selectedEvaluation, patientData, userData) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    let reportData = {}
    reportData.selectedEvaluation = { ...selectedEvaluation }
    // reportData.evalDetails = { ...evalDetails }
    reportData.patientData = { ...patientData }
    reportData.userData = { ...userData }

    axios.post('/api/soapnote', (reportData), { responseType: 'blob' })
        //.then(() => axios.get('/api/fetch-report', { responseType: 'blob' }))
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

            saveAs(pdfBlob, 'newPdf.pdf');
            dispatch({ type: CLEAR_ERRORS })
        })
        .catch(err => {
            console.log(err)
        })
}
