import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { purple } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import dayjs from 'dayjs'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import MyButton from '../../utils/MyButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
// import CircularProgress from '@material-ui/core/CircularProgress';

//redux stuff
import { connect } from 'react-redux';
import { setCurrentEvaluation, viewReport, setEvaluationType } from '../../redux/actions/dataActions'

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };



    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const style = (theme) => ({
    table: {
        minWidth: 500,
    },
    tablemain: {
        padding: theme.spacing(4),
        overflow: "visible",
        boxSizing: "border-box"
    },
    paperHead: {
        position: "relative",
        paddingTop: theme.spacing(.5),
        textAlign: 'center',
        margin: 'auto'
    },
    paper: {
        position: "absolute",
        top: '-75px',
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        textAlign: 'center',
        margin: 'auto',
        color: "white",
        backgroundColor: purple[400],
    },
    evaDialog: {
        width: '800px',
        height: "700px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    closeButton: {
        position: 'absolute',
        left: '91%',
        top: '3%'
    },

})


const menus = {
    "appointment": {
        "Date of Appointment": "prescriptionDate",
        "Initial Evaluation": "initialEvaluation",
        "Primary Diagnosis": "primaryDiagnosis",
        "Physician's Name": "physiciansName",
        "SOC-Date": "socDate",
        "onSet-Date": "onSetDate",
        "Treatment Diagnosis": "treatmentDiagnosis",
        "Insurance": "insurance",
        "Other Insurance": "otherInsurance",
        "Service": "service"
    },
    "evaluationOne": {
        "Present Medical History": 'presentMedicalHistory',
        "Past Medical History": 'pastMedicalHistory',
        "Family & Psychosocial History": 'familyPsychosocial',
        "Work & Educational History": 'workEducational',
        "Previous Status": 'previousStatus',
        "Location Of Injury": "locationofInjury",
        "Autonomic Nervous System": "autonomicNervousSystem",
        "Pons": "pons"
    },
    "evaluationSecond": {
        "Lower Motor Neuron Eye Defectsat Pheripheral Level Nerve": "lowerMotorNeuronEyeDefectsatPheripheralLevelNerve",
        "Postural Visual Symptoms": "posturalVisualSymptoms",
        "CVA-Head Injury": "cvaHeadInjury",
        "Subarachnoid Space": "subarachnoidSpace",
        "Eye Muscle General Multi-Defects": "eyeMuscleGeneralMultiDefects",
        "External Opthalmoplegia": "externalOpthalmoplegia",
        "Mid Brain Level Pregaglionic Area": "midbrainLevelPregaglionicArea"
    },
    "objectiveObservation": {
        "Direct Light Response": "directLightResponse",
        "Consenual Response": "consenualResponse",
        "Pupil Size and Shape": "pupilSizeandShape",
        "Laws of Eye Movements": "lawsofEyeMovements",
        "Eye Dominance": "eyeDominance",
        "Eye Opening": "eyeOpening",
        "Edema": 'edema',
        "Visual Acuity Development": "visualAcuityDevelopment",
        "Mood": 'mood',
        //  "Pathological Reflexes": "pathologicalReflexes",

        'PathologicalReflexes': 'pathologicalReflexes',
        'Pupillary Dark Reflexes': 'pupillaryDarkReflexes',
        'Other Pupil Reflexes': 'otherPupilReflexes',
        'Ciliospinal Reflexs': 'ciliospinalReflexs',
        'Near Accommodative Triad': 'nearAccommodativeTriad',
        'Corneal Reflex': 'cornealReflex',
        'Vestibulo Ocular Reflex': 'vestibuloOcularReflex',
        'Palpebral Oculogyric Reflex': 'palpebralOculogyricReflex',
        'Lacrimatory Reflex': 'lacrimatoryReflex',
        'Optokinetic Reflex': 'optokineticReflex',
        'Oculocardiac Reflex': 'oculocardiacReflex',
        'Oculo Respiratory Reflex': 'oculoRespiratoryReflex',
        'Oculo Ernetic Reflex': 'oculoErneticReflex',
        "Visual Acuity": "visualAcuity",
        "ROM of Eye Muscles": '',
        "Horizontal": '',
        "Left": 'romHorizontalRight',
        "Right": 'romHorizontalLeft',
        'Vertical': '',
        'up': 'romVerticalUp',
        'down': 'romVerticalDown',
        'Orientation': 'orientation',
        "Pupillary Distance": 'pupillaryDistance',
        "Pain": 'pain',
        // "ROM of Eye Muscles": "romofEyeMuscles",
        "Eye Muscle Strength": "eyeMuscleStrength",
    },
    "shortTermGoals": {
        "Short Term Goals": "shortTermGoals",


        // "Past Medical History": "pastMedicalHistory",
        // "Initial Assesment": "initialAssesment",
        // "Chief Complaint": "chiefComplaint",
        // "Affected Nerve": "affectedNerve",
        // "Abnormal Action of Nerve": "abnormalActionofNerve"
    },
    "longTermGoals": {
        "Long Term Goals": "longTermGoals",
        "Additional Goals": 'additionalGoals',
        "RecomendedTreatment": 'recomendedTreatment',
        "Rehab Potential": 'rehabPotential',
        "TreatmentModalities": 'treatmentModalities',
        'otherModalities1': 'otherModalities1',
        'otherModalities2': 'otherModalities2',
        'otherModalities3': 'otherModalities3',
        'Frequency Treatment': '',
        'WK': 'frequencyTreatmentWK',
        "WKS": 'frequencyTreatmentWKS',
        'Total Visits': 'frequencyTreatmenTotalVisit',
        "Agrement": 'agrement'
    },
    "dischargePlan": {
        // "Rehab Potential": "rehabPotential",
        "Discharge Plan": "dischargePlan",
        "Physician Name": "physicianName",
        "Therapist Name": 'therapistName',
        "Certification": "certification",
        "Date": 'date'
    }
}


class PatientHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 3,
            page: 0,
            open: false,
            openMenu: false,
            evalValue: 'evaluationOne'
        }
        this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage.bind(this)
        this.handleClickOpen.bind(this)
        this.handleClose.bind(this)
        this.handleChange.bind(this)
        this.handleMenuClose.bind(this)
        this.singleRow.bind(this)
    }

    handleClickOpen = (eval_data) => {
        this.setState({
            open: true
        })
        this.props.setCurrentEvaluation("mainEvaluation", eval_data)
    };
    handleClose = () => {
        this.setState({
            open: false,
        })
    };
    handleMenuClickOpen = () => {
        this.setState({
            openMenu: true
        })
    };
    handleMenuClose = () => {
        this.setState({
            openMenu: false
        })
    };
    handleChange = (event) => {
        this.setState({ evalValue: event.target.value });
    };
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 })
    };
    singleRow = (labelName, labelValue) => {
        if (labelValue != null) {
            return <p><b>{labelName}</b>:{labelValue}</p>
        }
        return;
    }
    listOutDiagnosis = (diagnosis) => {
        if (diagnosis && diagnosis.title !== "others") {
            return <p>{diagnosis.title}</p>
        }
    }
    findRowVal = (menuValue, evalValue) => {
        let finalOutput = "";
        if (Array.isArray(menuValue) && evalValue) {

            let output = menuValue.reduce((k, t) => k[t], evalValue);
            finalOutput = output ? output.toString() : "";
        } else {
            let output = evalValue[menuValue];
            if (output && output.constructor === ({}).constructor) {
                finalOutput = JSON.stringify(output, null, 2);
            } else {
                finalOutput = output ? output.toString() : "";
            }

        }
        return finalOutput;
    }

    render() {


        const { page, rowsPerPage } = this.state;
        const { classes, current_eval, patient } = this.props;
        let menuData = menus[this.state.evalValue];
        let evalData = current_eval[this.state.evalValue];

        // console.log(this.props.evaluations.evaluation)
        let evalDetails = {
            ...patient ? patient.evaluationDetails ? patient.evaluationDetails : "" : "",
            evaluationType: "mainEvaluation"
        }

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.evaluation.length - page * rowsPerPage);
        return (
            <TableContainer className={classes.tablemain} component={Paper}>
                <Grid item xs={12} sm={11} className={classes.paperHead}>
                    <Paper className={classes.paper}>
                        <h3>Patient History</h3>
                    </Paper>
                </Grid>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            {/* <TableCell></TableCell> */}
                            <TableCell align="right" onClick={() => { this.props.setEvaluationType(evalDetails); this.props.history.push('/evaluation') }}>
                                <Button variant="contained" color="primary">
                                    Add
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Date of Visit</TableCell>
                            <TableCell align="right">Primary Diagnosis</TableCell>
                            <TableCell align="right">Service</TableCell>
                            <TableCell align="right">Insurance</TableCell>

                            {/* <TableCell align="right">Report</TableCell> */}
                            <TableCell align="right">Details</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(rowsPerPage > 0
                            ? this.props.evaluation.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : this.props.evaluation
                        ).map((evl_data, i) => (
                            <Fragment key={i} >
                                <TableRow key={i}>
                                    <TableCell component="th" scope="row" onClick={() => { this.handleClickOpen(evl_data) }}>
                                        <Link component="button" variant="body2"> {dayjs(evl_data.appointment.createdAt).format("dddd, MMMM D YYYY")}</Link>
                                    </TableCell>
                                    {/* <TableCell align="right">{evl_data.appointment.primaryDiagnosis ? evl_data.appointment.primaryDiagnosis.forEach(diagnosis => { console.log(diagnosis.title) }) : ''}</TableCell> */}
                                    <TableCell align="right">{evl_data.appointment.primaryDiagnosis ? evl_data.appointment.primaryDiagnosis.map(diagnosis => this.listOutDiagnosis(diagnosis)) : ''}{evl_data.appointment.otherPrimaryDiagnosis ? evl_data.appointment.otherPrimaryDiagnosis : ""}</TableCell>
                                    <TableCell align="right">{evl_data.appointment.service ? evl_data.appointment.service.toString() : ''}</TableCell>
                                    <TableCell align="right">{evl_data.appointment.insurance ? evl_data.appointment.insurance.title !== "others" ? evl_data.appointment.insurance.title : evl_data.appointment.otherInsurance : ''}</TableCell>
                                    {/* <TableCell align="right" onClick={() => { this.props.setCurrentEvaluation("mainEvaluation", evl_data); this.props.history.push('/evaluation') }}> <EditIcon color="primary" /></TableCell> */}
                                    {/* <TableCell align="right" onClick={() => { this.props.setCurrentEvaluation("mainEvaluation", evl_data); this.props.viewReport(evl_data, this.props.patient) }}>
                                        <Button variant="contained" color="primary">
                                            Report
                                            {loading && <CircularProgress size={30} className={classes.progress} />}
                                        </Button>
                                    </TableCell> */}
                                    <TableCell align="right" onClick={() => { this.props.setCurrentEvaluation("mainEvaluation", evl_data); this.props.history.push('/evaluationMain') }}> <EditIcon color="primary" /></TableCell>

                                </TableRow>
                                <Dialog

                                    open={this.state.open}
                                    onClose={this.handleClose}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth="md"
                                >
                                    <MyButton
                                        onClick={this.handleClose}
                                        tip="close window"
                                        tipClassName={classes.closeButton}
                                    >
                                        <CloseIcon />
                                    </MyButton>
                                    <DialogTitle className={classes.styledHeader} >
                                        Patient Registration
                                     </DialogTitle>
                                    <DialogContent className={classes.evaDialog}>
                                        <Grid container justify="center">
                                            <Grid item xs={12}>
                                                <FormControl className={classes.formControl} variant="outlined">
                                                    {/* <InputLabel htmlFor="outlined-age-native-simple">Evaluation</InputLabel> */}
                                                    <Select
                                                        //labelId="demo-controlled-open-select-label"
                                                        //id="outlined-age-native-simple"
                                                        inputProps={{
                                                            name: 'age',
                                                            id: 'outlined-age-native-simple',
                                                        }}
                                                        open={this.state.openMenu}
                                                        onClose={this.handleMenuClose}
                                                        onOpen={this.handleMenuClickOpen}
                                                        value={this.state.evalValue}
                                                        onChange={this.handleChange}
                                                    >
                                                        <MenuItem value={'appointment'}>Appointment</MenuItem>
                                                        <MenuItem value={'evaluationOne'}>Evaluation One</MenuItem>
                                                        <MenuItem value={'evaluationSecond'}>Evaluation Two</MenuItem>
                                                        <MenuItem value={'objectiveObservation'}>Objective Observation</MenuItem>
                                                        <MenuItem value={'shortTermGoals'}>Short Term Goals</MenuItem>
                                                        <MenuItem value={'longTermGoals'}>Long Term Goals</MenuItem>
                                                        <MenuItem value={'dischargePlan'}>Discharge</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {menuData && evalData ? Object.keys(menuData).map(label => this.singleRow(label, this.findRowVal(menuData[label], evalData))) : null}
                                            </Grid>
                                        </Grid>


                                    </DialogContent>

                                </Dialog>
                            </Fragment>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[3, 5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={5}
                                count={this.props.evaluation.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }
}
const mapStateToProps = (state) => ({
    UI: state.UI,
    patient: state.data.patient,
    current_eval: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation : {} : {},
    evaluation: state.data.evaluations ? state.data.evaluations.evaluation ? state.data.evaluations.evaluation : [] : []
})

const mapActionsToProps = {
    setCurrentEvaluation,
    viewReport,
    setEvaluationType
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(style)(PatientHistoryTable))
