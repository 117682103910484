import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Divider, } from '@material-ui/core';
import { deepOrange, green, cyan, orange } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
// import ProfChart from './ProfChart';
import ProfTable from './ProfTable';
import Profile from '../UserProfile/Profile';
import LinearProgress from '@material-ui/core/LinearProgress';
//redux stuff
import { connect } from 'react-redux';


const useStyles = makeStyles(theme => ({
    root: {
        // flexGrow: 1,
        // width: '100%',
        // margin: 'auto',
        // padding: '0 60px'
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
    },
    subroot: {
        flexGrow: 1,
        width: '90%',
        margin: 'auto',
    },
    cardroot: {
        marginTop: theme.spacing(3),
        position: 'relative',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    square: {
        position: "absolute",
        top: '-10px',
        width: '80px',
        height: '80px',
        color: theme.palette.getContrastText(deepOrange[500]),
    },

    cardhead: {
        position: "relative"
    },
    card: {
        paddingTop: theme.spacing(2)
    },
    icon: {
        fontSize: '36px',
        lineHeight: '56px',
        width: '56px',
        height: '56px',
        textAlign: 'center'
    },
    button: {
        marginTop: theme.spacing(3)
    },
    chart: {
        marginTop: "40px",
        padding: theme.spacing(6),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        marginTop: theme.spacing(6),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    }
}));


function ProffesionalDashboard(props) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {props.loading ? <LinearProgress /> : ""}
            <div className={classes.subroot}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                        <Profile />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Grid container direction="column">
                            <Grid item >
                                <Grid container spacing={2} >
                                    <Grid item xs className={classes.cardroot}>
                                        <Card className={classes.card} >
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        variant="rounded"
                                                        className={classes.square}
                                                        style={{ background: orange[500] }}
                                                    >
                                                        <AccountBalanceIcon className={classes.icon} />
                                                    </Avatar>
                                                }
                                            />
                                            <CardContent>
                                                <p>My Plan</p>
                                                <Divider />
                                                <h3>499 monthly</h3>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs className={classes.cardroot}>
                                        <Card className={classes.card} >
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        variant="rounded"
                                                        className={classes.square}
                                                        style={{ backgroundColor: deepOrange[500], }}>
                                                        <ErrorOutlineIcon className={classes.icon} />
                                                    </Avatar>
                                                }
                                            />
                                            <CardContent>
                                                <p>User Guide</p>
                                                <Divider />
                                                <Button variant="outlined" color="primary" className={classes.button}>
                                                    <Link to="/userguide">View</Link>
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs className={classes.cardroot}>
                                        <Card className={classes.card}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        variant="rounded"
                                                        className={classes.square}
                                                        style={{ backgroundColor: green[500], }}
                                                    >
                                                        <AssignmentIcon className={classes.icon} />
                                                    </Avatar>
                                                }
                                            />
                                            <CardContent>
                                                <p>Suggest A Friend</p>
                                                <Divider />
                                                <Button variant="outlined" color="primary" className={classes.button}>
                                                    Suggest Now
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs className={classes.cardroot}>
                                        <Card className={classes.card}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        variant="rounded"
                                                        className={classes.square}
                                                        style={{ backgroundColor: cyan[500] }}
                                                    >
                                                        <AddBoxIcon className={classes.icon} />
                                                    </Avatar>
                                                }
                                            />
                                            <CardContent>
                                                <p>Contact Us</p>
                                                <Divider />
                                                <Button variant="outlined" color="primary" className={classes.button}>
                                                    <Link to="/contact">Contact</Link>
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={classes.table}>
                                <ProfTable history={props.history} noRows={3} />

                            </Grid>

                        </Grid>

                    </Grid>
                    {/* <Grid item xs={12} sm={5}>
                        <Paper className={classes.chart}>
                            <ProfChart />
                        </Paper>
                    </Grid> */}

                </Grid>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    loading: state.UI.loading
})

export default connect(mapStateToProps, {})(ProffesionalDashboard)
