import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { useField } from 'formik';

const useStyles = makeStyles(theme => ({
    ...theme.SpreadThis,
    root: {
        flexgrow: 1,
        width: '75%',
        margin: '0 auto'
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'left',
        alignItem: "center"
    },
    textfield: {
        padding: theme.spacing(4),
        textAlign: "center"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    itemspaceRight: {
        marginRight: theme.spacing(2)
    },
    itemspaceLeft: {
        marginRight: theme.spacing(3)
    },
    formlabel: {
        paddingBottom: theme.spacing(3),

    },
    button: {
        margin: theme.spacing(3)
    },
    main: {
        padding: theme.spacing(4)
    }

}))



export const SelectOption = ({ label, ...props }) => {
    const [field] = useField(props)
    const classes = useStyles()
    return (
        <FormControl size="small" fullWidth variant="outlined" className={classes.formControl}>
            <Select
                value={field.value ? field.value : ""}
                {...field}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value="Minimal">Minimal</MenuItem>
                <MenuItem value="Moderate">Moderate</MenuItem>
                <MenuItem value="Severe">Severe</MenuItem>
            </Select>
        </FormControl>
    )

}

export const SelectGait = ({ label, ...props }) => {
    const [field] = useField(props)
    const classes = useStyles()
    return (
        <FormControl size="small" fullWidth variant="outlined" className={classes.formControl}>
            <Select
                value={field.value ? field.value : ""}
                {...field}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value="Ataxic gait (cerebellar)">Ataxic gait (cerebellar)</MenuItem>
                <MenuItem value="Cautious gait/senile gait">Cautious gait/senile gait</MenuItem>
                <MenuItem value="Choreiform gait (Hyperkinetic gait)">Choreiform gait (Hyperkinetic gait)</MenuItem>
                <MenuItem value="Crouch gait">Crouch gait</MenuItem>
                <MenuItem value="Hemiplegic gait/Diplegic gait">Hemiplegic gait/Diplegic gait</MenuItem>
                <MenuItem value="Jump gait">Jump gait</MenuItem>
                <MenuItem value="Myopathic gait/Trendelenburg gait">Myopathic gait/Trendelenburg gait</MenuItem>
                <MenuItem value="Neuropathic gait/(steppage gait,Equine gait)">Neuropathic gait/(steppage gait,Equine gait)</MenuItem>
                <MenuItem value="Parkinsonian gait/Shuffling gait">Parkinsonian gait/Shuffling gait</MenuItem>
                <MenuItem value="Propulsive gait">Propulsive gait</MenuItem>
                <MenuItem value="Scissor gait">Scissor gait</MenuItem>
                <MenuItem value="Sensory gait">Sensory gait</MenuItem>
                <MenuItem value="Stiff knee gait">Stiff knee gait</MenuItem>
                <MenuItem value="Unsteady gait/Disability confident gait">Unsteady gait/Disability confident gait</MenuItem>
                <MenuItem value="Waddling gait">Waddling gait</MenuItem>
            </Select>
        </FormControl>
    )

}

export const StrengthSelect = ({ label, ...props }) => {
    const [field] = useField(props)
    const classes = useStyles()
    return (
        <FormControl size="small" fullWidth variant="outlined" className={classes.formControl}>
            <Select
                value={field.value ? field.value : ""}
                {...field}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value="5/5">5/5</MenuItem>
                <MenuItem value="4/5">4/5</MenuItem>
                <MenuItem value="4-/5">4-/5</MenuItem>
                <MenuItem value="3+/5">3+/5</MenuItem>
                <MenuItem value="3/5">3/5</MenuItem>
                <MenuItem value="3-/5">3-/5</MenuItem>
                <MenuItem value="2+/5">2+/5</MenuItem>
                <MenuItem value="2/5">2/5</MenuItem>
                <MenuItem value="1/5">1/5</MenuItem>
                <MenuItem value="0/5">0/5</MenuItem>
            </Select>
        </FormControl>
    )

}


export const LowerMotorNeuronEyeDefectsatPheripheralLevelNerve = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <div>
            <FormLabel focused>Lower Motor Neuron Eye Defects at Pheripheral Nerve Level </FormLabel>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>
                <Grid item xs={5}>
                    <FormLabel>Accidental Injury</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve.accidentalInjury"
                        value={values.accidentalInjury}
                        label="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Fracture of Orbit</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve.fractureofOrbit"
                        value={values.fractureofOrbit}
                        label="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Injury To III Cranial Nerve Ending</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve.injurytoIIICranialNerveEnding"
                        value={values.injurytoIIICranialNerveEnding}
                        label="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Post Ganglionic Area Pressure</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve.postGanglionicAreaPressure"
                        value={values.postGanglionicAreaPressure}
                        label="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Tear of Muscles Around Eyes</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve.tearofMusclesAroundEyes"
                        value={values.tearofMusclesAroundEyes}
                        label="lowerMotorNeuronEyeDefectsatPheripheralLevelNerve"
                    />
                </Grid>
            </Grid>
        </div>
    );
}
export const PosturalVisualSymptoms = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <div>
            <FormLabel focused>Postural Visual Symptoms</FormLabel>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>
                <Grid item xs={5}>
                    <FormLabel>Abnormal Gait</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectGait
                        type="select"
                        name="posturalVisualSymptoms.abnormalGait"
                        value={values.abnormalGait}
                        label="posturalVisualSymptoms"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel> Abnormal Postural Tone</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="posturalVisualSymptoms.abnormalPosturalTone"
                        value={values.abnormalPosturalTone}
                        label="posturalVisualSymptoms"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Hallucinations</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="posturalVisualSymptoms.hallucinations"
                        value={values.hallucinations}
                        label="posturalVisualSymptoms"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Migraine Headache</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="posturalVisualSymptoms.migraineHeadache"
                        value={values.migraineHeadache}
                        label="posturalVisualSymptoms"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Scinteillating Scotoma</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="posturalVisualSymptoms.scinteillatingScotoma"
                        value={values.scinteillatingScotoma}
                        label="posturalVisualSymptoms"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Visual Illusions</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="posturalVisualSymptoms.visualIllusions"
                        value={values.visualIllusions}
                        label="posturalVisualSymptoms"
                    />
                </Grid>
            </Grid>
        </div>
    );
}


////////////////CvaHeadInjury

export const CvaHeadInjury = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <React.Fragment>
            <FormLabel focused>CVA-Head Injury</FormLabel>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>
                <Grid item xs={5}>
                    <FormLabel>Abscess</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="cvaHeadInjury.abscess"
                        value={values.abscess}
                        label="cvaHeadInjury"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Aneurysm</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="cvaHeadInjury.aneurysm"
                        value={values.aneurysm}
                        label="cvaHeadInjury"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Blood Vessel Blockage</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="cvaHeadInjury.bloodVesselBlockage"
                        value={values.bloodVesselBlockage}
                        label="cvaHeadInjury"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel> Brain Cancer</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="cvaHeadInjury.brainCancer"
                        value={values.brainCancer}
                        label="cvaHeadInjury"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Hemorrhage</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="cvaHeadInjury.hemorrhage"
                        value={values.hemorrhage}
                        label="cvaHeadInjury"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Necrosis</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="cvaHeadInjury.necrosis"
                        value={values.necrosis}
                        label="cvaHeadInjury"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Sclerosis</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="cvaHeadInjury.sclerosis"
                        value={values.sclerosis}
                        label="cvaHeadInjury"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Tumor</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="cvaHeadInjury.tumor"
                        value={values.tumor}
                        label="cvaHeadInjury"
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

/////////ExternalOpthalmoplegia

export const ExternalOpthalmoplegia = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <React.Fragment>
            <FormLabel focused>External Opthalmoplegia</FormLabel>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>
                <Grid item xs={5}>
                    <FormLabel>Ciliary Muscle Numbness</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="externalOpthalmoplegia.ciliaryMuscleNumbness"
                        value={values.ciliaryMuscleNumbness}
                        label="externalOpthalmoplegia"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Ciliary Muscle Paralysis</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="externalOpthalmoplegia.ciliaryMuscleParalysis"
                        value={values.ciliaryMuscleParalysis}
                        label="externalOpthalmoplegia"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Dilated Fixed Pupil</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="externalOpthalmoplegia.dilatedFixedPupil"
                        value={values.dilatedFixedPupil}
                        label="externalOpthalmoplegia"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Irritability Blurry Vision</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="externalOpthalmoplegia.irritabilityBlurryVision"
                        value={values.irritabilityBlurryVision}
                        label="externalOpthalmoplegia"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Suspense Ligament Alteration</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="externalOpthalmoplegia.suspenseLigamentAlteration"
                        value={values.suspenseLigamentAlteration}
                        label="externalOpthalmoplegia"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Uncomfortable Feeling</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="externalOpthalmoplegia.uncomfortableFeeling"
                        value={values.uncomfortableFeeling}
                        label="externalOpthalmoplegia"
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

///////subarachnoidSpace 
export const SubarachnoidSpace = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <React.Fragment>
            <FormLabel focused>Subarachnoid Space</FormLabel>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>
                <Grid item xs={5}>
                    <FormLabel>Contralateral Homohemianopia</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="subarachnoidSpace.contralateralHomohemianopia"
                        value={values.contralateralHomohemianopia}
                        label="subarachnoidSpace"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Ipsilateral Homohemianopia</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="subarachnoidSpace.ipsilateralHemiplegia"
                        value={values.ipsilateralHemiplegia}
                        label="subarachnoidSpace"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>IV Cranial Nerve Meningitis</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="subarachnoidSpace.ivCranialNerveMeningitis"
                        value={values.ivCranialNerveMeningitis}
                        label="subarachnoidSpace"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Microvascular Disease Due to High Blood Pressure or Diabetes</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="subarachnoidSpace.microvascularDiseaseDuetoHighBloodPressureorDiabetes"
                        value={values.microvascularDiseaseDuetoHighBloodPressureorDiabetes}
                        label="subarachnoidSpace"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Posterior Cerebral Artery Ischemia</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="subarachnoidSpace.posteriorCerebralArteryIschemia"
                        value={values.posteriorCerebralArteryIschemia}
                        label="subarachnoidSpace"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Posterior Communication Artery Aneurysm</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="subarachnoidSpace.posteriorCommunicationArteryAneurysm"
                        value={values.posteriorCommunicationArteryAneurysm}
                        label="subarachnoidSpace"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>TB Pyogenic or Fungal</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="subarachnoidSpace.tbPyogenicorFungal"
                        value={values.tbPyogenicorFungal}
                        label="subarachnoidSpace"
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}


/////////eyeMuscleGeneralMultiDefects

export const EyeMuscleGeneralMultiDefects = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <React.Fragment>
            <FormLabel focused>Eye Muscle General Multi-Defects</FormLabel>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>
                <Grid item xs={6}>
                    <Grid container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={5}>
                            <FormLabel>Fine Motor Developmental Delay</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.fineMotorDevelopmentalDelay"
                                value={values.fineMotorDevelopmentalDelay}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Impaired Visual Motor Coordination</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.impairedVisualMotorCoordination"
                                value={values.impairedVisualMotorCoordination}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Impaired Visual Motor Skills</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.impairedVisualMotorSkills"
                                value={values.impairedVisualMotorSkills}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Letter Recognition Difficulty</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.letterRecognitionDifficulty"
                                value={values.letterRecognitionDifficulty}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Nyctalopia</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.nyctalopia"
                                value={values.nyctalopia}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Nystagmus</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.nystagmus"
                                value={values.nystagmus}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Reading Skill Impairment</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.readingSkillImpairment"
                                value={values.readingSkillImpairment}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Strabismus</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.strabismus"
                                value={values.strabismus}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={5}>
                            <FormLabel>Anosometropia</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.anosometropia"
                                value={values.anosometropia}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Anti Sacccades</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.antisacccades"
                                value={values.antisacccades}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Convergence Insufficiency</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.convergenceInsufficiency"
                                value={values.convergenceInsufficiency}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Defective Motor Execution</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.defectiveMotorExecution"
                                value={values.defectiveMotorExecution}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Defective Motor Planning</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.defectiveMotorPlanning"
                                value={values.defectiveMotorPlanning}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Disabled Kinesthetic Feedback</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.disabledKinestheticFeedback"
                                value={values.disabledKinestheticFeedback}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Disorganized Orthographic Coding</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.disorganizedOrthographicCoding"
                                value={values.disorganizedOrthographicCoding}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Double Vision</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.doubleVision"
                                value={values.doubleVision}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormLabel>Dyslexia</FormLabel>
                        </Grid>
                        <Grid item xs className={classes.itemspaceRight}>
                            <SelectOption
                                type="select"
                                name="eyeMuscleGeneralMultiDefects.dyslexia"
                                value={values.dyslexia}
                                label="eyeMuscleGeneralMultiDefects"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


        </React.Fragment>
    );
}

///// midbrain ipsilatral 

export const MidbrainLevelPregaglionicArea = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <React.Fragment>
            <FormLabel focused>Mid Brain Level Pregaglionic Area</FormLabel>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>
                <Grid item xs={5}>
                    <FormLabel>Ipsilateral Hemiplegia</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="midbrainLevelPregaglionicArea.ipsilateralHemiplegia"
                        value={values.ipsilateralHemiplegia}
                        label="midbrainLevelPregaglionicArea"
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormLabel>Contralateral Hemiplegia</FormLabel>
                </Grid>
                <Grid item xs className={classes.itemspaceRight}>
                    <SelectOption
                        type="select"
                        name="midbrainLevelPregaglionicArea.contralateralHemiplegia"
                        value={values.contralateralHemiplegia}
                        label="midbrainLevelPregaglionicArea"
                    />
                </Grid>
            </Grid>
        </React.Fragment>

    );
}


//Objective observation  list
//Eye muscle strength
export const EyeMuscleStrengthLeft = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <React.Fragment>
            {/* <FormLabel focused>Eye Muscle Strength (Oxford scale)</FormLabel> */}
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>


                <Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>LR</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="leftObservation.eyeMuscleStrength.LR"
                                value={values.LR}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>MR</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="leftObservation.eyeMuscleStrength.MR"
                                value={values.MR}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>SR</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="leftObservation.eyeMuscleStrength.SR"
                                value={values.SR}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>IR</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="leftObservation.eyeMuscleStrength.IR"
                                value={values.IR}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>SO</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="leftObservation.eyeMuscleStrength.SO"
                                value={values.SO}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>IO</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="leftObservation.eyeMuscleStrength.IO"
                                value={values.IO}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}
//Eye muscle strength
export const EyeMuscleStrengthRight = (props) => {
    const classes = useStyles();
    const [values] = React.useState(props.value ? props.value : {});

    return (
        <React.Fragment>
            {/* <FormLabel focused>Eye Muscle Strength (Oxford scale)</FormLabel> */}
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}>


                <Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>LR</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="rightObservation.eyeMuscleStrength.LR"
                                value={values.LR}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>MR</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="rightObservation.eyeMuscleStrength.MR"
                                value={values.MR}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>SR</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="rightObservation.eyeMuscleStrength.SR"
                                value={values.SR}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>IR</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="rightObservation.eyeMuscleStrength.IR"
                                value={values.IR}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>SO</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="rightObservation.eyeMuscleStrength.SO"
                                value={values.SO}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel>IO</FormLabel>
                        </Grid>
                        <Grid item xs={2} className={classes.itemspaceRight}>
                            <StrengthSelect
                                type="select"
                                name="rightObservation.eyeMuscleStrength.IO"
                                value={values.IO}
                                label="eyeMuscleStrength"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}