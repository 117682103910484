import { SET_USER, SET_AUTHENTICATED, SET_UNAUTHENTICATED, LOADING_USER, SET_SELECTED_PLAN, UPDATE_USER } from '../types';

const initialState = {
    authenticated: undefined,
    credentials: {},
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
                loading: false
            };
        case SET_UNAUTHENTICATED:
            return {
                ...state,
                authenticated: false,
                credentials: {},
                loading: false
            };
        case SET_USER:
            return {
                authenticated: true,
                ...action.payload
            };
        case SET_SELECTED_PLAN:
            let userData = {}
            userData = { ...state.credentials }
            userData.selectedPlan = action.payload
            return {
                ...state,
                credentials: userData,
                loading: false
            };
        case LOADING_USER:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER:
            let userDataUpdated = {}
            userDataUpdated = { ...state.credentials }
            userDataUpdated = { ...action.payload }
            return {
                ...state,
                credentials: userDataUpdated,
                loading: false
            }

        default:
            return state;
    }
}
