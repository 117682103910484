import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { green, purple, amber, deepOrange, cyan } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

import { setEvaluationType } from '../../../redux/actions/dataActions'
import { getProgressNotes, getUpdateNotes, getDischargeNotes } from '../../../redux/actions/progressNoteActions'
//redux
import { connect } from 'react-redux';


const styles = (theme) => ({
    ...theme.SpreadThis,
    // root: {
    //     minHeight: '100px'
    // },
    purpleButton: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        }
    },
    greenButton: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },

    },
    cyanButton: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: cyan[500],
        '&:hover': {
            backgroundColor: cyan[700],
        },

    },
    amberButton: {
        color: theme.palette.getContrastText(amber[500]),
        backgroundColor: amber[500],
        '&:hover': {
            backgroundColor: amber[700],
        },

    },
    deepOrangeButton: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        '&:hover': {
            backgroundColor: deepOrange[700],
        },

    }
})

class EvaluationType extends Component {
    render() {

        const { classes, currentEvaluation } = this.props
        // let evalId = currentEvaluation ? currentEvaluation.evaluationId ?currentEvaluation.evaluationId:"":"";
        let evalDetails = {
            ...currentEvaluation,

        }
        return (
            <div className={classes.root}>
                <Typography style={{ padding: "12px", backgroundColor: amber[500], }} variant="h6" gutterBottom>
                    Evaluation Type
                            </Typography>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <Button size="medium" fullWidth className={classes.purpleButton} onClick={() => { this.props.selectedEvalType("mainEvaluation") }}>
                            Evaluation
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="medium" fullWidth className={classes.deepOrangeButton} onClick={() => { this.props.getProgressNotes(evalDetails, this.props.history); this.props.selectedEvalType("Progress Note") }}>
                            Progress Note
                        </Button>

                    </Grid>
                    <Grid item xs={12}>
                        <Button size="medium" fullWidth className={classes.cyanButton} onClick={() => { this.props.getUpdateNotes(evalDetails, this.props.history); this.props.selectedEvalType("Update Note") }}>
                            Update Note
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="medium" fullWidth className={classes.greenButton} onClick={() => { this.props.getDischargeNotes(evalDetails, this.props.history); this.props.selectedEvalType("Discharge Note") }}>
                            Discharge Note
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    UI: state.UI,
    currentEvaluation: state.data.patient ? state.data.patient.evaluationDetails ? state.data.patient.evaluationDetails : {} : {}
})

const mapActionToProps = {
    getProgressNotes,
    getUpdateNotes,
    getDischargeNotes,
    setEvaluationType
}
export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(EvaluationType))
