import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

//redux
import { connect } from 'react-redux';
import { signupUser } from '../redux/actions/userActions';

const styles = (theme) => ({
    ...theme.spreadThis,
    root: {
        textAlign: 'center',
    },
    paper: {
        width: '70%',
        textAlign: 'center',
        padding: '20px 20px 40px 20px',
        marginTop: theme.spacing(4),
    }
})

class Signup extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            confirm_password: '',
            userHandle: '',
            userType: '',
            errors: {},

        }
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors })
        }
    }
    handleSubmit = (event) => {
        console.log("submit")
        event.preventDefault();
        const newUserData = {
            firstName: this.state.first_name,
            lastName: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirm_password,
            userHandle: this.state.userHandle,
            // userType: this.state.userType
        }
        this.props.signupUser(newUserData, this.props.history);
    }

    render() {
        const { classes, UI: { loading } } = this.props;
        const { errors } = this.state;
        return (
            <Grid
                className={classes.root}
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={6}>
                    <video autoPlay loop muted playsInline src="front-pic.mp4"></video>

                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <img src="/userIcon.png" alt="user" className={classes.image} />
                        <Typography variant="h5" className={classes.pageTitle}>
                            Signup
                   </Typography>
                        <form noValidate onSubmit={this.handleSubmit}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="first_name"
                                        name="first_name"
                                        type="text"
                                        label="FirstName"
                                        variant="outlined"
                                        className={classes.textField}
                                        helperText={errors.firstName}
                                        error={errors.firstName ? true : false}
                                        value={this.state.first_name}
                                        onChange={this.handleChange}
                                        size="small"

                                    />
                                </Grid>
                                <Grid item xs={6}><TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    label="LastName"
                                    variant="outlined"
                                    className={classes.textField}
                                    helperText={errors.lastName}
                                    error={errors.lastName ? true : false}
                                    value={this.state.last_name}
                                    onChange={this.handleChange}
                                    size="small"

                                />
                                </Grid>
                            </Grid>


                            <TextField
                                id="userHandle"
                                type="text"
                                name="userHandle"
                                label="User Name"
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                                value={this.state.userHandle}
                                helperText={errors.userHandle}
                                error={errors.userHandle ? true : false}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <TextField
                                id="email"
                                type="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                                value={this.state.email}
                                helperText={errors.email}
                                error={errors.email ? true : false}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <TextField
                                id="password"
                                name="password"
                                type="password"
                                label="Password"
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                                helperText={errors.password}
                                error={errors.password ? true : false}
                                value={this.state.password}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <TextField
                                id="confirmPassword"
                                name="confirm_password"
                                type="password"
                                label="ConfirmPassword"
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                                helperText={errors.confirm_password}
                                error={errors.confirm_password ? true : false}
                                value={this.state.confirm_password}
                                onChange={this.handleChange}
                                fullWidth
                            />

                            {errors.general && (
                                <Typography variant="body2" className={classes.customError}>
                                    {errors.general}
                                </Typography>
                            )}
                            <Button
                                type='submit'
                                variant='contained'
                                color="primary"
                                className={classes.button}
                                disabled={loading}
                            >
                                signup
                                {loading && <CircularProgress size={30} className={classes.progress} />}
                            </Button>
                            <br /><small>already have an account ? login <Link to='/login'>here</Link></small>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}
Signup.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    signupUser: PropTypes.func.isRequired

}
const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})
const mapActionToProps = {
    signupUser
}
export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(Signup))



// handleSubmit = (event) => {
//     event.preventDefault()
//     this.setState({
//         loading: true
//     })
//     const newUser = {
//         first_name: this.state.first_name,
//         last_name: this.state.last_name,
//         email: this.state.email,
//         password: this.state.password,
//         confirm_password: this.state.confirm_password
//     }
//     axios.post('api/user/register', newUser)
//         .then(res => {
//             if (res.data.code === 200) {
//                 document.cookie = "auth_token=" + res.data.token + ";expires=" + res.data.token.timestamb;
//                 //this.props.history.push('/home');
//                 this.props.onLoginAction(true)
//                 //alert("successfully registerd")
//                 this.setState({ loading: false })

//             }


//         }).catch(err => {
//             console.log(err)
//             this.setState({
//                 errors: err.response.data,
//                 loading: false
//             })
//             console.log(this.state.errors.general)
//         })
// }