import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles'
// import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { Button, CardContent, CircularProgress, Snackbar } from '@material-ui/core';
import { MyDialogTextField } from '../Evaluation/FormComponents/FormComponents';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
import * as Yup from 'yup';
import { Formik, } from 'formik';
import { Fragment } from 'react';
import { deepOrange } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { updateProfile } from '../../redux/actions/userActions';
import Profile from './Profile';
import Alert from '@material-ui/lab/Alert';


const styles = (theme) => ({
    ...theme.SpreadThis,
    root: {
        flexGrow: 1,
        width: '90%',
        margin: 'auto',
        // marginTop: theme.spacing(6)


    },
    card: {
        padding: theme.spacing(3),
        overflow: "visible",
        boxSizing: "border-box",
        marginTop: theme.spacing(2)
    },
    paperHead: {
        position: "relative",
        paddingTop: theme.spacing(.5),
        textAlign: 'center',
        margin: 'auto'
    },
    paper: {
        position: "absolute",
        top: '-70px',
        right: 0,
        left: 0,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        // backgroundColor: purple[500],
        // '&:hover': {
        //     backgroundColor: purple[700],
        // }
        backgroundColor: deepOrange[500],
        '&:hover': {
            backgroundColor: deepOrange[700],
        },
    },

    Button: {
        marginTop: theme.spacing(1)
    },
})
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


class EditHospitalProfile extends Component {

    state = {
        tostValue: ""
    }

    handleError = (isSuccess) => {
        if (isSuccess) {
            this.setState({ tostValue: "success" })
        }
        else if ((this.props.errors) && (this.props.errors.error)) {
            this.setState({ tostValue: 'error' })
        }

    }
    handleError = (isSuccess) => {
        if (isSuccess) {
            this.setState({ tostValue: "success" })
        }
        else if ((this.props.errors) && (this.props.errors.error)) {
            this.setState({ tostValue: 'error' })
        }

    }

    render() {
        const { classes, UI: { loading } } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={5} >
                        <Profile />
                        {/* <Paper className={classes.paper1}></Paper> */}
                    </Grid>
                    <Grid item xs={12} sm={7} style={{ marginTop: '32px' }}>
                        <Card className={classes.card}>
                            <Grid item xs={12} sm={11} className={classes.paperHead}>
                                <Paper className={classes.paper}>
                                    <h3>Update Business Profile</h3>
                                </Paper>
                            </Grid>


                            <CardContent>
                                <Formik
                                    initialValues={this.props.user ? this.props.user :
                                        {
                                            businessName: '',
                                            address: "",
                                            city: "",
                                            state: '',
                                            zipCode: '',
                                            phone: '',
                                            fax: "",
                                        }}
                                    validationSchema={Yup.object().shape({
                                        phone: Yup.string()
                                            .min(9, 'Phone number is not valid')
                                            .max(15, 'Phone number is not valid')
                                            .matches(phoneRegExp, 'Phone number is not valid')
                                            .required('Required'),
                                    })}
                                    onSubmit={(values, { setSubmitting, setFieldError, setStatus }) => {

                                        setTimeout(() => {
                                            this.props.updateProfile(values, (isSuccess) => isSuccess ? (this.handleError(isSuccess), setSubmitting(false)) : setSubmitting(false))

                                        }, 500)
                                    }}
                                >
                                    {({ values, isSubmitting, errors, submitForm, status }) => (
                                        <Fragment>
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item xs={12} sm={12} style={{ marginTop: '16px' }} >

                                                    <MyDialogTextField
                                                        name="businessName"
                                                        label="Business Name"
                                                        value={values.businessName}
                                                        placeholder="Business Name eg: Modern Therapy Works"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} >
                                                    <MyDialogTextField
                                                        name="address"
                                                        label="Address"
                                                        value={values.address}
                                                        placeholder="Address"
                                                    />
                                                </Grid>
                                                <Grid container spacing={2} xs={12} sm={12} >
                                                    <Grid item xs={12} sm={12} md={6} >
                                                        <MyDialogTextField
                                                            name="city"
                                                            label="City"
                                                            value={values.city}
                                                            placeholder="City"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6}>
                                                        <MyDialogTextField
                                                            name="zipCode"
                                                            label="Zip-Code"
                                                            type="number"
                                                            value={values.zipCode}
                                                            placeholder="Zip-Code"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={12} >
                                                    <MyDialogTextField
                                                        name="state"
                                                        label="State"
                                                        value={values.state}
                                                        placeholder="State"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <MyDialogTextField
                                                        name="phone"
                                                        label="Phone"
                                                        type="number"
                                                        value={values.phone}
                                                        placeholder="Phone"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <MyDialogTextField
                                                        name="fax"
                                                        label="Fax"
                                                        type="number"
                                                        value={values.fax}
                                                        placeholder="Fax"

                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12}   >
                                                <Button
                                                    onClick={submitForm}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={loading}
                                                    fullWidth
                                                    className={classes.Button}
                                                    size="large"
                                                >
                                                    {loading && <CircularProgress size={30} className={classes.progress} />}
                                                    Save
                                                </Button>

                                            </Grid>
                                            <Grid item xs={12} sm={12}  >
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={this.handleClose}
                                                    fullWidth
                                                    className={classes.Button}
                                                    size="large"
                                                >
                                                    Cancel
                                                </Button>

                                            </Grid>

                                        </Fragment>

                                    )}
                                </Formik>


                                <Snackbar open={this.state.tostValue ? true : false} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={this.handleSuccessClose} >

                                    <Alert severity={this.state.tostValue} onClose={this.handleSuccessClose}>
                                        {this.state.tostValue === "success" ? 'patient Registered successfully' : 'something went wrong'}
                                    </Alert>
                                </Snackbar>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    UI: state.UI,
    errors: state.UI ? state.UI.errors ? state.UI.errors : {} : {},
    user: state.user.credentials

})
const mapActionToProps = {

    updateProfile
}


export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(EditHospitalProfile))

