import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, } from 'react-router-dom'

//import AuthRoute from './utils/AuthRoute'
//import Home from './pages/home';
import Login from './pages/login';
import Signup from './pages/signup';
import ForgotPassword from './pages/forgotPassword'
import EditProfile from './components/UserProfile/EditProfile'
import Plans from './components/Subscription/Plans'
//import NavbarDrawer from './components/Navbar/NavbarDrawer';
import SearchPatient from './components/Search/SearchPatient'
import EvaluationTab from './components/Evaluation/EvaluationTab/EvaluationTab'
import TrainerApp from './components/TrainerApp/TrainerApp';
import EvaluationOne from './components/Evaluation/EvaluationFirst/EvaluationOne'
import PatientRegistrationForm from './components/Evaluation/PatientRegistration/PatientRegistrationForm'
import LongTermGoals from './components/Evaluation/Goals/LongTermGoals';
import ShortTermGoals from './components/Evaluation/Goals/ShortTermGoals'
import DischargeForm from './components/Evaluation/DischargeForm/DischargeForm'
import EvaluationSecond from './components/Evaluation/EvaluationSecond/EvaluationSecond'
import UserDashboard from './components/Dashboard/UserDashboard';
import ProffesionalDashboard from './components/Dashboard/ProffesionalDashboard';
import PatientInfo from './components/Search/PatientInfo'
import Payment from './components/PayPal/Payment';
import PlanExpiry from './components/UserProfile/PlanExpiry'
//import Appointment from './components/Evaluation/Appointment/Appointment'
import EvaluationMain from './components/Evaluation/EvaluationMain/EvaluationMain'
import NotesTab from './components/NotesTab/NotesTab';
import EditHospitalProfile from './components/UserProfile/EditHospitalProfile';
import Contact from './pages/contact';
import UserGuide from './components/UserGuide/UserGuide';
import YouTubeGuide from './components/UserGuide/YouTubeGuide';

class AppRoute extends Component {

    // componentDidUpdate=(prevProps)=>{
    //     if(prevProps.userType && prevProps.userType !== this.props.userType){}
    // }
    findMyAccount = () => {

        // return this.props.userType === "professional" ? ProffesionalDashboard : this.props.userType === "patient" ? UserDashboard : this.props.userType === "user" ? PlanExpiry : () => <div>Loading</div>;
        return this.props.userType === "professional" ? ProffesionalDashboard : this.props.userType === "patient" ? UserDashboard : this.props.userType === "user" ? PlanExpiry : () => <div>Loading</div>;

    }
    render() {
        return <Fragment>{this.props.authenticated === undefined ? <div><PlanExpiry /></div> : this.props.authenticated === true ?
            <Switch>
                <Route exact path='/my-account' component={this.findMyAccount()} />
                <Route exact path='/editprofile' component={EditProfile} />
                <Route exact path='/ed-hos-prof' component={EditHospitalProfile} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/plans' component={Plans} />
                <Route exact path='/trainer-app' component={TrainerApp} />
                <Route exact path='/patientreg' component={PatientRegistrationForm} />
                <Route exact path="/evafirst" component={EvaluationOne} />
                <Route exact path="/evasecond" component={EvaluationSecond} />
                <Route exact path="/longgoals" component={LongTermGoals} />
                <Route exact path="/shortgoals" component={ShortTermGoals} />
                <Route exact path="/discharge" component={DischargeForm} />
                <Route exact path="/evaluation" component={EvaluationTab} />
                <Route exact path="/search" component={SearchPatient} />
                <Route exact path="/patient" component={PatientInfo} />
                <Route exact path="/payment" component={Payment} />
                <Route exact path="/evaluationMain" component={EvaluationMain} />
                <Route exact path="/soapnotes" component={NotesTab} />
                <Route exact path="/userguide" component={UserGuide} />
                <Route exact path="/video-tutorial" component={YouTubeGuide} />

            </Switch> :
            <Switch>
                <Route exact path='/login' component={Login} />
                <Route exact path='/signup' component={Signup} />
                <Route exact path='/forgotpassword' component={ForgotPassword} />
            </Switch>}</Fragment>
    }
}
const mapStateToProps = (state) => ({
    authenticated: state.user ? state.user.authenticated : undefined,
    userType: state.user ? state.user.credentials ? state.user.credentials.userType : '' : ''
})
export default connect(mapStateToProps, {})(AppRoute)
