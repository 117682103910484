import {
    // LOADING_DATA,
    LOADING_UI,
    CLEAR_ERRORS,
    SET_ERRORS,
    SET_PROGRESSNOTES,
    // SET_CURRENT_PROGRESSNOTE,
    // UPDATE_CURRENT_PROGRESSNOTE,
    // SET_PROGNOTE_ID,
    SET_UPDATENOTES,
    SET_DISCHARGENOTE,
    SET_EVALUATION_DETAILS
} from '../types'
import axios from 'axios';

//add progressnote appointment
export const addProgessAppointment = (key, prgAppointment, evalutionId) => {

}


//update progressnote details
export const updateProgressNote = (key, prog_update, evaluationId) => {

}


//set current progressnote
// export const setCurrentProgNote = (selectedProgNote) => (dispatch) => {

//     dispatch({ type: LOADING_UI });
//     dispatch({
//         type: SET_PROGNOTE_ID,
//         payload: selectedProgNote.progressNoteId
//     })
//     dispatch({
//         type: SET_CURRENT_PROGRESSNOTE,
//         payload: selectedProgNote
//     })
//     console.log(selectedProgNote);
// }

//get progressnotes for an evaluation  
export const getProgressNotes = (evaluationDetails, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    let evalData = {
        ...evaluationDetails,
        evaluationType: "Progress Note"
    }
    axios.get(`/api/prognote/${evaluationDetails.evaluationId}`)
        .then(res => {
            dispatch({
                type: SET_EVALUATION_DETAILS,
                payload: evalData
            })
            dispatch({
                type: SET_PROGRESSNOTES,
                payload: res.data
            })
            dispatch({ type: CLEAR_ERRORS })

        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}
export const getUpdateNotes = (evaluationDetails, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    let evalData = {
        ...evaluationDetails,
        evaluationType: "Update Note"
    }
    axios.get(`/api/updateNotes/${evaluationDetails.evaluationId}`)
        .then(res => {
            dispatch({
                type: SET_EVALUATION_DETAILS,
                payload: evalData
            })
            dispatch({
                type: SET_UPDATENOTES,
                payload: res.data
            })
            dispatch({ type: CLEAR_ERRORS })

        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}
export const getDischargeNotes = (evaluationDetails, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    let evalData = {
        ...evaluationDetails,
        evaluationType: "Discharge Note"
    }
    axios.get(`/api/dischargeNote/${evaluationDetails.evaluationId}`)
        .then(res => {
            dispatch({
                type: SET_EVALUATION_DETAILS,
                payload: evalData
            })
            dispatch({
                type: SET_DISCHARGENOTE,
                payload: res.data
            })
            dispatch({ type: CLEAR_ERRORS })

        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}