import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import FormLabel from '@material-ui/core/FormLabel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Myradio, CustomTextField, CustomDatePicker, CustomCheckbox, MinHeightTextarea } from '../FormComponents/FormComponents'
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
//import CardMedia from '@material-ui/core/CardMedia';


//redux
import { connect } from 'react-redux';
import { updateEvaluation, dischargePlanUpdate } from '../../../redux/actions/dataActions';

const styles = theme => ({
    ...theme.SpreadThis,
    root: {
        flexgrow: 1,
        width: '75%',
        margin: '0 auto'
    },
    paper: {
        padding: theme.spacing(5),
        textAlign: 'left',
        alignItem: "center"
    },
    textfield: {
        padding: theme.spacing(2),
        textAlign: "center"
    },
    formlabel: {
        paddingTop: theme.spacing(1)
    },
    formlabelbottom: {
        textAlign: 'right'
    },
    certification: {
        paddingTop: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(3)
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    input: {
        display: 'none',
    },
    card: {
        maxWidth: '200px',
        border: '1px solid black',
        margin: '5px'
    }
})

const data = [
    { title: 'Low vision rehab' },
    { title: 'Occupational' },
    { title: "Physical" },
    { title: 'Speech therapy' }
]

const initialDischargeData = {
    dischargePlan: [],
    additionalPlans: '',
    certification: { from: new Date(), to: new Date() },
    physicianName: "",
    therapistSign: '',
    therapistName: '',
    doctorComments: '',
    treatmentPlan: '',
    eval_type: '',
    date: new Date()
}

const treatPlan = [{ title: "Continue treatment as per the plan of care" }]

class DischargeForm extends Component {
    findDischargeType = () => {

        let dischargeData = {};
        let evalType = this.props.patient ? this.props.patient.evaluationDetails ? this.props.patient.evaluationDetails.evaluationType ? this.props.patient.evaluationDetails.evaluationType : "" : "" : ""
        if (evalType === "mainEvaluation") {
            dischargeData = { ...this.props.mainDischargePlan }

            return dischargeData && Object.keys(dischargeData).length > 0 ? dischargeData : initialDischargeData
            // if (dischargeData && Object.keys(dischargeData).length > 0) {
            //      dischargeOut = dischargeData
            // }
            //  dischargeOut =initialDischargeData 

        }
        else if (evalType === "Progress Note") {
            dischargeData = this.props.progressDischargePlan
            return dischargeData && Object.keys(dischargeData).length > 0 ? dischargeData : initialDischargeData
        }
        else if (evalType === "Update Note") {
            dischargeData = this.props.updateDischargePlan
            return dischargeData && Object.keys(dischargeData).length > 0 ? dischargeData : initialDischargeData
        }
        else if (evalType === "Discharge Note") {
            dischargeData = this.props.dischargeDischargePlan
            return dischargeData && Object.keys(dischargeData).length > 0 ? dischargeData : initialDischargeData

        }
        else {
            return initialDischargeData

        }
    }
    checkAuth = () => {
        let evalType = this.props.patient ? this.props.patient.evaluationDetails ? this.props.patient.evaluationDetails.evaluationType ? this.props.patient.evaluationDetails.evaluationType : "" : "" : ""
        if (evalType === "Progress Note" || evalType === "Discharge Note") {
            return ""
        }
        else {
            return (
                <>
                    <Typography
                        variant="subtitle1"
                    >
                        I certify that</Typography>
                    <span><CustomCheckbox option={data} name="eval_type" /></span>
                    <Typography> services are required by the above patient and that this patient is presently under my care. Plan of treatment has been established for continuing care for as long as it is necessary in my professional opinion. Said plan will be reviewed at least 30 days as required.

                                    </Typography>
                </>
            )
        }

    }

    render() {
        const { patient, classes } = this.props
        let evalDetails = {
            key: "dischargePlan",

            ...patient.evaluationDetails
        }

        let photo = false;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Formik
                    initialValues={this.findDischargeType()}

                    onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
                        const formData = new FormData();
                        if (photo) {

                            formData.append('image', values.therapistSign);
                            let discharge_data = {}
                            discharge_data.physicianName = values.physicianName;
                            discharge_data.treatmentPlan = values.treatmentPlan;
                            discharge_data.additionalPlans = values.additionalPlans;
                            discharge_data.therapistName = values.therapistName;
                            discharge_data.certification = values.certification;
                            discharge_data.dischargePlan = values.dischargePlan;
                            // discharge_data.doctorComments = values.doctorComments;
                            discharge_data.date = values.date;
                            discharge_data.eval_type = values.eval_type;

                            const evaluationData = { ...discharge_data }
                            setTimeout(() => {
                                this.props.dischargePlanUpdate('dischargePlan', formData, evaluationData, patient.evaluationId, (isSuccess) => setSubmitting(false))
                            }, 500)
                        } else {
                            let discharge_data = {}
                            discharge_data.physicianName = values.physicianName;
                            discharge_data.therapistName = values.therapistName;
                            discharge_data.treatmentPlan = values.treatmentPlan;
                            discharge_data.additionalPlans = values.additionalPlans;
                            discharge_data.certification = values.certification;
                            discharge_data.dischargePlan = values.dischargePlan;
                            discharge_data.therapistSign = values.therapistSign;
                            // discharge_data.doctorComments = values.doctorComments;
                            discharge_data.date = values.date;
                            discharge_data.eval_type = values.eval_type;
                            const evaluationData = { dischargePlan: discharge_data }
                            setTimeout(() => {
                                this.props.updateEvaluation(evalDetails, evaluationData, (isSuccess) => setSubmitting(false))
                            }, 500)
                        }
                    }}
                >
                    {({ values, isSubmitting, submitForm, setFieldValue }) => (
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <Grid container justify="flex-start" spacing={2}>
                                    <Grid item xs>
                                        <p style={{ color: "red", textAlign: 'left' }}>
                                            {this.props.patient ? this.props.patient.evaluationDetails ? this.props.patient.evaluationDetails.evaluationType ? this.props.patient.evaluationDetails.evaluationType.toUpperCase() === "MAINEVALUATION" ? (<p>MAIN EVAUATION</p>) : this.props.patient.evaluationDetails.evaluationType.toUpperCase() : "" : "" : ""}
                                        </p>
                                    </Grid>
                                </Grid>
                                <Typography color="primary" className={classes.textfield} variant="h4" >Discharge Form</Typography>
                                {patient ? patient.evaluationDetails ? patient.evaluationDetails.evaluationType !== "Progress Note" ?
                                    (<Grid item xs={12} container direction="column">
                                        <FormLabel focused className={classes.formlabel}>Discharge Plan</FormLabel>
                                        <Myradio
                                            type="radio"
                                            name="dischargePlan"
                                            label="Patient will be Discharged when Goals have been Met"
                                            value="Patient will be Discharged when Goals have been Met"
                                        />
                                        <Myradio
                                            type="radio"
                                            name="dischargePlan"
                                            label="Patient will be Discharged With Home Optolight Exercise Program"
                                            value="Patient will be Discharged With Home Optolight Exercise Program"
                                        />
                                        <Myradio type="radio" name="dischargePlan" label="N/A" value="N/A" />
                                        <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
                                    </Grid>) : "" : "" : ""}

                                {patient ? patient.evaluationDetails ? patient.evaluationDetails.evaluationType === "Progress Note" ?
                                    (<Grid item xs container direction="column" >
                                        <FormLabel focused className={classes.formlabel}>Treatment Plan</FormLabel>
                                        <CustomCheckbox option={treatPlan} name="treatmentPlan" />
                                        <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
                                    </Grid>) : "" : "" : ""}

                                <Grid item xs={12} >
                                    <FormLabel focused className={classes.formlabel}>Additional Plans/Comments</FormLabel>
                                    <MinHeightTextarea
                                        name="additionalPlans"
                                        placeholder="Since the patient has speech and language difficulty patient is referred to a speech pathologist😊&#10;Since the patient has hearing problem patient is referred to Audiologist"
                                    />
                                </Grid>
                                <Grid className={classes.certification}
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item xs={2} >
                                        <FormLabel focused className={classes.formlabelbottom}>  On File </FormLabel>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomTextField name="therapistName" placeholder="Therapist's Name" />
                                    </Grid>
                                </Grid>
                                {/* {patient ? patient.evaluationDetails ? patient.evaluationDetails.evaluationType === "mainEvaluation" ?
                                    <Grid item xs>
                                        <FormLabel focused className={classes.formlabelbottom}>Therapist Signature </FormLabel>
                                        <Grid item xs={3}>
                                            <CardMedia
                                                className={classes.card}
                                                id="imageInput"
                                                component="img"
                                                alt="Upload signature"
                                                height="100"
                                                src={values.therapistSign}
                                                title="Contemplative Reptile"
                                            />

                                        </Grid>
                                        <Grid item xs>
                                            <input
                                                accept="image/*"
                                                className={classes.input}
                                                id="contained-button-file"
                                                multiple
                                                name="therapistSign"
                                                type="file"
                                                onChange={(event) => {
                                                    setFieldValue("therapistSign", event.currentTarget.files[0]);
                                                    photo = true
                                                }}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button variant="contained" color="primary" component="span">
                                                    Upload
                                            </Button>
                                            </label>
                                        </Grid>

                                    </Grid> : "" : "" : ""} */}


                                <Grid
                                    className={classes.certification}
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item xs={2} >
                                        <FormLabel focused className={classes.formlabelbottom}>Date</FormLabel>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomDatePicker name="date" label="Date" />
                                    </Grid>
                                </Grid>
                                <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />

                                <Grid item  >
                                    {this.checkAuth()}

                                    <Grid
                                        className={classes.certification}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item xs={2} >
                                            <FormLabel focused className={classes.formlabelbottom}>Certification</FormLabel>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CustomDatePicker name="certification.from" label="From" />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CustomDatePicker name="certification.to" label="To" />
                                        </Grid>
                                    </Grid>
                                    <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />

                                    <Grid container justify="center" >
                                        <Button
                                            className={classes.button}
                                            color="primary"
                                            variant="contained"
                                            disabled={isSubmitting}
                                            component={Link}
                                            to="/search"
                                            onClick={submitForm}
                                        >
                                            save & continue
                                            </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    )}
                </Formik>
            </MuiPickersUtilsProvider>
        )
    }
}
const mapStateToProps = (state) => ({
    patient: state.data.patient,
    dischargePlan: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation.dischargePlan ? state.data.patient.evaluation.dischargePlan : {} : {} : {},
    mainDischargePlan: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation.dischargePlan ? state.data.patient.evaluation.dischargePlan : {} : {} : {},
    progressDischargePlan: state.data.patient ? state.data.patient.progressNote ? state.data.patient.progressNote.dischargePlan ? state.data.patient.progressNote.dischargePlan : {} : {} : {},
    updateDischargePlan: state.data.patient ? state.data.patient.updateNotes ? state.data.patient.updateNotes.dischargePlan ? state.data.patient.updateNotes.dischargePlan : {} : {} : {},
    dischargeDischargePlan: state.data.patient ? state.data.patient.dischargeNotes ? state.data.patient.dischargeNotes.dischargePlan ? state.data.patient.dischargeNotes.dischargePlan : {} : {} : {},
    UI: state.data
})
export default connect(mapStateToProps, { updateEvaluation, dischargePlanUpdate })(withStyles(styles)(DischargeForm))
