import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import userReducer from './reducers/userReducer'
import dataReducer from './reducers/dataReducer'
import uiReducer from './reducers/uiReducer'


const initialState = {};
const middleware = [thunk];


const reducers = combineReducers({
    user: userReducer,
    data: dataReducer,
    UI: uiReducer
});


//for local redux dev tools configuration
// const composeEnhancers =
//     typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//         ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
//         : compose;

// const enhancer = composeEnhancers(applyMiddleware(...middleware));
// const store = createStore(reducers, initialState, enhancer);

//for production store configuration
const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(...middleware)
    )
);


export default store;