import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import YouTube from 'react-youtube';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: "auto",
        height: "88vh"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

const YouTubeGuide = () => {
    const classes = useStyles();



    const opts = {
        height: '490',
        width: '740',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };
    const handleReady = (event) => {
        event.target.pauseVideo();
    }


    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <Typography component="h5" variant="h5">
                    Training Procedure
                </Typography>
                <YouTube videoId="GUSmJJeOAe4" opts={opts} onReady={handleReady} />

            </CardContent>
        </Card>
    )
}

export default YouTubeGuide
