import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid'
import { Formik } from 'formik'
import withStyles from '@material-ui/core/styles/withStyles'
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';

//custom compoents
import MyButton from '../../utils/MyButton'
import { OutlinedTextField } from '../Evaluation/FormComponents/FormComponents'

//rdux
import { connect } from 'react-redux'
import { updateProfile } from '../../redux/actions/userActions'


const styles = (theme) => ({
    ...theme.SpreadThis,
    closeButton: {
        position: 'absolute',
        left: '91%',
        top: '3%'
    },
    dialogAction: {
        margin: '8px 32px 16px 0',
    },
    styledHeader: {
        background: '#009688',
        '& h2': {
            color: 'white',
            fontSize: '1.5rem',
        }
    }
})

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }
    handleClose = () => {
        this.setState({
            open: false,
            profileData: ''
        })
        this.handleOpen.bind(this)
        this.handleClose.bind(this)
    }
    render() {
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        const { classes } = this.props
        const { profileData } = this.state
        return (
            <Fragment>
                <MyButton tip="Edit Profile" onClick={this.handleOpen}>
                    <EditIcon />
                </MyButton>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth="md"
                >
                    <MyButton
                        onClick={this.handleClose}
                        tip="close window"
                        tipClassName={classes.closeButton}
                    >
                        <CloseIcon />
                    </MyButton>
                    <DialogTitle className={classes.styledHeader}>Edit Profile</DialogTitle>
                    <hr style={{ border: 'none', margin: '15px 0 10px 0' }} />
                    <Formik
                        initialValues={
                            profileData ? profileData : {
                                lastName: '',
                                firstName: '',
                                phone: '',
                                address: '',
                                place: '',
                                state: ''
                            }}
                        validationSchema={Yup.object({
                            lastName: Yup.string()
                                .max(15, 'Must be 15 charactors or less')
                                .required('Required'),
                            firstName: Yup.string()
                                .max(15, 'Must be 15 charactors or less')
                                .required('Required'),
                            phone: Yup.string()
                                .min(9, 'Phone number is not valid')
                                .max(15, 'Phone number is not valid')
                                .matches(phoneRegExp, 'Phone number is not valid')
                                .required('Rrquired'),
                            address: Yup.string()
                                .required('Required'),
                            place: Yup.string()
                                .required('Required'),
                            state: Yup.string()
                                .required('Required')
                        })}
                        onSubmit={(values, { setSubmitting }) => (
                            setTimeout(() => {
                                alert(JSON.stringify(values, 2, null))
                                setSubmitting(false)
                            })
                        )}
                    >
                        {({ values, isSubmitting, submitForm }) => (
                            <Fragment>
                                <DialogContent>
                                    <Grid container direction="row" spacing={3}>

                                        <Grid item xs={6}>
                                            <OutlinedTextField
                                                name="lastName"
                                                label="Last Name"
                                                value={values.lastName}
                                                placeholder="Last Name"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OutlinedTextField
                                                name="firstName"
                                                label="First Name"
                                                value={values.firstName}
                                                placeholder="First Name"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OutlinedTextField
                                                name="phone"
                                                label="Phone"
                                                value={values.phone}
                                                placeholder="Phone"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OutlinedTextField
                                                name="address"
                                                label="Address"
                                                value={values.address}
                                                placeholder="Address"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OutlinedTextField
                                                name="place"
                                                label="Place"
                                                value={values.place}
                                                placeholder="Place"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OutlinedTextField
                                                name="state"
                                                label="State"
                                                value={values.state}
                                                placeholder="State"
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className={classes.dialogAction}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={this.handleClose}
                                    >
                                        Cancel
                                     </Button>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save & Continue
                                     </Button>
                                </DialogActions>

                            </Fragment>
                        )}
                    </Formik>

                </Dialog>
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps, { updateProfile })(withStyles(styles)(EditProfile))
