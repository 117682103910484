import React, { Component } from 'react'
//import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles'
import ProfessionalPlans from './ProfessionalPlans';
// import Pricing from './UserPlans';
import theme from '../../utils/theme';
import Typography from '@material-ui/core/Typography';



const styles = (teme) => ({
    ...theme.spreadThis,
    plans: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }

})
export class Plans extends Component {
    state = {
        selectedPlan: '',
        gilad: true,
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
        console.log(this.state.gilad)
    };

    render() {
        const { gilad } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.plans}>
                <div >
                    <Typography variant="h2" color="textSecondary" component="p">

                    </Typography>
                    <FormControl component="fieldset">
                        {/* <FormLabel component="legend">
                            Assign responsibility
                     </FormLabel> */}

                        {/* <FormControlLabel
                            control={<Switch checked={this.state.gilad} onChange={this.handleChange('gilad')} value="gilad" />}
                            label="select plan"
                        /> */}
                    </FormControl>
                </div>
                {/* {gilad ? <ProfessionalPlans history={this.props.history} /> : <Pricing history={this.props.history} />} */}
                {gilad ? <ProfessionalPlans history={this.props.history} /> : ""}
            </div>
        )
    }
}

export default withStyles(styles)(Plans)
