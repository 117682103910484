import axios from 'axios';

const paypal = require('paypal-checkout');
const client = require('braintree-web/client');
const paypalCheckout = require('braintree-web/paypal-checkout');


export default class PayPalClient {

  static renderPayPal = (props) => {
    if (props.clientId) {

      paypal.Button.render({
        braintree: {
          client,
          paypalCheckout,
        },
        client: {
          production: props.clientId,
          // sandbox: props.clientId,
          //   paypalCheckout: paypalCheckout
        },

        env: 'production', //'sandbox', // or 'production'
        payment: function (data, actions) {

          console.log(props.selectedPlan);
          return actions.braintree.create({
            flow: 'checkout', // Required
            amount: props.selectedPlan.price, // Required
            currency: 'USD', // Required
            enableShippingAddress: true,
            shippingAddressEditable: false,
            // shippingAddressOverride: {
            //   recipientName: 'Scruff McGruff',
            //   line1: '1234 Main St.',
            //   line2: 'Unit 1',
            //   city: 'Chicago',
            //   countryCode: 'US',
            //   postalCode: '60652',
            //   state: 'IL',
            //   phone: '123.456.7890'
            // }
          });
        },

        onAuthorize: function (payload) {

          axios.post("/api/paypal/checkout", { selectedPlan: props.selectedPlan.type, userType: props.selectedPlan.plan, nonce: payload.nonce })
            .then(k => {
              alert(k.data.message)
              //props.history.push('/my-account')
              window.location.href = '/my-account';
            })
            .catch(k => {
              alert(k.data.message)
            })

          // Submit `payload.nonce` to your server.
        }
      }, '#paypal-button');
    }

  }
}