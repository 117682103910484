import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import { resetPassword } from '../redux/actions/userActions'

const styles = theme => ({
    ...theme.spreadThis,
    paper: {
        width: '33%',
        minWidth: '280px',
        margin: 'auto',
        height: '100%',
        padding: '20px 20px 40px 20px',
        textAlign: 'center',
        color: theme.palette.primary,
    },


})

class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            errors: {}
        }
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            email: this.state.email
        }
        this.props.resetPassword(userData, this.props.history);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors })
        }
    }

    render() {
        const { classes, UI: { loading } } = this.props;
        const { errors, } = this.state;
        return (
            <div>
                <Paper className={classes.paper}>
                    <Typography variant="h5" className={classes.pageTitle}>
                        What's My Password
                            </Typography>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <Grid container justify="center" alignItems="center" direction="row">
                            <Grid item xs={12}>
                                <Typography varient="h6">If you have forgotten your password you can reset here.</Typography>
                            </Grid>

                            <Grid item xs={10}>

                                <TextField
                                    id="email"
                                    type="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    className={classes.textField}
                                    value={this.state.email}
                                    helperText={errors.email}
                                    error={errors.email ? true : false}
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {errors.general && (
                                    <Typography variant="body2" className={classes.customError}>{errors.general}</Typography>
                                )}
                                <Button
                                    type="submit"
                                    variant='contained'
                                    color="primary"
                                    className={classes.button}
                                    disabled={loading}
                                >
                                    Reset Password
                                    {loading && <CircularProgress size={30} className={classes.progress} />}
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </Paper>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    UI: state.UI
})
const mapActionToProps = {
    resetPassword
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(ForgotPassword))
