import React, { Component, Fragment } from 'react'
import { Formik, Field } from 'formik'
import Typography from '@material-ui/core/Typography';
import { MyTextField, ComboBox, Myradio, DatePickerField, MultiSelectDropebox } from '../FormComponents/FormComponents'
import { CheckboxWithLabel } from 'formik-material-ui';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
//import { Link } from 'react-router-dom';

//redux stuff
import { addAppointment } from '../../../redux/actions/dataActions'
import { connect } from 'react-redux'

const styles = (theme) => ({
    ...theme.SpreadThis,
    button: {
        margin: theme.spacing(3)
    },
    evaluationtxt: {
        textAlign: "left"
    },
    form: {
        padding: theme.spacing(4)
    },
    form1: {
        padding: "8px 32px 8px 32px"
    },
    styledHeader: {
        background: '#009688',

        '& MuiTypography-h5': {
            color: 'white',
            textAlign: 'center',
            fontSize: '2rem'
        }
    }
})
const insuranceList = [
    { title: "Blue Cross / Blue Shield" },
    { title: "Children Medical Service" },
    { title: "CIGNA" },
    { title: "Freedom 1st" },
    { title: "Medicaid" },
    { title: "MedicAid Waiver" },
    { title: "Medicare" },
    { title: "Optimum" },
    { title: "PediCare" },
    { title: "Self Pay" },
    { title: "Staywell" },
    { title: "Sunshine" },
    { title: "WellCare" },
    { title: "Worker's Compensation" },
    { title: "others" }
];
const treatmentDiagnosis = [
    { title: "Anosometropia" },
    { title: "Antisacccades" },
    { title: "Convergence Insufficiency" },
    { title: "Defective Motor Execution" },
    { title: "Defective Motor Planning" },
    { title: "Disabled Kinesthetic Feedback" },
    { title: "Disorganized Orthographic Coding" },
    { title: "Double Vision" },
    { title: "Dyslexia" },
    { title: "Fine Motor Developmental Delay" },
    { title: "Impaired Visual Motor Coordination" },
    { title: "Impaired Visual Motor Skills" },
    { title: "Letter Recognition Difficulty" },
    { title: "Nyctalopia" },
    { title: "Nystagmus" },
    { title: "Reading Skill Impairment" },
    { title: "Strabismus" },
    { title: "others" }
]
const primaryDiagnosisList = [
    { title: "Astigmatism" },
    { title: "Cataracts" },
    { title: "Chalazion(meibomian Blocking glands)" },
    { title: "Color blindness" },
    { title: "Conjunctivitis" },
    { title: "Corneal disease" },
    { title: "Crossed eyes (Strabismus)" },
    { title: "Dry eyes" },
    { title: "Excess tearing" },
    { title: "Eye strain" },
    { title: "Eyelid problems" },
    { title: "Farsightedness(Hyperopia)" },
    { title: "Floaters" },
    { title: "Glaucoma" },
    { title: "Lazy eye(Amblyopia)" },
    { title: "Low vision" },
    { title: "Macular degeneration" },
    { title: "Near-sightedness (Myopia)" },
    { title: "Night blindness" },
    { title: "Nystagmus" },
    { title: "Presbyopia" },
    { title: "Red eyes" },
    { title: "Retinal disorders" },
    { title: "Stye" },
    { title: "Uveitis" },
    { title: "Vision changes" },
    { title: "others" }
]
let initialAppointData = {
    onSetDate: new Date(),
    socDate: new Date(),
    initialEvaluation: false,
    rx: false,
    primaryDiagnosis: [],
    physiciansName: [],
    treatmentDiagnosis: [],
    insurance: '',
    otherInsurance: '',
    service: [],
    prescriptionDate: new Date(),
    othertreatmentDiagnosis: '',
    otherPrimaryDiagnosis: '',
    appointmentDate: new Date()
}

class AppoinmentForm extends Component {

    findAppointmentType = () => {
        let appointData = {}
        let evalType = this.props.patient ? this.props.patient.evaluationDetails ? this.props.patient.evaluationDetails.evaluationType ? this.props.patient.evaluationDetails.evaluationType : "" : "" : ""
        if (evalType === "mainEvaluation") {
            appointData = { ...this.props.appointmentData }
            return appointData && Object.keys(appointData).length > 0 ? appointData : initialAppointData
        }
        else if (evalType === "Progress Note") {
            appointData = this.props.progAppointmentData
            // let progAppointmentData = this.props.patient ? this.props.patient.progressNote ? this.props.patient.progressNote.appointment ? this.props.patient.progressNote.appointment : "" : "" : ""
            return appointData && Object.keys(appointData).length > 0 ? appointData : initialAppointData
        }
        else if (evalType === "Update Note") {
            appointData = this.props.updateAppointData
            return appointData && Object.keys(appointData).length > 0 ? appointData : initialAppointData
        }
        else if (evalType === "Discharge Note") {
            appointData = this.props.disAppointmentData
            return appointData && Object.keys(appointData).length > 0 ? appointData : initialAppointData
        }
        else {
            return initialAppointData
        }
    }


    render() {


        const { classes } = this.props;
        const { patient: { evaluationDetails, patientAutoGenId } } = this.props
        let evalDetails = {
            key: "appointment",
            patientAutoGenId: patientAutoGenId,
            ...evaluationDetails
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Formik
                    enableReinitialize
                    // initialValues={this.props.appointmentData && Object.keys(this.props.appointmentData).length > 0 ? this.props.appointmentData : {
                    // initialValues={appointment && Object.keys(this.props.appointment).length > 0 ? appointment : {
                    //     onSetDate: new Date(),
                    //     socDate: new Date(),
                    //     initialEvaluation: false,
                    //     rx: false,
                    //     primaryDiagnosis: [],
                    //     physiciansName: [],
                    //     treatmentDiagnosis: [],
                    //     insurance: '',
                    //     otherInsurance: '',
                    //     service: [],
                    //     prescriptionDate: new Date(),
                    //     othertreatmentDiagnosis: '',
                    //     otherPrimaryDiagnosis: ''
                    //     //evaluationId: patient.evaluationId

                    // }}
                    initialValues={this.findAppointmentType()}
                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        let appoimentData = { appointment: values }
                        //appoimentData.appointment.currentEvaluationId = evalDetails.evaluationId ? evalDetails.evaluationId : ""
                        setTimeout(() => {
                            let handleChange = this.props.handleChange;
                            this.props.addAppointment(evalDetails, appoimentData, (isSuccess) => { handleChange(1, isSuccess); setSubmitting(false) })
                        }, 500)
                        //resetForm()
                        setStatus({
                            msg: this.props.UI.message
                        })
                    }}
                >
                    {({ values, isSubmitting, submitForm }) => (
                        <Fragment>
                            {/* {Object.keys(this.props.appointmentData).length === 0 ? isSubmitting = false : isSubmitting = true} */}

                            <Grid
                                className={classes.form1}
                                container
                                direction="row"
                                justify="space-around"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item xs={6}  >
                                    <DatePickerField
                                        name="appointmentDate"
                                        placeholder="Appointment Date"
                                    />
                                </Grid>
                                <Grid item xs={6}  >

                                </Grid>
                            </Grid>
                            <Grid
                                className={classes.form}
                                container
                                direction="row"
                                justify="space-around"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item xs={6}  >
                                    <DatePickerField
                                        name="onSetDate"
                                        placeholder="ONSET Date"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePickerField
                                        name="socDate"
                                        placeholder="SOC Date"

                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.evaluationtxt}>
                                    <Typography variant="subtitle1" >Intial Evaluation</Typography>
                                    <Myradio type="radio" value="yes" label="Yes" name="initialEvaluation" />
                                    <Myradio type="radio" value="No" label="No" name="initialEvaluation" />
                                </Grid>
                                <Grid item xs={6} className={classes.evaluationtxt}>
                                    <Typography variant="subtitle1" >Rx</Typography>
                                    <Myradio type="radio" value="Yes" label="Yes" name="rx" />
                                    <Myradio type="radio" value="No" label="No" name="rx" />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <MyTextField
                                        name="primaryDiagnosis"
                                        placeholder="Medical Primary Diagnosis"
                                    /> */}
                                    <MultiSelectDropebox
                                        OptionList={primaryDiagnosisList}
                                        name="primaryDiagnosis"
                                        placeholder="Medical Primary Diagnosis"
                                        as="select"
                                        value={values.primaryDiagnosis}
                                    />
                                    {values.primaryDiagnosis ? values.primaryDiagnosis.find(treat => treat.title === "others") ? <MyTextField
                                        name="otherPrimaryDiagnosis"
                                        placeholder="Other Primary Diagnosis"

                                    /> : '' : ''}
                                </Grid>
                                <Grid item xs={6}>
                                    <MultiSelectDropebox
                                        OptionList={treatmentDiagnosis}
                                        name="treatmentDiagnosis"
                                        placeholder="Rehab Treatment Diagnosis"
                                        as="select"
                                        value={values.primaryDiagnosis}
                                    />

                                    {values.treatmentDiagnosis ? values.treatmentDiagnosis.find(treat => treat.title === "others") ? <MyTextField
                                        name="othertreatmentDiagnosis"
                                        placeholder="Other Treatment Diagnosis"

                                    /> : '' : ''}

                                </Grid>
                                <Grid item xs={6}>
                                    <MyTextField
                                        name="physiciansName"
                                        placeholder="Physician's Name"
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    {/* <MyTextField name="duration" placeholder="Duration" /> */}
                                    <DatePickerField
                                        name="prescriptionDate"
                                        placeholder="Prescription Date"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ComboBox
                                        label="Insurance"
                                        OptionList={insuranceList}
                                        name="insurance"
                                        placeholder="Insurance"
                                        as="select"
                                        value={values.insurance}
                                    />
                                    {values.insurance ? values.insurance.title === "others" ? <MyTextField
                                        name="otherInsurance"
                                        placeholder="Inusurance"

                                    /> : '' : ''}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                    >
                                        Service
                                                    </Typography>
                                    <Field
                                        component={CheckboxWithLabel}
                                        name="service"
                                        type="checkbox"
                                        value="PT"
                                        Label={{ label: 'PT' }}
                                    />
                                    <Field
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'OT' }}
                                        type="checkbox"
                                        name="service"
                                        value="OT"
                                    />
                                    <Field
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'ST' }}
                                        type="checkbox"
                                        name="service"
                                        value="ST"
                                    />
                                    <Field
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'LV' }}
                                        type="checkbox"
                                        name="service"
                                        value="LV"
                                    />

                                </Grid>
                                <Grid container justify="center" >
                                    {/* {this.props.appoimentData ? (isSubmitting = true) : isSubmitting = false} */}
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    //component={Link}
                                    //to="/evafirst"
                                    >
                                        save & continue
                                        </Button>
                                </Grid>
                            </Grid>

                        </Fragment>
                    )}

                </Formik>
            </MuiPickersUtilsProvider>

        )
    }
}

const mapStateToProps = (state) => ({

    UI: state.UI,
    patient: state.data.patient ? state.data.patient : {},
    appointmentData: state.data.patient ? state.data.patient.evaluation ? state.data.patient.evaluation.appointment ? state.data.patient.evaluation.appointment : {} : {} : {},
    progAppointmentData: state.data.patient ? state.data.patient.progressNote ? state.data.patient.progressNote.appointment ? state.data.patient.progressNote.appointment : {} : {} : {},
    updateAppointData: state.data.patient ? state.data.patient.updateNotes ? state.data.patient.updateNotes.appointment ? state.data.patient.updateNotes.appointment : {} : {} : {},
    disAppointmentData: state.data.patient ? state.data.patient.dischargeNotes ? state.data.patient.dischargeNotes.appointment ? state.data.patient.dischargeNotes.appointment : {} : {} : {}
})
const mapActionToProps = {
    addAppointment
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(AppoinmentForm))
