import React, { Component } from 'react'
//import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from 'axios';
//payment stuff
import PayPalClient from '../PayPal/PayPalButton';
import { connect } from 'react-redux'
import { Button } from '@material-ui/core';

import { startFreeTrial } from '../../redux/actions/userActions'

const styles = ((theme) => ({
    main: {
        marginTop: '0px',
        backgroundImage: "url('plant.jpg')",
        backgroundsize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        backgroundPosition: 'center',
    },
    root: {
        minWidth: 275,
        marginTop: '80px'
    },
    card: {
        padding: theme.spacing(1),

    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: '32px 80px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
        // paddingBottom: theme.spacing(4)
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    title: {
        padding: theme.spacing(2)
    },

    // paymentButton: {
    //     padding: '8px 128px'
    // }
}));


class Payment extends Component {
    componentDidMount = () => {
        if (this.props.selectedPlan.plan !== "userOffer") {

            axios.get("/api/paypal/client_token")
                .then(res => {
                    PayPalClient.renderPayPal({ clientId: res.data, selectedPlan: this.props.selectedPlan, history: this.props.history });
                    //console.log(res.data);
                    console.log(this.props.selectedPlan)

                });
        }

    }
    findPrice = (selectedPlan) => {
        let planPrice = ""
        if (selectedPlan.plan === "user") {
            planPrice = <Typography className={this.props.classes.subtitle} variant="h7" component="h3">${selectedPlan.price}</Typography>
        }
        else if ((selectedPlan.plan === "professional") && (this.props.credentials.isProfOfferAvailable === true)) {
            planPrice = <Typography variant="h7" component="h3" className={this.props.classes.subtitle}>${selectedPlan.price}</Typography>

        }
        else if (selectedPlan.plan === "professional") {
            planPrice = <Typography className={this.props.classes.subtitle} variant="h7" component="h3">${selectedPlan.price}</Typography>

        }
        return planPrice
    }

    render() {
        const { classes, selectedPlan } = this.props;
        return (
            <div className={classes.main}>
                <Grid container justify='center' alignItems="center">
                    <Grid item xs={4}>
                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography className={classes.title} variant="h6" component="h3">Order Summary</Typography>

                                <Divider style={{ marginBottom: '16px' }} />
                                <Grid container justify="space-between" >
                                    <Grid item >
                                        <Typography className={classes.subtitle}>Plan</Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography className={classes.subtitle}>{selectedPlan.title}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container justify="space-between">
                                    <Grid item >
                                        <Typography className={classes.subtitle}>Subtotal</Typography>
                                    </Grid>
                                    <Grid item >
                                        {this.findPrice(selectedPlan)}
                                        {/* {credentials.isProfOfferAvailable === true ? (
                                            <Typography className={classes.subtitle}>${selectedPlan.offerPrice}</Typography>
                                        ) : (
                                                <Typography className={classes.subtitle}>${selectedPlan.price}</Typography>
                                            )} */}
                                    </Grid>
                                </Grid>
                                <Grid container justify="space-between">

                                    <Grid item>
                                        <Typography className={classes.subtitle}>Validity</Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography className={classes.subtitle}>{selectedPlan.validity}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="space-between">

                                    <Grid item >
                                        <Typography className={classes.subtitle} variant="h7" component="h3">Total</Typography>

                                    </Grid>
                                    <Grid item >
                                        {this.findPrice(selectedPlan)}
                                        {/* {credentials.isProfOfferAvailable === true ? (
                                            <Typography className={classes.subtitle} variant="h7" component="h3">${selectedPlan.offerPrice}</Typography>
                                        ) : (

                                                <Typography className={classes.subtitle} variant="h7" component="h3">${selectedPlan.price}</Typography>
                                            )} */}
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-end" style={{ marginTop: '8px' }}>
                                    <Grid item>
                                        {/* <Button id='paypal-button' size="large" value="title" className={classes.paymentButton} colour='primary' /> */}
                                        {selectedPlan.plan === "userOffer" ? (<Button fullWidth variant="contained" color="primary" onClick={() => this.props.startFreeTrial(selectedPlan, this.props.history)}>
                                            Start Free Trial
                                        </Button>) : <div id='paypal-button' color="primary" />}
                                        {/* <img alt='example' src="taj.jpg" /> */}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid >
                </Grid >
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    credentials: state.user ? state.user.credentials ? state.user.credentials : "" : "",
    selectedPlan: state.user ? state.user.credentials ? state.user.credentials.selectedPlan ? state.user.credentials.selectedPlan : {} : {} : {}
})
const mapActionToProps = {
    startFreeTrial
}
export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(Payment))
