import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { purple } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
//redux stuff
import { connect } from 'react-redux';
import { getAllPatients, setCurrentPatient, getEvaluations } from '../../redux/actions/dataActions'

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const style = (theme) => ({
    table: {
        minWidth: 500,
    },
    tablemain: {
        padding: theme.spacing(3),
        overflow: "visible",
        boxSizing: "border-box"
    },
    paperHead: {
        position: "relative",
        paddingTop: theme.spacing(.5),
        textAlign: 'center',
        margin: 'auto'
    },
    paper: {
        position: "absolute",
        top: '-75px',
        right: 0,
        left: 0,
        padding: theme.spacing(1),
        textAlign: 'center',
        margin: 'auto',
        color: "white",
        backgroundColor: purple[400],
    },
})

class ProfTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: this.props.noRows,
            page: 0
        }
        this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage.bind(this)
    }
    componentDidMount() {
        this.props.getAllPatients();
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 })
    };

    render() {
        const { page, rowsPerPage } = this.state;
        const { classes, data: { patients } } = this.props
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, patients.length - page * rowsPerPage);
        return (
            <TableContainer className={classes.tablemain} component={Paper}>
                <Grid item xs={12} sm={11} className={classes.paperHead}>
                    <Paper className={classes.paper}>
                        <h3>Recent Patients</h3>
                    </Paper>
                </Grid>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Patient Name</TableCell>
                            <TableCell align="right">Patient Id</TableCell>
                            <TableCell align="right">Address</TableCell>
                            <TableCell align="right">Phone</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!patients ? (<p>loading</p>) : (rowsPerPage > 0
                            ? patients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : patients
                        ).map((patient) => (
                            <TableRow key={patient.patientId}>
                                <TableCell component="th" scope="row">
                                    {patient.firstName + ' ' + patient.lastName}
                                </TableCell>
                                <TableCell align="right">{patient.patientId}</TableCell>
                                <TableCell align="right">{patient.address}</TableCell>
                                <TableCell align="right">{patient.phone}</TableCell>
                                <TableCell align="right" onClick={() => {
                                    this.props.setCurrentPatient(patient.patientId);
                                    this.props.getEvaluations(patient.uniqueId, this.props.history);
                                    this.props.history.push('/patient')
                                }}>
                                    <EditIcon color="primary" />
                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[3, 5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={5}
                                count={patients.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }
}
const mapStateToProps = (state) => ({
    data: state.data
})
const mapActionToProps = {
    getAllPatients,
    setCurrentPatient,
    getEvaluations

}

export default connect(mapStateToProps, mapActionToProps)(withStyles(style)(ProfTable))
