import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import StarIcon from '@material-ui/icons/StarBorder';
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { green } from '@material-ui/core/colors';

import Box from '@material-ui/core/Box';
//import axios from 'axios';
//payment stuff
//import PayPalClient from '../PayPal/PayPalButton';
//redux
import { connect } from 'react-redux';
import { selectedPlan, startFreeTrial } from '../../redux/actions/userActions';
// import { Avatar, Paper } from '@material-ui/core';



const styles = (theme) => ({
    ...theme.spreadThis,
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    imagePro: {
        width: '200px',
        height: '150px',
        textAlign: 'center'
    },
    imageUser: {
        width: '200px',
        height: '200px'
    },
    image: {
        width: 200,
        height: 200,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1000,
    },
    orginalPrice: {
        color: '#999',
        textDecoration: 'line-through'
    },
    offerTag: {
        textAlign: 'center',
        color: green[700],
    }

})
const professionalTiers = [
    {
        plan: "professional",
        title: 'Basic',
        type: "professionalBasic",
        price: '120',
        offerPrice: '36',
        duration: '/month',
        validity: '30 days',
        description: [
            'Single User',
            'Unlimited Access',
            'Help Center Access',
            'Email Support'],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        buttonOffer: 'Get Now',
        id: 'paypal-button'
    },
    {
        plan: "professional",
        title: 'Short Term',
        type: "professionalShortTerm",
        subheader: 'Most popular',
        price: '600',
        offerPrice: '180',
        duration: '/6 months',
        validity: '90 days',
        description: [
            'Single User',
            'Unlimited Access',
            'Help Center Access',
            'Priority Email Support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
        buttonOffer: 'Get Now',
        id: 'paypal-button'
    },
    {
        plan: "professional",
        title: 'Long Term',
        type: "professionalLongTerm",
        price: '1200',
        offerPrice: '360',
        duration: '/year',
        validity: '365 days',
        description: [
            'Single User',
            'Unlimited Access',
            //'30 GB of storage',
            'Help Center Access',
            'Phone & Email Support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        buttonOffer: 'Get Now',
        id: 'paypal-button'
    },
];

const professionalFreeTiers = [
    {
        plan: "professional",
        title: 'Basic',
        type: "professionalBasic",
        price: '36',
        originalPrice: '120',
        duration: '/month',
        validity: '30 days',
        description: [
            'Single User',
            'Unlimited Access',
            'Help Center Access',
            'Email Support'],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        buttonOffer: 'Get Now',
        id: 'paypal-button'
    },
    {
        plan: "professional",
        title: 'Short Term',
        type: "professionalShortTerm",
        subheader: 'Most popular',
        price: '180',
        originalPrice: '600',
        duration: '/6 months',
        validity: '90 days',
        description: [
            'Single User',
            'Unlimited Access',
            'Help Center Access',
            'Priority Email Support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
        buttonOffer: 'Get Now',
        id: 'paypal-button'
    },
    {
        plan: "professional",
        title: 'Long Term',
        type: "professionalLongTerm",
        price: '360',
        originalPrice: '1200',
        duration: '/year',
        validity: '365 days',
        description: [
            'Single User',
            'Unlimited Access',
            //'30 GB of storage',
            'Help Center Access',
            'Phone & Email Support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        buttonOffer: 'Get Now',
        id: 'paypal-button'
    },
];

const freeTire = {
    plan: "userOffer",
    type: "userFree",
}
const userTiers = [

    {
        plan: "user",
        title: 'Basic',
        type: "patientBasic",
        price: '20.20',
        duration: '/month',
        validity: '30days',
        description: [
            'Single User',
            'Unlimited Access',
            'Help Center Access',
            'Email Support'],
        buttonText: 'Get Started',
        buttonOffer: 'Try Now',
        buttonVariant: 'outlined',
    },
    {
        plan: "user",
        title: 'Short Term',
        type: "patientShortTerm",
        subheader: 'Most popular',
        price: '100',
        duration: '/6 months',
        validity: '90days',
        description: [
            'Single User',
            'Unlimited Access',
            'Help Center Access',
            'Priority Email Support',
        ],
        buttonText: 'Get started',
        buttonOffer: 'Try Now',
        buttonVariant: 'contained',
    },
    {
        plan: "user",
        title: 'Long Term',
        type: "patientLongTerm",
        price: '200',
        duration: '/year',
        validity: '365days',
        description: [
            'Single User',
            'Unlimited Access',
            //'30 GB of storage',
            'Help Center Access',
            'Phone & Email Support',
        ],
        buttonText: 'Get Started',
        buttonOffer: 'Try Now',
        buttonVariant: 'outlined',
    },
];
class ProfessionalPlans extends Component {
    Copyright = () => {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link to={window.location.pathname} color="inherit" href="https://material-ui.com/">
                    Your Website
          </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }
    render() {

        const Copyright = this.Copyright;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Container maxWidth="sm" component="main" className={classes.heroContent}>
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        Professional Pricing
                        </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" component="p">
                        This Plan Includes Patient Assessment and Treatment for Professionals like Physicians,Therapists and Related Professionals
                        </Typography>
                </Container>
                <Container maxWidth="md" component="main">
                    <Grid container spacing={5} alignItems="flex-end">

                        {this.props.user.isProfOfferAvailable === true ? (

                            professionalFreeTiers.map(tier => (
                                // Enterprise card is full width at sm breakpoint
                                <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                                    <Card>
                                        <CardHeader
                                            title={tier.title}
                                            subheader={tier.subheader}
                                            titleTypographyProps={{ align: 'center' }}
                                            subheaderTypographyProps={{ align: 'center' }}
                                            action={tier.title === 'Pro' ? <StarIcon /> : null}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>

                                            <>
                                                <h3 className={classes.offerTag}>Get 70% Now</h3>
                                                <div className={classes.cardPricing}>
                                                    <Typography component="h2" variant="h5" color="textPrimary">
                                                        ${tier.price}
                                                    </Typography>
                                                    <Typography component="p" variant="h7" className={classes.orginalPrice}>
                                                        ${tier.originalPrice}
                                                    </Typography>
                                                    <Typography variant="h7" color="textSecondary">
                                                        {tier.duration}
                                                    </Typography>
                                                </div>
                                            </>

                                            <ul>
                                                {tier.description.map(line => (
                                                    <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                        {line}
                                                    </Typography>
                                                ))}
                                            </ul>

                                        </CardContent>
                                        <CardActions>
                                            <Button fullWidth variant={tier.buttonVariant} onClick={() => this.props.selectedPlan(tier, this.props.history)} color="primary">
                                                {tier.buttonText}
                                            </Button>

                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            professionalTiers.map(tier => (
                                // Enterprise card is full width at sm breakpoint
                                <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                                    <Card>
                                        <CardHeader
                                            title={tier.title}
                                            subheader={tier.subheader}
                                            titleTypographyProps={{ align: 'center' }}
                                            subheaderTypographyProps={{ align: 'center' }}
                                            action={tier.title === 'Pro' ? <StarIcon /> : null}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>

                                            <div className={classes.cardPricing}>
                                                <Typography component="h2" variant="h5" color="textPrimary">
                                                    ${tier.price}
                                                </Typography>

                                                <Typography variant="h7" color="textSecondary">
                                                    {tier.duration}
                                                </Typography>
                                            </div>

                                            <ul>
                                                {tier.description.map(line => (
                                                    <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                        {line}
                                                    </Typography>
                                                ))}
                                            </ul>

                                        </CardContent>
                                        <CardActions>

                                            <Button fullWidth variant={tier.buttonVariant} onClick={() => this.props.selectedPlan(tier, this.props.history)} color="primary">
                                                {tier.buttonText}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        )

                        }

                    </Grid>
                    <Container maxWidth="sm" component="main" className={classes.heroContent}>
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            User Pricing
                        </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                            Set your goal & start your training today
                            our goal is fulfilling your expected recovery from eye muscle-related vision problems
                        </Typography>
                        <div>
                        </div>
                        {this.props.user.isUserTrialAvailable === true ? (
                            <>
                                <Button fullWidth variant="contained" onClick={() => this.props.startFreeTrial(freeTire, this.props.history)} color="primary">
                                    1 month free
                        </Button>
                            </>
                        ) : (
                            <>

                            </>
                        )}
                    </Container>
                    <Grid container spacing={5} alignItems="flex-end">
                        {userTiers.map(tier => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>

                                <Card>
                                    <CardHeader
                                        title={tier.title !== "Free" ? tier.title : ''}
                                        subheader={tier.subheader}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        action={tier.title === 'Pro' ? <StarIcon /> : null}
                                        className={classes.cardHeader}

                                    />
                                    <CardContent>
                                        <div className={classes.cardPricing}>
                                            <Typography component="h2" variant="h5" color="textPrimary">
                                                ${tier.price}
                                            </Typography>
                                            <Typography variant="h6" color="textSecondary">
                                                {tier.duration}
                                            </Typography>
                                        </div>
                                        <ul>
                                            {tier.description.map(line => (
                                                <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                    {line}
                                                </Typography>
                                            ))}
                                        </ul>

                                        {/* <img src="freetrial.png" alt="user" className={classes.imageUser} /> */}

                                    </CardContent>
                                    <CardActions>

                                        <Button fullWidth variant={tier.buttonVariant} onClick={() => this.props.selectedPlan(tier, this.props.history)} color="primary">
                                            {tier.buttonText}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.user ? state.user.credentials : ""
})
export default connect(mapStateToProps, { selectedPlan, startFreeTrial })(withStyles(styles)(ProfessionalPlans))
