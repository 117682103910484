import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
//import PayPalClient from '../components/PayPal/PayPalButton';
import PropTypes from 'prop-types';
import { loginUser } from '../redux/actions/userActions';
import { connect } from 'react-redux'
const styles = (theme) => ({
    ...theme.spreadThis,
    root: {
        textAlign: 'center',

    },
    paper: {
        width: '33%',
        minWidth: '280px',
        margin: 'auto',
        height: '100%',
        padding: '20px 20px 40px 20px',
        textAlign: 'center',
        color: theme.palette.primary,
    },


})
class Login extends Component {
    constructor() {
        super();
        this.state = {
            // isSuccess: false,
            email: '',
            password: '',
            errors: {},
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        this.props.loginUser(userData, this.props.history);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors })
        }
    }

    render() {
        const { classes, UI: { loading } } = this.props;
        const { errors, } = this.state;
        return (
            <div>
                <Grid
                    className={classes.root}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    {/* <Grid item xs={6}>

                    </Grid> */}


                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <img src="/userIcon.png" alt="user" className={classes.image} />
                            <Typography variant="h5" className={classes.pageTitle}>
                                Login
                            </Typography>
                            <form noValidate onSubmit={this.handleSubmit}>
                                <TextField
                                    id="email"
                                    type="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    className={classes.textField}
                                    value={this.state.email}
                                    helperText={errors.email}
                                    error={errors.email ? true : false}
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                                <TextField
                                    id="password"
                                    name="password"
                                    type="password"
                                    label="Password"
                                    variant="outlined"
                                    className={classes.textField}
                                    helperText={errors.password}
                                    error={errors.password ? true : false}
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                                {errors.general && (
                                    <Typography variant="body2" className={classes.customError}>{errors.general}</Typography>
                                )}
                                <Button
                                    type="submit"
                                    variant='contained'
                                    color="primary"
                                    className={classes.button}
                                    disabled={loading}
                                >
                                    Login
                                    {loading && <CircularProgress size={30} className={classes.progress} />}
                                </Button>
                                <br /><small>don't have an account ? signup <Link to='/signup'>here</Link></small>
                                <Grid item xs={12}>
                                    <p><Link to="/forgotpassword">Forgot Password</Link></p>

                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                    <div id="paypal-button"></div>
                </Grid>
            </div>
        )
    }
}
Login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})
const mapActionToProps = {
    loginUser
}
export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(Login))

