import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import LinearProgress from '@material-ui/core/LinearProgress';
// import ProfTable from '../Dashboard/ProfTable'
//import { connect } from 'formik';
//redux stuff
import { connect } from 'react-redux';
import FilteredUserTable from './FilteredUserTable';
//import { FormControl, OutlinedInput, Typography } from '@material-ui/core';


const styles = (theme) => ({
    ...theme.SpreadThis,
    mainRoot: {
        // flexGrow: 1,
        // width: '75%',
        // margin: '0 auto',
        // position: 'relative'

        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
    },
    paper: {
        width: '75%',
        margin: '0 auto',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '83vh'
    },
    search: {
        width: '50%',
        margin: '0 auto',
    },
    margin: {
        margin: theme.spacing(1),
        paddingTop: theme.spacing(3)
    },
    textfield: {
        paddingTop: theme.spacing(4)
    }
})


class SearchPatient extends Component {
    state = {
        query: '',
        progress: false,
    }



    handleChange = (event) => {
        this.setState({ query: event.target.value });
    };
    render() {
        const { classes, patients } = this.props
        // let totalPatients = []
        // totalPatients.push(...patients)
        //  console.log(this.state.totalPatients)
        //const lowerCaseQuery = this.state.query.toLowerCase()
        let filteredPatients = patients.filter((item) => item.patientId.includes(this.state.query))
        return (
            <div className={classes.mainRoot}>
                {this.props.loading ? <LinearProgress /> : ""}

                <Grid container justify="center">
                    <Grid item xs={12}>
                        {/* <LinearProgress style={{ position: 'absolute', bottom: '68px' }} /> */}
                        <Paper className={classes.paper}>

                            <Typography
                                color="primary"
                                className={classes.textfield}
                                variant="h4"
                            >
                                Search Patient
                            </Typography>

                            <FormControl fullWidth className={classes.margin} variant="outlined">
                                <OutlinedInput
                                    className={classes.search}
                                    name="query"
                                    id="outlined-adornment-amount"
                                    value={this.state.query}
                                    onChange={this.handleChange}
                                    placeholder="Eg: Patient Name, Patient Id..."
                                    type="search"
                                    startAdornment={<SearchIcon position="start" />}

                                />
                            </FormControl>
                            <div style={{ marginTop: "70px" }}>
                                <FilteredUserTable history={this.props.history} patients={filteredPatients} noRows={5} />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.UI.loading,
    patients: state.data.patients
})
export default connect(mapStateToProps, {})(withStyles(styles)(SearchPatient))

